import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrianglePersonDigging, faHome } from '@fortawesome/pro-duotone-svg-icons';
import Lottie from 'react-lottie';
import AccessDeniedAnimation from 'assets/img/animated-icons/403-access-denied'

const InsufficientPermissions = () => {

  const AccessDeniedAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: AccessDeniedAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Card className="text-center" >
      <Card.Body className="p-5" >
        <div className="display-1 text-300 fs-error">
          <Lottie options={AccessDeniedAnimationOptions} ariaRole="div" style={{ maxWidth: '400px' }} />
        </div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Your access to this page has been restricted.
        </p>
        <hr />
        <p>
          Contact your company administrator for more information.
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default InsufficientPermissions;
