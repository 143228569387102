import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';

const ProfileBannerHeader = ({ profile_picture, firstname, lastname, className }) => {
  return (
    <Card.Header
      className={classNames(className, 'position-relative p-lg-2 mb-md-7 mb-lg-0 mb-sm-7 ')}
    >
      <Avatar
        size="5xl"
        className="avatar-profile ms-4"
        src={(profile_picture === '') ? '' : profile_picture}
        name={(profile_picture === '' || !profile_picture) ? `${firstname} ${lastname}` : ''}
        mediaClass="img-thumbnail shadow-sm"
      />
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3 mt-5">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  // firstname: PropTypes.string.isRequired,
  // lastname: PropTypes.string.isRequired,
  className: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
