import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from "./routes/PrivateRoutes"
import Login from "./components/authentication/split/Login"
import ForgottenPassword from "./components/authentication/split/ForgottenPassword"
import ResetPassword from "./components/authentication/split/ResetPassword"
import Layout from './layouts/Layout';
import Activate from 'components/authentication/split/Activate';
import { SocketProvider } from 'context/SocketContext';
import PublicLayout from 'layouts/PublicLayout';
import { ApolloProvider } from '@apollo/client';
import { client } from 'api/ServiceGraphQL';
import Error404 from 'components/errors/Error404';

const App = () => {
  return (
    <div>
      <SocketProvider>
        <Router>
          <AuthProvider>
            <ApolloProvider client={client}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/Activate" component={Activate} />
                <Route path="/ForgottenPassword" component={ForgottenPassword} />
                <Route path="/ResetPassword" component={ResetPassword} />
              <PrivateRoute path="/_d/" component={Layout} />
                <Route path="/" component={PublicLayout} />
              </Switch>
            </ApolloProvider>
          </AuthProvider>
        </Router>
      </SocketProvider>
    </div>
  );
};

export default App;
