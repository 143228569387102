export const faqs = [
  // {
  //   id: 0,
  //   title: `How does Falcon's pricing work?`,
  //   description: `The free version of Falcon is available for teams of up to 15 people. Our Falcon Premium plans of 15 or fewer qualify for a small team discount. As your team grows to 20 users or more and gets more value out of Falcon, you'll get closer to our standard monthly price per seat. The price of a paid Falcon plan is tiered, starting in groups of 5 and 10 users, based on the number of people you have in your Team or Organization.`
  // },
  // {
  //   id: 1,
  //   title: 'What forms of payment do you accept?',
  //   description: `You can purchase Falcon with any major credit card. For annual subscriptions, we can issue an invoice payable by bank transfer or check. Please contact us to arrange an invoice purchase. Monthly purchases must be paid for by credit card.`
  // },
  // {
  //   id: 2,
  //   title: 'We need to add more people to our team. How will that be billed?',
  //   description: `You can add as many new teammates as you need before changing your subscription. We will subsequently ask you to correct your subscription to cover current usage.`
  // },
  // {
  //   id: 3,
  //   title: `How secure is Falcon?`,
  //   description: `Protecting the data you trust to Falcon is our first priority. Falcon uses physical, procedural, and technical safeguards to preserve the integrity and security of your information. We regularly back up your data to prevent data loss and aid in recovery. Additionally, we host data in secure SSAE 16 / SOC1 certified data centers, implement firewalls and access restrictions on our servers to better protect your information, and work with third party security researchers to ensure our practices are secure.`
  // },
  // {
  //   id: 4,
  //   title: `Will I be charged sales tax?`,
  //   description: `As of May 2016, state and local sales tax will be applied to fees charged to customers with a billing address in the State of New York.`
  // },
  // {
  //   id: 5,
  //   title: `Do you offer discounts?`,
  //   description: `We've built in discounts at each tier for teams smaller than 15 members. We also offer two months for free in exchange for an annual subscription.`
  // },
  // {
  //   id: 6,
  //   title: `Do you offer academic pricing?`,
  //   description: `We're happy to work with student groups using Falcon. Contact Us`
  // },
  // {
  //   id: 7,
  //   title: `Is there an on-premise version of Falcon?`,
  //   description: `We are passionate about the web. We don't plan to offer an internally hosted version of Falcon. We hope you trust us to provide a robust and secure service so you can do the work only your team can do.`
  // },
  // {
  //   id: 8,
  //   title: `What is your refund policy?`,
  //   description: `We do not offer refunds apart from exceptions listed below. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features. Exceptions to our refund policy: canceling within 48 hours of initial charge will result in a full refund. If you cancel within this timeframe, you will lose access to paid features and associated data immediately upon canceling.`
  // }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How do I add a new order?',
    description:
      ''
  },
  {
    id: 1,
    title: 'How do I add a new customer?',
    description:
      ''
  },
  {
    id: 2,
    title: 'How do I add a new user?',
    description:
      ''
  },
  {
    id: 3,
    title: 'How do I create an invoice?',
    description:
      ''
  },
  {
    id: 4,
    title: 'How do I create a statement?',
    description:
      ''
  },
  {
    id: 5,
    title: 'How do I add a new file to an existing order?',
    description:
      ''
  },

  {
    id: 6,
    title: 'How do I add driver notes and istructions on an existing order?',
    description:
      ''
  },

  {
    id: 7,
    title: 'How to dispatch an existing order?',
    description:
      ''
  },

  {
    id: 8,
    title: 'How do I cancel an existing order?',
    description:
      ''
  },

  {
    id: 9,
    title: 'When do I delete and when to cancel an existing order?',
    description:
      ''
  },

  {
    id: 10,
    title: 'How do I change the status on an existing order?',
    description:
      ''
  },

  {
    id: 11,
    title: 'How do I add a money code payment outside of a load?',
    description:
      ''
  },

  {
    id: 12,
    title: 'How do I complete an existing order?',
    description:
      ''
  },

  {
    id: 13,
    title: 'How do I add a comment to an existing order?',
    description:
      ''
  },

  {
    id: 14,
    title: 'How do I change available date and time of an equipment?',
    description:
      ''
  },

  {
    id: 15,
    title: 'How do I found the details of an equipment?',
    description:
      ''
  },

  {
    id: 16,
    title: 'How do I add a new equipment?',
    description:
      ''
  },

  {
    id: 17,
    title: 'How do I add a new maintenance report?',
    description:
      ''
  },

  {
    id: 18,
    title: 'How do I add a new place?',
    description:
      ''
  },

  {
    id: 19,
    title: 'How to generate a driver performance report?',
    description:
      ''
  },

  {
    id: 20,
    title: 'How to generate an invoice report?',
    description:
      ''
  },

  {
    id: 21,
    title: 'How to generate a maintenance report?',
    description:
      ''
  },

  {
    id: 22,
    title: 'How do I add a new tracking update?',
    description:
      ''
  },

  {
    id: 23,
    title: 'How do I enter a TONU on an existing order?',
    description:
      ''
  },

  {
    id: 24,
    title: 'How do I add detention or layover on an existing order?',
    description:
      ''
  },

  {
    id: 25,
    title: 'How do I add an extra stop to an existing order?',
    description:
      ''
  },

  {
    // Not sure what will be that report called.
    id: 26,
    title: 'How do I generate activity report of an user?',
    description:
      ''
  },



  {
    // Not sure if it will have that option.
    id: 27,
    title: 'How to mail available equipment list?',
    description:
      ''
  },


  {

    id: 28,
    title: ' ',
    description:
      ''
  },

]
  ;
