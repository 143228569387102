import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext'
import APICommunicator from 'api/APICommunicator'

const API = new APICommunicator('asd');

const ForgottenPasswordForm = ({ handleSubmission }) => {
  // State
  const [username, setUsername] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (username && typeof handleSubmission === 'function') handleSubmission(username)
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Username'}
          value={username}
          name="username"
          onKeyUp={(e) => setUsername(e.target.value)}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!username}>
          Send reset link
        </Button>
      </Form.Group>
    </Form>
  );
};


ForgottenPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgottenPasswordForm.defaultProps = { layout: 'split' };

export default ForgottenPasswordForm;
