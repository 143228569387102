import React from "react";

const BouncingDotsLoader = ({ className, ...props }) => {

    return (
        <div className={`bouncing-loader ${className}`} {...props}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default BouncingDotsLoader;