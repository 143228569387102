import React, { useEffect, useState } from "react";
import Flex from "components/common/Flex";
import { Button, Table } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight, faSpinnerThird, } from "@fortawesome/pro-duotone-svg-icons";
import APICommunicator from "api/APICommunicator";
import ModuleErrorWithReloadAlert from "./ModuleErrorWithReloadAlert";
import { debounce } from "helpers/utils";
import TableLoadingPlaceholder from "./TableLoadingPlaceholder";
import SimpleBarReact from 'simplebar-react';

const TableFooterToolbar = ({
    start = 0,
    perPage,
    totalCount = 0,
    loading,
    pageChanged = 15
}) => {

    const handleOnPageBack = () => {
        const newStart = start <= (perPage - 1) ? 0 : start - perPage
        if (typeof pageChanged === 'function') pageChanged(newStart)
    }

    const handleOnPageForward = () => {
        const newStart = start < totalCount - perPage ? start + perPage : totalCount - perPage
        if (typeof pageChanged === 'function') pageChanged(newStart)
    }

    return <Flex className="align-items-center justify-content-between">

        <Flex alignItems="center" className="fs--1">
            {totalCount > 0 ?
                <>Showing {start + 1} to {(start + 1 + perPage) < totalCount ? start + 1 + perPage : totalCount} of {totalCount} records</>
                :
                <>No results</>
            }
            {loading ? <FontAwesomeIcon icon={faSpinnerThird} className="ms-2 text-primary fa-spin" /> : <></>}
        </Flex>
        <Flex>

            <Button
                size="sm"
                variant={!(start <= perPage - 1) ? 'primary' : 'light'}
                className={classNames({ disabled: start <= perPage - 1 })}
                onClick={handleOnPageBack}
            >
                <FontAwesomeIcon icon={faAnglesLeft} />
            </Button>


            <Button
                size="sm"
                variant={start + perPage < totalCount ? 'primary' : 'light'}
                className={classNames('ms-2', { disabled: !(start + perPage < totalCount) })}
                onClick={handleOnPageForward}
            >
                <FontAwesomeIcon icon={faAnglesRight} />
            </Button>

        </Flex>
    </Flex>
}

const TableRender = ({
    data,
    columns,
    headerClassName,
    rowClassName,
    tableProps,
    onHeaderCellClick,
    onRowClick,
    noResultsDisplay,
    onCellClick
}) => {

    return <Table {...tableProps}>
        <thead className={'bg-200 text-900 text-nowrap align-middle'}>
            <tr>
                {columns.map((col, i) => {

                    const sortDesc = col.isSortedDesc === undefined ? col.defaultSort === undefined ? undefined : (col.defaultSort === 'desc') : col.isSortedDesc
                    const sortClass = (col.canSort !== false) ? (
                        (col.isSorted === true || sortDesc !== undefined) ? (
                            sortDesc === true ? ("sort desc fw-bold") : ("sort asc fw-bold")
                        ) : ("sort")
                    ) : ('')
                    return <th key={i} className={sortClass} onClick={() => { if (typeof onHeaderCellClick === 'function') onHeaderCellClick(col) }}>
                        {col.Header}
                    </th>
                })
                }
            </tr>
        </thead>
        <tbody>
            {data.length >= 1 ? data.map((row, i) => (
                <tr key={i}
                    className={rowClassName || 'align-middle white-space-nowrap'}
                    onClick={() => { if (typeof onRowClick === 'function') onRowClick(row) }}
                >
                    {columns.map((col, i) => (
                        <td key={i}>
                            {(typeof col.Cell === 'function') ? col.Cell(row) : row[col.accessor]}
                        </td>
                    ))}
                </tr>
            ))
                :
                <tr>
                    <td colSpan={columns.length} >
                        <div className="text-muted text-center d-block w-100">
                            {noResultsDisplay || 'No results'}
                        </div>
                    </td>
                </tr>
            }
        </tbody>
    </Table >
}

export const AtlassDataTable = ({
    source = [],
    columns = [],
    requestFilters = false,
    headerClassName,
    rowClassName,
    tableProps,
    tableMisc,
    footerToolbarProps,
    isLoading
}) => {


    const API = typeof source === 'string' ? new APICommunicator(source) : false
    const [data, setData] = useState(false)
    const [error, setError] = useState(false)
    const [perPage, setPerPage] = useState(15)
    const [start, setStart] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [Filters, setFilters] = useState(false)
    const [cols, setCols] = useState(columns)

    // get API Data
    const getData = () => {

        if (!source) return console.error('API source not provided')

        setError(false)
        setLoading(true)



        // Transform Filter array to string query
        const queryString = Filters ? Object.keys(Filters).map((q, i) =>
            Filters[q] && `&${q}=${encodeURIComponent(Filters[q])}`
        ).join('') : '';

        // Transform  Sort information to string query
        const sortString = cols ? cols.map(col => {
            const sortDesc = col.isSortedDesc === undefined ? col.defaultSort === undefined ? undefined : col.defaultSort === 'desc' : col.isSortedDesc
            const ascDesc = sortDesc === true ? 'desc' : 'asc'
            return (sortDesc !== undefined) ?
                `&sort_${col.accessor}=${ascDesc}` : ''
        }).join('') : '';


        if (typeof source === 'string') return API.GET(`/?perPage=${perPage}&start=${start}${queryString}${sortString}`)
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleError(Response)
                setData(Response.content.data)
                setTotalCount(Response.content.totalCount)
            })
            .catch(e => {
                setLoading(false)
                handleError(e)
            })



        /******************************** */
        /***********PROTOTYPE************ */
        /******************************** */
        var filteredSource = source
        Object.keys(Filters).forEach(filter => {
            filteredSource = filteredSource.filter(data => data[filter] === Filters[filter])
        })
        console.log(filteredSource)
        /******************************** */
        /***********PROTOTYPE************ */
        /******************************** */





        // getting data from an object
        setData(source)
        setLoading(false)

    }

    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleSort = col => {
        const { accessor } = col
        if (col.canSort === false) return
        const column = cols.find(x => x.accessor === accessor)
        const descSort = column.isSortedDesc === undefined ? column.defaultSort === undefined ? undefined : (column.defaultSort === 'desc') : column.isSortedDesc
        const desc = descSort !== undefined ? descSort !== true ? undefined : false : true
        const sorted = desc !== undefined
        delete column.defaultSort
        setCols([
            ...cols.map(c => c.accessor === accessor ?
                {
                    ...column,
                    isSorted: sorted,
                    isSortedDesc: desc
                } : c
            )])
    }

    useEffect(() => {
        setLoading(false)
        if (!source || typeof source === 'boolean') return setError('Source not provided')
        getData(false)
    }, [])

    useEffect(() => {
        setFilters(requestFilters)
    }, [requestFilters])

    useEffect(() => {
        setStart(0)
        setLoading(true);
        debounce(() => { getData() }, 150)()
    }, [Filters, cols])

    useEffect(() => {
        setLoading(true);
        debounce(() => { getData() }, 200)()
    }, [start])


    useEffect(() => {
        if (typeof isLoading === 'function') isLoading(loading)
    }, [loading])


    return <><SimpleBarReact>
        {error ?
            <div className="mx-3"><ModuleErrorWithReloadAlert message="Error loading data" errordata={error} reloadCallback={getData} /></div>
            :
            (data === false) ? <TableLoadingPlaceholder rows={3} cols={columns.length} /> : <TableRender
                data={data}
                columns={cols}
                headerClassName={headerClassName}
                rowClassName={rowClassName}
                onHeaderCellClick={handleSort}
                tableProps={tableProps || {
                    bordered: false,
                    striped: true,
                    hover: false,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
                {...tableMisc}

            />
        }
    </SimpleBarReact>
        <div className="mt-3 px-3">
            <TableFooterToolbar
                start={start}
                perPage={perPage}
                totalCount={totalCount}
                loading={loading}
                pageChanged={newStart => { setStart(newStart) }} {...footerToolbarProps}
            />
        </div>
    </>
}



export default AtlassDataTable