import { gql } from "@apollo/client";

export const GetPlaces = gql`
query Query{
  placeList {
    name
    emailList {
      emails {
        email
      }
    }
    internalNotes
    address {
      administritive_area
      country {
        ISO3
      }
      lat
      lng
      dependent_locality
      locality
      premise
      postal_code
      sub_administritive_area
      sub_premise
      thoroughfare
      types
    }
    publicNotes
    timezone
    type
    uuid
  }
}
`