import bw1 from 'assets/img/logos/partners/b&w/DAT.png';
import bw2 from 'assets/img/logos/partners/b&w/EFS.png';
import bw3 from 'assets/img/logos/partners/b&w/quickbooks.png';
import bw4 from 'assets/img/logos/partners/b&w/samsara.png';
import bw5 from 'assets/img/logos/partners/b&w/truckstop.png';

export default [
  { src: bw1, height: 40 },
  { src: bw2, height: 45 },
  { src: bw3, height: 30 },
  { src: bw4, height: 30 },
  { src: bw5, height: 35 },
];
