import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { PlaceTypes } from "data/autocomplete/PlaceTypes";


const AtlassCreatableTagSelect = ({
    ...props
}) => {

    return <CreatableSelect
        placeholder='Select from the list or type to create a tag'
        classNamePrefix="react-select"
        isClearable
        isMulti
        {...props}
    />
}

export const AtlassPlaceTypeCreatableSelect = ({
    value = [],
    onChange = () => { },
    name = "",
    ...props
}) => {

    const [selected, setSelected] = useState([])
    const [places, setPlaces] = useState([])

    const placeTypeFormat = PlaceType => ({
        value: PlaceType,
        label: PlaceType
    })

    const handleChange = (TAGS) => {

        const StringValue = TAGS ? TAGS.map(TAG => TAG.value).join(',') : ''

        const FilteredOptions = PlaceTypes.filter(ar => !TAGS.find(rm => rm.value === ar)).map(placeTypeFormat)
        setPlaces(FilteredOptions) // remove selected options from list

        setSelected(TAGS.length >= 1 ? TAGS : [])

        onChange({
            target: {
                value: StringValue,
                name: name
            }
        })

    }

    useEffect(() => {
        setPlaces(PlaceTypes.map(type => ({ value: type, label: type })))
    }, [])

    useEffect(() => {
        if (value && typeof value === 'string') setSelected(value.split(',').map(placeTypeFormat))
    }, [value])

    return <AtlassCreatableTagSelect
        options={places}
        onChange={handleChange}
        value={selected}
        {...props}
    />
}

export default AtlassCreatableTagSelect