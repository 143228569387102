import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SectionHeader from "components/common/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGarage } from "@fortawesome/pro-duotone-svg-icons";

const Equipment = () => {

    useEffect(() => {
        document.title = `Equipment`
    }, [])

    return (<>
        <Row>
            <Col>
                <SectionHeader
                    Title='Equipment'
                    Subtitle='Company trucks and trailers'
                    Icon={<FontAwesomeIcon icon={faGarage} className="me-0 ms-2" size="2x" />}
                />
            </Col>
        </Row>
    </>)
}

export default Equipment