import eagleexpress from 'assets/img/logos/eagle-express.svg';

export default [
  {
    description:
      "Atlass has helped us focus on growing our business and has given us freedom to spend more time with our families.",
    author: 'Emil Gouchterov',
    designation: 'Owner',
    companyImage: eagleexpress,
    size: 45
  }
];
