import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SectionHeader from "components/common/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildings } from "@fortawesome/pro-duotone-svg-icons";

const Companies = () => {

    useEffect(() => {
        document.title = `Companies`
    }, [])

    return (<>
        <Row>
            <Col>
                <SectionHeader
                    Title='Companies'
                    Subtitle='Customers, vendors, brokers and all other companies'
                    Icon={<FontAwesomeIcon icon={faBuildings} className="me-0 ms-2" size="2x" />}
                />
            </Col>
        </Row>
    </>)
}

export default Companies