import React, { useEffect, useState } from "react";
// import imap from 'imap'
import ModuleErrorWithReloadAlert from "components/common/ModuleErrorWithReloadAlert";

const u = 'rado@eagleexpress05.com'
const p = '!@!eagle_Express2014'
const h = 'mail.eagleexpress05.com'

const EmailInbox = () => {

    const [error, setError] = useState(false)

    // var imap = new Imap({
    //     user: u,
    //     password: p,
    //     host: h,
    //     port: 143,
    //     tls: true
    // })

    // const openInbox = cb => {
    //     imap.openBox('INBOX', true, cb)
    // }
    // try {
    //     imap.once('ready', () => {
    //         openInbox((err, box) => {
    //             if (err) throw err;

    //             var f = imap.seq.fetch('1:3', {
    //                 bodies: 'HEADER.FIELDS (FROM TO SUBJECT DATE)',
    //                 struct: true
    //             });


    //             f.on('message', function (msg, seqno) {
    //                 console.log('Message #%d', seqno);
    //                 var prefix = '(#' + seqno + ') ';
    //                 msg.on('body', function (stream, info) {
    //                     var buffer = '';
    //                     stream.on('data', function (chunk) {
    //                         buffer += chunk.toString('utf8');
    //                     });
    //                     stream.once('end', function () {
    //                         console.log(prefix + 'Parsed header: %s', inspect(Imap.parseHeader(buffer)));
    //                     });
    //                 });
    //                 msg.once('attributes', function (attrs) {
    //                     console.log(prefix + 'Attributes: %s', inspect(attrs, false, 8));
    //                 });
    //                 msg.once('end', function () {
    //                     console.log(prefix + 'Finished');
    //                 });
    //             });

    //             f.once('error', function (err) {
    //                 console.log('Fetch error: ' + err);
    //             });
    //             f.once('end', function () {
    //                 console.log('Done fetching all messages!');
    //                 imap.end();
    //             });
    //         })
    //     })
    // }
    // catch (e) {
    //     setError(e)
    // }


    // imap.once('error', err => {
    //     console.log(err)
    //     setError(err)
    // })

    // useEffect(() => {
    //     imap.connect()
    // })

    return <>
        {error ? <div className="mx-3"><ModuleErrorWithReloadAlert message="Error loading data" errordata={error} /></div> :
            <>
                email should be working
            </>
        }
    </>
}

export default EmailInbox