import { faMapLocationDot } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "components/common/IconButton";
import SectionHeader from "components/common/SectionHeader";
import { hasPermissions } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import AddEdit from "./offcanvas/AddEdit";
import { PlacesTable } from "./PlacesTable";


export const AddNewPlaceDefaultButton = props => {
    const [PlaceEditWindow, setPlaceEditWindow] = useState(false)
    const [placeEdit, setPlaceEdit] = useState(false)
    return <>
        <IconButton variant="primary" icon="plus" size="sm" transform="shrink-3" onClick={e => {
            setPlaceEdit(false)
            setPlaceEditWindow(true)
        }} {...props}>
            <span className="d-none d-sm-inline-block ms-1">Add a new place</span>
        </IconButton>

        <AddEdit
            show={PlaceEditWindow}
            handleClose={() => { setPlaceEditWindow(false) }}
            place={placeEdit}
        />
    </>
}

const Places = () => {

    useEffect(() => {
        document.title = `Places`
    }, [])

    return (<>
        <Row>
            <Col>
                <SectionHeader
                    Title='Places'
                    Subtitle='Directory of saved known locations'
                    Icon={<FontAwesomeIcon icon={faMapLocationDot} className="me-0 ms-2" size="2x" />}
                    RightSide={hasPermissions(['VIEW_PLACES', 'MANAGE_PLACES']) && <AddNewPlaceDefaultButton />}
                />
            </Col>
        </Row>

        <Card>
            <Card.Body className="px-0">
                <PlacesTable />
            </Card.Body>
        </Card>


    </>)
}

export default Places