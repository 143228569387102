import React, { useEffect, useState } from "react";
import Cta from "./Cta";
import Hero from "./Hero";
import Partners from "./Partners";
import Processes from "../Processes";
import Services from "./Services";
import Testimonial from "./Testimonial";

const Home = () => {

    return (<>
        <Hero />
        <Partners />
        <Processes />
        <Services />
        <Testimonial />
        <Cta />
    </>)
}

export default Home