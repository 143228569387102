import illustration1 from 'assets/img/platform-illustrations/settlements-page.png';
import illustration2 from 'assets/img/platform-illustrations/import-documents.png';
import illustration3 from 'assets/img/platform-illustrations/driver-safety.png';

export default [
  {
    icon: false,
    iconText: 'COMPLEX ACCOUNTING',
    color: 'danger',
    title: 'Simplify and Streamline Accounting',
    description:
      'Are you tired of manually keeping track of bills, receipts and invoices and their allocation to vendors and drivers?',
    image: illustration1
  },
  {
    // icon: ['far', 'object-ungroup'],
    icon: false,
    iconText: 'UNLIMITED DOCUMENTS',
    color: 'info',
    title: 'Access all your important documents anywhere',
    description:
      "Exhausted from constantly looking from documents in emails and shared folers? With Atlass' cloud document manager you can save, organize, preview and share any kind of documents. Unlimited.",
    image: illustration2,
    inverse: true
  },
  {
    // icon: ['far', 'paper-plane'],
    icon: false,
    iconText: 'SAFETY AND COMPLIANCE',
    color: 'success',
    title: 'Get control of what\'s really important',
    description: 'Remember that driver\'s CDL expires soon? Or that incident you were supposed to follow-up on? Let us keep track of all of that for you.',
    image: illustration3
  }
];
