import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PageHeader from "components/common/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dashboard = () => {

    useEffect(() => {
        document.title = `Accounting Dashboard`
    }, [])

    return (<>
        <PageHeader
            title="Accounting"
            description=""
            className="mb-3"
        />
    </>)
}

export default Dashboard