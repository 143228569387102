import UserComment from "components/common/UserComment";
import React, { useEffect, useState } from "react";

const Comments = ({ comments }) => {
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    return (<>
        {(comments && typeof comments === 'object') && comments.map(({ id, userId, avatarSrc, name, content, postTime, rating }) =>
            <UserComment key={id || uuidv4()}
                avatarSrc={avatarSrc}
                userId={userId}
                name={name}
                content={content}
                postTime={postTime}
                rating={rating} />
        )}
    </>)
}

export default Comments