import React, { useState, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangelogList from 'data/changelog'

const Changelog = () => {
    const [CHANGELOG, setCHANGELOG] = useState(ChangelogList);
    useEffect(() => {
        document.title = `FAQ`
    }, []);
    return (
        <>
            <PageHeader
                title="Changelog"
                description="Here you can find what has been worked on and the latest changes we've made to Atlass to improve it."
                className="mb-3"
            />
            {CHANGELOG.map(LOG => (
                <Card key={LOG.changelog_number} className="mb-3">
                    <Card.Body>
                        <Card.Title as="div">
                            <h4>{LOG.changelog_title}</h4>
                            <p className='text-muted fs--2'>{LOG.changelog_number}</p>
                        </Card.Title>
                        <p className="text-muted"  >{LOG.changelog_short}</p>
                        <div>{LOG.changelog_long}</div>
                    </Card.Body>
                </Card>
            ))
            }
        </>
    );
};

export default Changelog;
