/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import fourohfour from 'components/pages/landing/helper/fourohfour';
import Home from 'components/pages/landing/home/Home';
import ServiceStatus from 'components/pages/landing/services_status';
import PublicPrivacyPolicy from 'components/pages/landing/helper/privacypolicy';
import SMSTerms from 'components/pages/landing/helper/SMSTerms';

const PublicRoutes = () => (
    <Switch>

        {/* Operations */}
        <Route path="/" exact component={Home} />
        <Route path="/Status" exact component={ServiceStatus} />
        <Route path="/privacypolicy" exact component={PublicPrivacyPolicy} />
        <Route path="/smsterms" exact component={SMSTerms} />


        {/* helper pages */}
        <Route path="/errors/404" exact component={fourohfour} />


        <Redirect to="/errors/404" />


    </Switch>
);

export default PublicRoutes;
