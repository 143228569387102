import { USDOTInput, USPhoneInput, USPhoneInput_NO_EXTENSION } from "components/common/MaskedInputs";
import { validateEmail, validateName, validatePhone } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const DemoRequestForm = ({ submitCallback }) => {

    const MAX_INPUT_LENGTH = 255

    const formSchema = {
        name: v => validateName(v),
        position: v => typeof v === 'string' || v instanceof String,
        email: v => validateEmail(v),
        phone: v => validatePhone(v.substring(3)),
        company_name: v => validateName(v),
        company_trucks: v => parseInt(v) === Number(v) && v >= 5 && v <= 100,
        company_employees: v => parseInt(v) === Number(v) && v >= 5 && v <= 100,
        company_usdot: v => (parseInt(v) === Number(v)) || v == '',
        message: v => typeof v === 'string' || v instanceof String
    }

    const [formData, setFormData] = useState({
        name: '',
        position: '',
        email: '',
        phone: '',
        company_name: '',
        company_trucks: 0,
        company_employees: 0,
        company_usdot: '',
        message: ''
    })

    const [formErrors, setFormErrors] = useState(false)

    const validateForm = (formData, formSchema) => Object
        .keys(formSchema)
        .filter(key => !formSchema[key](formData[key]))
        .map(key => `${key}`)


    const fieldChange = e => {
        if (e.target.value.length <= MAX_INPUT_LENGTH) setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmitError = Response => console.log(Response)
    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        console.table(formData)
        fetch('https://public.atlass.app/.application/core/php/scripts/Mail/SendDemoRequestEmail.php', {
            'method': 'POST',
            // 'headers': new Headers({
            //     'Content-Type': 'application/json',
            // }),
            'body': JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(handleSubmitError)
        if (typeof submitCallback === 'function') submitCallback(true)
    }

    useEffect(() => {
        setFormErrors(validateForm(formData, formSchema));
    }, [formData])

    useEffect(() => {
        // console.log(formData)
        // console.log(formErrors)
    }, [formErrors])

    return (<>
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="* Required"
                            name="name"
                            onChange={fieldChange}
                            value={formData ? formData.name : ''}
                            isInvalid={formErrors && formErrors.includes('name') && formData && formData.name !== ''}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupPosition">
                        <Form.Label>Position</Form.Label>
                        <Form.Control type="text" placeholder="Optional"
                            name="position"
                            onChange={fieldChange}
                            value={formData ? formData.position : ''}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Business Email</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com * Required"
                            name="email"
                            isInvalid={formErrors && formErrors.includes('email') && formData && formData.email !== ''}
                            onChange={fieldChange}
                            value={formData ? formData.email : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <USPhoneInput_NO_EXTENSION type="text" placeholder="+1 (xxx) xxx-xxxx * Required" name="phone"
                            isInvalid={formErrors && formErrors.includes('phone') && formData && formData.phone !== '' && formData.phone !== '+1 ('}
                            onChange={fieldChange}
                            value={formData ? formData.phone : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid phone.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupCompany">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" placeholder="* Required"
                            name="company_name"
                            onChange={fieldChange}
                            isInvalid={formErrors && formErrors.includes('company_name') && formData && formData.company_name !== ''}
                            value={formData ? formData.company_name : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid company name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formGroupCompany">
                        <Form.Label>Company USDOT</Form.Label>
                        <USDOTInput type="text" placeholder="Optional"
                            name="company_usdot"
                            onChange={fieldChange}
                            value={formData ? formData.company_usdot : ''}
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formGroupSize">
                <Form.Label>Approximate Company Size <span className=" text-400">* Required</span></Form.Label>
                <Row>
                    <Col>
                        <Form.Label className="w-100">Trucks
                            <span className="float-end">&#177; <b>{formData && formData.company_trucks}</b></span>
                        </Form.Label>
                        <Form.Range
                            min="5" max="100" step="5"
                            name="company_trucks" onChange={fieldChange}
                            value={formData ? formData.company_trucks : 5}
                        />
                    </Col>
                    <Col>
                        <Form.Label className="w-100">
                            Employees (excl drivers)
                            <span className="float-end">&#177; <b>{formData && formData.company_employees}</b></span>
                        </Form.Label>
                        <Form.Range
                            min="5" max="100" step="5"
                            name="company_employees" onChange={fieldChange}
                            value={formData ? formData.company_employees : 5}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                    as="textarea" rows={3}
                    name="message"
                    onChange={fieldChange}
                    placeholder="Optional"
                    value={formData ? formData.message : ''}
                />
            </Form.Group>

            <div className="text-end mt-4">
                <Button disabled={formErrors.length > 0} type="submit">Submit</Button>
            </div>

        </Form>
    </>)
}

export default DemoRequestForm