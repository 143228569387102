import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, Alert, Select } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import UnitedStatesAndCanada from 'data/provinces/NorthAmerica/UnitedStatesAndCanada'
import ReactSelect from 'react-select';

const EditUserModal = ({ show, handleClose, successCallback, inheritedFormData, USER_ID }) => {


    const API = new APICommunicator('Users');
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState(inheritedFormData)
    const [stateDropdownValue, setStateDropdownValue] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()
        setError(false)

        API.PUT(`/${USER_ID}`, {
            address_1: formData.address_1,
            address_2: formData.address_2,
            city: formData.city,
            email: formData.email,
            firstname: formData.firstname,
            lastname: formData.lastname,
            phone: formData.phone,
            profile_picture: formData.profile_picture,
            role: formData.role,
            service_unique_id: formData.service_unique_id,
            state: formData.state,
            // status: formData.status,
            timezone: formData.timezone,
            title: formData.title,
            uuid: formData.uuid,
            zip: formData.zip,
        })
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }


    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const STATES = UnitedStatesAndCanada.map(STATE => ({
        value: STATE.id,
        label: <div className="text-dark">[{STATE.abbr}] {STATE.name}</div>
    }))

    const FindStateAbbr = str => {
        if (!str) return ''
        let val = str.toLowerCase()
        if (UnitedStatesAndCanada.find(x => x.abbr.toLowerCase() === val)) return UnitedStatesAndCanada.find(x => x.abbr.toLowerCase() == val).abbr
        if (UnitedStatesAndCanada.find(x => x.name.toLowerCase() === val)) return UnitedStatesAndCanada.find(x => x.name.toLowerCase() == val).abbr
        return ''
    }

    useEffect(() => {
        setFormData(inheritedFormData)
        setTimeout(() => {
            setStateDropdownValue(STATES.find(x => x.value == FindStateAbbr(inheritedFormData.state)))
        }, 500)
    }, [inheritedFormData, show]);

    useEffect(() => {
        setFormData({
            ...formData,
            state: stateDropdownValue?.value
        })
    }, [stateDropdownValue])

    return (

        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
                                <Form.Control type="text" placeholder="First Name" name="firstname" onChange={handleFieldFormChange} defaultValue={formData?.firstname} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="Last Name" className="mb-3">
                                <Form.Control type="text" placeholder="Last Name" name="lastname" onChange={handleFieldFormChange} defaultValue={formData?.lastname} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="border-dashed-bottom my-3"></div>
                    <Row>
                        <Col lg={12} xl={6}>
                            <Form.Label>User Info</Form.Label>
                            <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                                <Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldFormChange} defaultValue={formData?.email} />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3">
                                <Form.Control type="text" placeholder="Phone" name="phone" onChange={handleFieldFormChange} defaultValue={formData?.phone} />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                                <Form.Control type="text" placeholder="title" name="title" onChange={handleFieldFormChange} defaultValue={formData?.title} />
                            </FloatingLabel>
                        </Col>

                        <Col lg={12} xl={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="Address" name="address_1" onChange={handleFieldFormChange} defaultValue={formData?.address_1} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Address Line 2 </Form.Label>
                                <Form.Control type="text" placeholder="Address Line 2" name="address_2" onChange={handleFieldFormChange} defaultValue={formData?.address_2} />
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="City" name="city" onChange={handleFieldFormChange} defaultValue={formData?.city} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>State</Form.Label>
                                        <ReactSelect
                                            closeMenuOnSelect={true}
                                            options={STATES}
                                            placeholder="Select a state"
                                            value={stateDropdownValue}
                                            onChange={v => setStateDropdownValue(v)}
                                            classNamePrefix="react-select"
                                        />
                                    </Form.Group>

                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ZIP</Form.Label>
                                        <Form.Control type="text" placeholder="ZIP" name="zip" onChange={handleFieldFormChange} defaultValue={formData?.zip} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>

                    </Row>


                    {error && <Alert variant="warning" dismissible onClose={() => setError(false)}>
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={(formData && (!formData.firstname || !formData.lastname || !formData.email))}>
                            Submit</Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}

export default EditUserModal