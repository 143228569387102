import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PageHeader from "components/common/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useQuery,
    gql
} from '@apollo/client'
import { GetPlaces } from "api/GraphQL/Queries";
import SectionHeader from "components/common/SectionHeader";
import { faScrewdriverWrench } from "@fortawesome/pro-duotone-svg-icons";
import ServiceRecordsList from "./components/ServiceRecordsList";


const Service = () => {

    useEffect(() => {
        document.title = `Service`
    }, [])


    return (<>
        <Row>
            <Col>
                <SectionHeader
                    Title='Service Visit Records'
                    Subtitle='Repair and maintenance records and schedule'
                    Icon={<FontAwesomeIcon icon={faScrewdriverWrench} className="me-0 ms-2" size="2x" />}
                // RightSide={hasPermissions(['VIEW_PLACES', 'MANAGE_PLACES']) && <AddNewPlaceDefaultButton />}
                />
            </Col>
        </Row>
        <div className="mt-2 px-1 pb-1 pt-0 fs--1">
            <Row className="gx-1 gy-0 text-center">
                <Col>
                    <Row className="">
                        <Card className="bg-transparent shadow-none">
                            <Card.Header className="bg-light"> -- search -- </Card.Header>
                            <Card.Body className="p-0 mt-1">
                                <Row className="gx-0 gy-1">
                                    <ServiceRecordsList />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                {/* <Col xl={2} lg={4} className="d-xl-block d-lg-block d-none" >
                    <Card>
                        <Card.Header className="bg-light text-start"><h6 className="mb-0">Filters</h6></Card.Header>
                        <Card.Body>-- filters --</Card.Body>
                    </Card>
                </Col> */}
            </Row>
        </div>
    </>)
}

export default Service