import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { EmailValidatedInput } from "components/common/ValidatedInputs";
import { USPhoneInput } from "components/common/MaskedInputs";
import { AtlassStateSelect_NA } from "components/common/AtlassSelect";
import { AtlassPlaceTypeCreatableSelect } from "components/common/AtlassTagSelect";


const AddEditPlaceFormNoSubmit = ({
    place,
    onChange
}) => {

    const [PLACE, setPlace] = useState('')

    const handleFieldChange = ({ target }) => setPlace({
        ...PLACE,
        [target.name]: target.value
    })

    useEffect(() => {
        if (place) setPlace({
            ...place,
            address_formatted: place.address_formatted ? place.address_formatted : `${place.address}, ${place.address2}, ${place.city}, ${place.state} ${place.zip}`
        });
    }, [place])


    useEffect(() => {
        if (typeof onChange === 'function') onChange(PLACE)
    }, [PLACE])

    return <>
        <Form.Group className="mb-1">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" placeholder="Location name" className="fw-bold" value={PLACE.name || ""} onChange={handleFieldChange} />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <AtlassPlaceTypeCreatableSelect name="type" value={PLACE.type || ""} onChange={handleFieldChange} />
        </Form.Group>
        <Row className="bg-200 py-1 mb-3">
            <Col>
                <Form.Group className="mb-1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" name="address" value={PLACE.address || ""} placeholder="Street number and name" size="sm" onChange={handleFieldChange} />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control type="text" name="address2" value={PLACE.address2 || ""} placeholder="Unit# / Building# / Etc.. " size="sm" onChange={handleFieldChange} />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" name="city" value={PLACE.city || ""} placeholder="City" onChange={handleFieldChange} />
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className="mb-1">
                            <Form.Label>State</Form.Label>
                            <AtlassStateSelect_NA name="state" value={PLACE.state || ""} placeholder="State" className="fs--1" onChange={handleFieldChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-1">
                            <Form.Label>ZIP</Form.Label>
                            <Form.Control type="text" name="zip" value={PLACE.zip || ""} placeholder="ZIP" onChange={handleFieldChange} />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Form.Label className="fw-bold">Contact Details</Form.Label>
        <Row>
            <Col>
                <Form.Group className="mb-1">
                    <Form.Label>Phone</Form.Label>
                    <USPhoneInput name="phone" value={PLACE.phone || ""} size="sm" onChange={handleFieldChange} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-1">
                    <Form.Label>FAX</Form.Label>
                    <USPhoneInput type="text" name="fax" value={PLACE.fax || ""} size="sm" onChange={handleFieldChange} />
                </Form.Group>
            </Col>
        </Row>
        <Form.Group className="mb-1">
            <Form.Label>Email</Form.Label>
            <EmailValidatedInput name="email" size="sm" placeholder="email@example.com" value={PLACE.email || ""} onChange={e => {
                const val = e.target.value;
                const name = e.target.name;
                const isInvalid = e.target.getAttribute('data-isinvalid')
                if (isInvalid === 'false') setPlace({
                    ...PLACE,
                    [name]: val
                })

                if (isInvalid === 'true') {
                    delete PLACE.email
                    setPlace({
                        ...PLACE
                    })
                }
            }}
            />
        </Form.Group>
        <Form.Label className="fw-bold">Remarks</Form.Label>
        <Form.Group className="mb-1">
            <Form.Label>Internal Notes</Form.Label>
            <Form.Control as="textarea" name="internal_notes" size="sm" rows="4" value={PLACE.internal_notes || ""} placeholder="Visible only to users of this app" onChange={handleFieldChange} />
        </Form.Group>
        <Form.Group className="mb-1">
            <Form.Label>Public Notes</Form.Label>
            <Form.Control as="textarea" name="public_notes" size="sm" rows="4" value={PLACE.public_notes || ""} placeholder="Visible to drivers and 3rd party viewers" onChange={handleFieldChange} />
        </Form.Group>
    </>
}

export default AddEditPlaceFormNoSubmit