import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PageHeader from "components/common/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccountsPayable = () => {

    useEffect(() => {
        document.title = `Accounts - Payable`
    }, [])

    return (<>
        <PageHeader
            title="Accounts Payable"
            description="Statements and payouts"
            className="mb-3"
        />
    </>)
}

export default AccountsPayable