export const PlaceTypes = [
    'Shipper/Receiver',
    'Cold Storage',
    'Warehouse',
    'Staging area',
    'Pallets place',
    'Gas Station',
    'Market center',
    'Motel',
    'Office place',
    'Rest area',
    'Scale',
    'Store',
    'Tire Sales & Repair',
    'Towing and Recovery',
    'Trailer Repair',
    'Truck repair',
    'Truck stop',
    'Truck wash',
    'Weigh Station',
    'Other'
]