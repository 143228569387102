import React, { useState, useEffect } from 'react'
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import { Button, Card, FloatingLabel, Form, FormCheck, ListGroup, Modal } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import Permissions from 'data/important/permissions'
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { getUserData } from 'context/AuthContext';
import IconButton from 'components/common/IconButton';

const API = new APICommunicator('UserRoles');

const Role = () => {

    const history = useHistory()
    const { ROLE_ID } = useParams();
    const [ROLE, setROLE] = useState(false)
    const [PERMISSIONS, setPERMISSIONS] = useState(false)
    const [roleName, setRoleName] = useState(false)
    const [roleDescription, setRoleDescription] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState([])

    const getRoleData = () => {
        API.GET(`/${ROLE_ID}`)
            .then(Response => {
                // console.table(Response.content)
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                setROLE(Response.content)
            })
            .catch(handleErrorWithToast)
    }

    const handleSubmit = e => {
        e.preventDefault()
        API.PUT(`/${ROLE.uuid}`, {
            permissions: selectedPermissions
        }).then(Response => {
            if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
            setROLE(Response.content)
        }).catch(handleErrorWithToast)

    }

    const handlePermissionChange = e => {
        if (e.target.checked && selectedPermissions.indexOf(e.target.id) <= -1) setSelectedPermissions([...selectedPermissions, e.target.id]);
        if (!e.target.checked && selectedPermissions.indexOf(e.target.id) >= 0) {
            let newArray = [...selectedPermissions]
            let i = newArray.indexOf(e.target.id)
            newArray.splice(i, 1)
            // console.log(newArray)
            setSelectedPermissions(newArray)
        }
    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })

    useEffect(() => {
        ROLE_ID != 'New' && getRoleData()
    }, [ROLE_ID])

    useEffect(() => {
        setPERMISSIONS(Permissions)
        setSelectedPermissions(ROLE.permissions)
    }, [ROLE])

    useEffect(() => {
        // console.log(selectedPermissions)
    }, [selectedPermissions])

    return (
        <>
            <IconButton
                onClick={() => history.goBack()}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className=" mt-1"
                transform="shrink-3">
                <span className="d-none d-sm-inline-block ms-1">Back</span>
            </IconButton>
            <br />
            <br />
            <Card>
                <Form onSubmit={handleSubmit}>
                    <Card.Body>
                        {ROLE ? `Edit role` : `Add new role`}
                        <div>
                            <Form.Group>
                                <FloatingLabel label="Name" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        onChange={e => {
                                            setRoleName(e.target.value)
                                        }}
                                        defaultValue={ROLE.name}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group>
                                <FloatingLabel label="Description" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Description"
                                        name="description"
                                        onChange={e => {
                                            setRoleDescription(e.target.value)
                                        }}
                                        defaultValue={ROLE.description}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <ListGroup variant="flush">
                                {(PERMISSIONS.length >= 1 && ROLE) && PERMISSIONS.map(PERMISSION =>
                                    <ListGroup.Item key={PERMISSION.code_name} className="fs--2 pb-0" >
                                        <FormCheck
                                            type="switch"
                                            id={PERMISSION.code_name}
                                            style={{ minHeight: '0' }}>
                                            <FormCheck.Input
                                                onChange={handlePermissionChange}
                                                className="mt-2"
                                                style={{ minHeight: '0' }}
                                                defaultChecked={ROLE.permissions ? ROLE.permissions.indexOf(PERMISSION.code_name) >= 0 : false}
                                            />
                                            <FormCheck.Label as='div' className='d-block'>
                                                <div className="ms-3">
                                                    <h6 className="mb-0">{PERMISSION.name}</h6>
                                                    <span className="text-muted">{PERMISSION.description}</span>
                                                </div>
                                            </FormCheck.Label>
                                        </FormCheck>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </div>
                    </Card.Body>
                    <Card.Footer className='bg-light border-top-1 border-dark sticky-sidebar'>
                        <Form.Group>
                            <Button
                                type="submit"
                                className="ms-4"
                                variant="primary"
                                disabled={!roleName}>
                                Save
                            </Button>
                        </Form.Group>
                    </Card.Footer>
                </Form>
            </Card>

        </>
    )
}

export default Role;