import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext'
import { SocketContext, useSockets } from 'context/SocketContext';
import { toast } from 'react-toastify';

const LoginForm = ({ hasLabel, layout }) => {

  const { login } = useAuth();
  const { loginConnect } = useSockets()
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // State
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  // Handler
  const handleSubmit = e => {

    e.preventDefault();
    setError('');
    setLoading(true)

    login(formData.username, formData.password)
      .then(Response => {
        setLoading(false)
        if (!Response) return console.error(`Login error`)
        if (!Response.status) return console.error(Response)
        if (Response.status !== 'success') return setError(Response.message)
        loginConnect(Response.content).then(() => {
          history.push("/_d/")
        })


      })
      .catch(e => {
        setLoading(false)
        if (typeof e === 'string') setError(e)
      })
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            style={{ borderRadius: '65px' }}
            placeholder='username'
            value={formData.username}
            name="username"
            onChange={handleFieldChange}
            type="text"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            style={{ borderRadius: '65px' }}
            placeholder='password'
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Row className="justify-content-between align-items-center">

          <Col xs="auto">
            <Link
              className="fs--1 mb-0"
              to={`/ForgottenPassword`}
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Form.Group>
          <Button
            style={{ borderRadius: '65px' }}
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.username || !formData.password || loading}
          >
            Log in
          </Button>
        </Form.Group>
      </Form>
      {error && <div className="alert alert-danger mt-4" role="alert">{error}</div>}
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
