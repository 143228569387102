import React from "react";
import { Form, FormControl } from "react-bootstrap";
import InputMask from 'react-input-mask';

const MaskedInput = (props) => {
    return (
        <InputMask {...props}>
            {(inputProps) => <Form.Control type="text" {...inputProps} />}
        </InputMask>
    )
}

// XX-XXXXXXX
// Reference https://www.irs.gov/pub/irs-pdf/p1635.pdf
export const EINInput = (props) => <MaskedInput mask="99-9999999" maskChar={null} {...props} />

// XXXXXXXXXX
// Reference https://ask.fmcsa.dot.gov/app/answers/detail/a_id/245/~/what-is-operating-authority-%28mc-number%29-and-who-needs-it%3F
export const MCInput = ({ ...props }) => <MaskedInput mask="9999999999" maskChar={null} alwaysShowMask={false}  {...props} />
export const MXInput = ({ ...props }) => <MaskedInput mask="9999999999" maskChar={null} alwaysShowMask={false}  {...props} />
export const FFInput = ({ ...props }) => <MaskedInput mask="9999999999" maskChar={null} alwaysShowMask={false}  {...props} />

// XXXXXXXX
// Reference 
export const USDOTInput = ({ ...props }) => <MaskedInput mask="9999999" maskChar={null} alwaysShowMask={false} {...props} />

// +1 (XXX) XXX-XXXX
export const USPhoneInput = ({ ...props }) => <MaskedInput mask={`+1 (999) 999-9999 * 9999999999`} maskChar={null} formatChars={{ '*': '[*]', '9': '[0123456789]' }} placeholder={`+1 (999) 999-9999 * 9999999999`} alwaysShowMask={false} {...props} />

// +1 (XXX) XXX-XXXX
export const USPhoneInput_NO_EXTENSION = ({ ...props }) => <MaskedInput mask={`+1 (999) 999-9999`} maskChar={null} formatChars={{ '*': '[*]', '9': '[0123456789]' }} placeholder={`+1 (999) 999-9999`} alwaysShowMask={false} {...props} />

// +1 (XXX) XXX-XXXX
export const USZIPInput = ({ ...props }) => <MaskedInput mask="99999-9999" maskChar={null} alwaysShowMask={false} {...props} />


export default MaskedInput