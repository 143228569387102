import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";

const CallUs = () => {
    const [phone, setPhone] = useState('')
    const PHONE_NUMBER = '+1 (720) 326 6889'
    const handleClick = e => {
        setPhone(PHONE_NUMBER)
    }

    return (<>
        <Card>
            <Card.Body className="text-center">
                {!phone && <Button
                    onClick={handleClick}
                >Click here to show phone number</Button>}
                <h1>{phone}</h1>
            </Card.Body>
        </Card>
    </>)
}

export default CallUs