import Section from "components/common/Section";
import Error404 from "components/errors/Error404";
import React, { useEffect, useState } from "react";

const fourohfour = () => {

    return (<>
        <Section>
            <Error404 />
        </Section>
    </>)
}

export default fourohfour