import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEmptySet, faFaceMonocle } from "@fortawesome/pro-duotone-svg-icons";

const EmptySetMessage = ({
    condition = true,
    noResultsMessage = <> No results found, try a different search </>,
    noDataMessage = <> You don't have any data yet </>,
    noReultsIcon = faFaceMonocle,
    noDataIcon = faEmptySet,
    action = <></>
}) =>
    <div className="text-400 my-4">
        <FontAwesomeIcon icon={condition ? noReultsIcon : noDataIcon} className="mb-4" size="6x" /><br />
        {condition ? <p>{noResultsMessage}</p> : <p>{noDataMessage}</p>}
        {action}
    </div>

export default EmptySetMessage