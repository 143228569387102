
import { SocketContext } from 'context/SocketContext';
import React from 'react';
import { Button } from 'react-bootstrap';
import APICommunicator from '../../../api/APICommunicator'
import SocketTest from './socketest';

const Dashboard = () => {
  const TestAPI = new APICommunicator('TestRequest');
  return (
    <>
      Running env: {process.env.REACT_APP_API_URL}

      {/* <SocketContext.Consumer>{({ generalSocket, pong }) =>
        <SocketTest socket={generalSocket} pong={pong} />
      }</SocketContext.Consumer> */}

    </>
  );
};

export default Dashboard;
