import React, { useState, useEffect } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";

import APICommunicator from 'api/APICommunicator'
import EditUserModal from '../../modals/EditUserModal';
import { useSearch } from 'context/SearchContext';
import IconButton from 'components/common/IconButton';
import ProfileBanner from './Banner';
import ActivityChart from './ActivityChart';
import { Col, Row } from 'react-bootstrap';
import sampleusersdata from 'data/sample/UsersFakeData';
import activitylogdata from 'data/sample/UserActivityLog';
import CommentsActivityLog from './CommentsActivityLog';
import { getUserData } from 'context/AuthContext';

const Profile = ({ self = false }) => {

  const API = new APICommunicator('Users');
  const history = useHistory()
  const location = useLocation()
  const { addRecentItem } = useSearch()
  const CurrentUser = JSON.parse(getUserData())
  const { USER_ID } = useParams();
  const [userProfile, setUserProfile] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [editUserFormData, setEditUserFormData] = useState({});
  const [RadarStatistics, setRadarStatistics] = useState({
    //[Tracking Updates, Orders Added, Assigned as Dispatch (Assist), Assigned as Customer Representative (Lead), Invoices Created]
    PreviousMonth: [0, 0, 0, 0, 0, 0],
    LastMonth: [0, 0, 0, 0, 0, 0],
    CurrentMonth: [0, 0, 0, 0, 0, 0]
  })
  const [loading, setLoading] = useState(true)

  const GetUser = async () => {
    setUserProfile(false)
    const RN = (M = 1, X = 200) => Math.floor(Math.random() * (X - M + 1)) + M;
    // var USER = sampleusersdata.filter(U => U.uuid === "4b27ebb3-4d6c-4b24-b108-2e7e90bb467c")[0];
    // var USER = sampleusersdata[Math.floor(Math.random() * sampleusersdata.length)];
    // console.log(USER)
    // setEditUserFormData(USER)
    // setUserProfile(USER)
    // console.log(userProfile);
    // addRecentItem({
    //   ...USER,
    //   category_type: 'user',
    //   url: `/Users/${USER.uuid}`,
    //   id: USER.uuid
    // });

    // setTimeout(() => {
    //   setLoading(false)
    //   setRadarStatistics({
    //     PreviousMonth: [210, 47, 43, 45, 0, 0],
    //     LastMonth: [260, 48, 77, 75, 0, 0],
    //     CurrentMonth: [150, 15, 64, 52, 0, 0]
    //   })
    // }, 1500)

    await API.GET(`/${self ? CurrentUser.uuid : USER_ID}`)
      .then(Response => {
        if (!Response || Response.status != 'success') return console.log(Response)
        setLoading(false)
        var USER = Response.content
        setUserProfile(USER);
        setEditUserFormData(USER)
        addRecentItem({
          ...USER,
          category_type: 'user',
          url: `/Users/${USER_ID}`,
          id: USER.uuid
        })
        // console.log(USER)
        document.title = `${USER.firstname} ${USER.lastname} | Users`
          setRadarStatistics({
            PreviousMonth: [RN(190, 300), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100)],
            LastMonth: [RN(190, 300), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100)],
            CurrentMonth: [RN(190, 300), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100), RN(10, 100)]
          })
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    let mounted = true;
    GetUser();
    return () => mounted = false;
  }, [location.pathname])


  useEffect(() => {
    // GetUser();
  }, [])

  return (
    <>
      <IconButton
        onClick={() => history.goBack()}
        variant="falcon-default"
        size="sm"
        icon="arrow-left"
        className=" mt-1"
        transform="shrink-3">
        <span className="d-none d-sm-inline-block ms-1">Back</span>
      </IconButton>
      <br />
      <br />

        <Row className="g-3 mb-3 mt-2">
          <Col lg={8}>
          <ProfileBanner className="mt-3" user={userProfile} loading={loading} />
          <CommentsActivityLog activities={activitylogdata.slice(-5)} />
          </Col>
          <Col lg={4}>
          {/* <div className="sticky-sidebar"> */}
          <div className="">

            <ActivityChart RadarStatistics={RadarStatistics} Loading={loading} className="mt-5" />

            </div>
          </Col>
      </Row>
    </>);
};

export default Profile;
