import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { Logout } from '../../../context/AuthContext'
import { getUserData } from "context/AuthContext"


const ProfileDropdown = () => {

  let USER = {};

  try {
    USER = JSON.parse(getUserData())
  }
  catch (error) {
    USER = {
      firstname: 'TEST',
      lastname: 'test',
      profile_picture: ''
    };
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 nav-link"
      >
        <Avatar src={USER.profile_picture && USER.profile_picture} name={`${USER.firstname} ${USER.lastname}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/MyProfile/">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="/Support/Contact/">Support</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={Logout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
