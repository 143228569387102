import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, Alert, InputGroup } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'

const EditSocialMediaModal = ({ show, handleClose, successCallback, inheritedFormData, user }) => {


    const API = new APICommunicator('Users');
    const [error, setError] = useState(false)
    const [validationError, setValidationError] = useState(false)
    const [fieldName, setFieldName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const [USER, setUser] = useState(false)
    const AllowedURLs = {
        'social_facebook': ['fb', 'facebook'],
        'social_linkedin': ['linkedin'],
        'social_twitter': ['twitter']
    }

    const handleSubmit = e => {
        e.preventDefault()
        setError(false)

        API.PUT(`/${USER?.uuid}`, {
            [fieldName]: fieldValue,
            service_unique_id: USER?.service_unique_id
        })
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }

    const URLRegEx = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i')
    const handleError = Response => setError(API.handleResponseErrors(Response))
    const isValidHttpUrl = str => !!URLRegEx.test(str);
    const getUrlDomainHostname = str => str.match(URLRegEx)[4]
    const handleFieldChange = str => {
        if (!isValidHttpUrl(str)) return setValidationError('Invalid link');
        if (AllowedURLs[fieldName] && AllowedURLs[fieldName].indexOf(getUrlDomainHostname(str)) <= -1) return setValidationError(`This link is not allowed for ${fieldLabel}`)
        setValidationError(false)
        setFieldValue(str)
    }

    useEffect(() => {
        setFieldName(inheritedFormData.field_id)
        setFieldValue(inheritedFormData.field_value)
        setFieldLabel(inheritedFormData.field_label)
    }, [inheritedFormData]);

    useEffect(() => {
        setUser(user)
    }, [user]);

    return (

        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit {fieldLabel} link</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                        <FloatingLabel controlId="floatingInput" label={fieldLabel} className="mb-3">
                        <Form.Control type="input" placeholder={fieldLabel} onChange={e => handleFieldChange(e.target.value)} defaultValue={inheritedFormData.field_value} isInvalid={validationError} />
                            <Form.Control.Feedback type="invalid">
                                {validationError}
                            </Form.Control.Feedback>
                    </FloatingLabel>

                    {error && <Alert variant="warning" dismissible onClose={() => setError(false)}>
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={error}>
                            Submit</Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}

export default EditSocialMediaModal