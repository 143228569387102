import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import {
    useHistory,
    useParams,
    useLocation
} from 'react-router-dom';
import Flex from 'components/common/Flex';
import { sampleData, ServiceRecordStatusTag } from '../components/ServiceRecordsList';
import SoftBadge from 'components/common/SoftBadge';
import moment from 'moment';
import { VehicleShortTag } from 'components/pages/Directory/Vehicles/VehicleShortTag';
import { getRandomNumber } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import FileTag from 'components/files/fileTag';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { RepairListItem } from './RepairList';
import { MaintenanceListItem } from './MaintenanceList';
import VehicleAxlesAndTires from './VehicleAxlesAndTires';

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

export default ({ }) => {
    const history = useHistory()
    const ServiceRecord = sampleData[getRandomNumber(0, sampleData.length)]
    return (<>

        <Row className="mt-2">
            <Col>
                <Card className="mb-4">
                    <Card.Header className="d-flex flex-between-center">
                        {/* Default back button */}
                        <IconButton
                            onClick={() => history.goBack()}
                            variant="falcon-default"
                            size="sm"
                            icon="arrow-left"
                            className="mt-1"
                            transform="shrink-3">
                            <span className="d-none d-sm-inline-block ms-1">Back</span>
                        </IconButton>
                        <Flex>
                            {/* Control Buttons */}
                            <ButtonGroup>
                                -- action buttons --
                            </ButtonGroup>
                        </Flex>
                    </Card.Header>
                </Card>
            </Col>
        </Row>
        <Row className="mt-2">
            <Col >
                <Card>
                    <Card.Header>
                        <span className="fw-bold me-2">
                            <span className='me-2'>{ServiceRecord.type.icon}</span>
                            {ServiceRecord.type.name}
                        </span>
                    </Card.Header>
                    <Card.Body className="bg-light border-top">
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">ASSETS</p>
                                    </Col>
                                    <Col>
                                        <p className='mb-1'>
                                            {
                                                ServiceRecord.vehicles.length >= 1 ?
                                                    ServiceRecord.vehicles.map(Vehicle => <VehicleShortTag key={Vehicle.uuid} Vehicle={Vehicle} />)
                                                    :
                                                    <SoftBadge bg='light'><i>No vehicles selected</i></SoftBadge>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">STATUS</p>
                                    </Col>
                                    <Col>
                                        <p className='mb-1'>
                                            <ServiceRecordStatusTag ServiceRecord={ServiceRecord} />
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">TAGS</p>
                                    </Col>
                                    <Col>
                                        <p className='mb-1'>
                                            {
                                                ServiceRecord.tags.length >= 1 ?
                                                    ServiceRecord.tags.map((Tag, i) => <SoftBadge bg='info' className="me-1" key={i}>{Tag}</SoftBadge>)
                                                    :
                                                    <SoftBadge bg='light'><i>No tags</i></SoftBadge>
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">CONDUCTED</p>
                                    </Col>
                                    <Col>
                                        <p className='mb-1'>
                                            {moment(ServiceRecord.conducted).format('MM/DD/YYYY')}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">DESCRIPTION</p>
                                    </Col>
                                    <Col>
                                        <p className='mb-1'>{ServiceRecord.description}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Col xs={5} sm={4}>
                                    <p className="fw-semi-bold mb-1">FILES</p>
                                </Col>
                                <Col>
                                    <div className='px-1 bg-light rounded-3'>
                                        <div className="d-inline-flex flex-column">
                                            <FileTag file={{
                                                name: 'scan2942835924823',
                                                ext: 'pdf',
                                                size: '832kb'
                                            }} />
                                            <FileTag file={{
                                                name: 'img5234',
                                                ext: 'jpg',
                                                size: '1.2mb'
                                            }} />
                                        </div>
                                        <hr className='my-x1' />
                                        <Row className='flex-between-center gx-4 gy-2'>
                                            <Col>
                                                <p className="fs--1 text-1000 mb-sm-0 font-sans-serif fw-700 mb-0"><FontAwesomeIcon icon={faPaperclip} /> 2 files attached</p>
                                            </Col>
                                            <Col>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="arrow-down"
                                                    className="mt-1"
                                                    transform="shrink-3">
                                                    <span className="d-none d-sm-inline-block ms-1">Download all</span>
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Col>
                        </Row>

                    </Card.Body>
                    <Card.Footer className="border-top">
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <Card>
                    <FalconCardHeader
                        title="Repair Records"
                        light
                        titleTag="h6"
                    />
                    <Card.Body className="py-0">
                        {ServiceRecord.repairs.map((Record, i) => <RepairListItem key={i} Record={Record} />)}
                    </Card.Body>
                    <Card.Footer className="fs--1 text-end bg-light">
                        Total Cost: <b>{moneyFormatter.format(getRandomNumber(500, 10000))}</b>
                    </Card.Footer>
                </Card>
            </Col>
            <Col>
                <Card>
                    <FalconCardHeader
                        title="Maintenance Records"
                        light
                        titleTag="h6"
                    />
                    <Card.Body className="py-0">
                        {ServiceRecord.maintenance.map((Record, i) => <MaintenanceListItem key={i} Record={Record} />)}
                    </Card.Body>
                    <Card.Footer className="fs--1 text-end bg-light">
                        Total Cost: <b>{moneyFormatter.format(getRandomNumber(500, 10000))}</b>
                    </Card.Footer>
                </Card>
            </Col>
            <Col>
                <Card>
                    <FalconCardHeader
                        title="Tire Change Records"
                        light
                        titleTag="h6"
                    />
                    <Card.Body>
                        {ServiceRecord.vehicles.map(Vehicle => <div key={Vehicle.uuid} className="bg-light rounded border-1 p-2 mb-1 border-600">
                            {Vehicle.type} <b>{Vehicle.vehicle_id}</b>
                            <VehicleAxlesAndTires />
                        </div>)}
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </>)
}