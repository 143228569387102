export default [
  // {
  //   id: 0,
  //   userId: 1,
  //   type: 'user',
  //   messagesId: 0,
  //   read: true
  // },
  // {
  //   id: 1,
  //   groupId: 0,
  //   type: 'group',
  //   messagesId: 1,
  //   read: true
  // },
  // {
  //   id: 2,
  //   userId: 3,
  //   type: 'user',
  //   messagesId: 2,
  //   read: true
  // },
  // {
  //   id: 3,
  //   userId: 13,
  //   type: 'user',
  //   messagesId: 3,
  //   read: false
  // },
  // {
  //   id: 4,
  //   userId: 4,
  //   type: 'user',
  //   messagesId: 4,
  //   read: false
  // },
  // {
  //   id: 5,
  //   userId: 5,
  //   type: 'user',
  //   messagesId: 5,
  //   read: true
  // },
  // {
  //   id: 6,
  //   userId: 10,
  //   type: 'user',
  //   messagesId: 6,
  //   read: true
  // },
  // {
  //   id: 7,
  //   userId: 11,
  //   type: 'user',
  //   messagesId: 7,
  //   read: true
  // },
  // {
  //   id: 8,
  //   userId: 12,
  //   type: 'user',
  //   messagesId: 8,
  //   read: true
  // },
  // {
  //   id: 9,
  //   userId: 22,
  //   type: 'user',
  //   messagesId: 9,
  //   read: true
  // },
  // {
  //   id: 10,
  //   userId: 23,
  //   type: 'user',
  //   messagesId: 10,
  //   read: true
  // }
];
