import PageHeader from 'components/common/PageHeader'
import React, { useEffect } from 'react'
import headerbgr from 'assets/img/illustrations/authentication-corner.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBugs, faLifeRing, faPhone, faTicket, faTicketSimple } from '@fortawesome/pro-duotone-svg-icons';
import { Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import TicketForm from './TicketForm';
import CallUs from './CallUs';

const ContactSupport = () => {
    useEffect(() => {
        document.title = `Contact Support`
    }, []);

    return (
        <>
            <PageHeader
                image={headerbgr}
                title="Contact Support"
                description="In this page you will find tools to contact us about issues with the platform, share feedback, request features or bring new ideas"
                className="mb-3"
            />
            <Row>
                <Col>


                    {/* MY TICKETS */}


                    <Row className="d-flex mb-3 mt-4">
                        <Col xs="auto" className="pt-2 ps-2">
                            <FontAwesomeIcon icon={faTicketSimple} className="me-0 ms-2" size="2x" style={{ transform: 'rotate(45deg)' }} />
                        </Col>
                        <Col className="ps-0">
                            <h5 className="mb-0 mt-1 text-primary position-relative">
                                <span className="bg-200 dark__bg-1100 px-2 rounded">My Support Tickets</span>
                                <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                            </h5>
                            <p className="mb-0 px-2 d-none d-sm-inline-block">
                            </p>
                        </Col>
                    </Row>
                    You have no open tickets


                    {/* KNOWN OPEN ISSUES */}

                    <Row className="d-flex mb-3 mt-4">
                        <Col xs="auto" className="pt-2 ps-2">
                            <FontAwesomeIcon icon={faBugs} className="me-0 ms-2" size="2x" />
                        </Col>
                        <Col className="ps-0">
                            <h5 className="mb-0 mt-1 text-primary position-relative">
                                <span className="bg-200 dark__bg-1100 px-2 rounded">Known open issues</span>
                                <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                            </h5>
                            <p className="mb-0 px-2 d-none d-sm-inline-block">
                            </p>
                        </Col>
                    </Row>
                    No known issues found
                </Col>
                <Col xxl={6} xl={7}>
                    <Row className="d-flex mb-3 mt-4">
                        <Col xs="auto" className="pt-2 ps-2">
                            <FontAwesomeIcon icon={faTicket} className="me-0 ms-2" size="2x" style={{ transform: 'rotate(45deg)' }} />
                        </Col>
                        <Col className="ps-0">
                            <h5 className="mb-0 mt-1 text-primary position-relative">
                                <span className="bg-200 dark__bg-1100 px-2 rounded">Submit a Support Ticket</span>
                                <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                            </h5>
                            <p className="mb-0 px-2 d-none d-sm-inline-block">

                            </p>
                        </Col>
                    </Row>
                    <Card>
                        <Card.Body>
                            <Card.Title>Create New Ticket</Card.Title>
                            <TicketForm />
                        </Card.Body>
                    </Card>
                    <Row className="d-flex mb-3 mt-4">
                        <Col xs="auto" className="pt-2 ps-2">
                            <FontAwesomeIcon icon={faPhone} className="me-0 ms-2" size="2x" />
                        </Col>
                        <Col className="ps-0">
                            <h5 className="mb-0 mt-1 text-primary position-relative">
                                <span className="bg-200 dark__bg-1100 px-2 rounded">Call Us</span>
                                <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                            </h5>
                            <p className="mb-0 px-2 d-none d-sm-inline-block">
                                If the matter is not urgent, please utilize our ticket system first, so both you and us can keep track of ongoing issues.
                            </p>
                        </Col>
                    </Row>
                    <CallUs />
                </Col>
            </Row>
        </>
    )
}

export default ContactSupport