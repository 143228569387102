
import { render } from "@testing-library/react";
import Background from "components/common/Background";
import React from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Logo from 'assets/img/logos/TQL.png'
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle, faLink, faLinkHorizontalSlash, faPlusHexagon, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";
import IconAlert from "components/common/IconAlert";


const RequestIntegration = (props) => {
    return (<>
        <Card className="p-2 bg-200"{...props}>
            <Card.Header className="position-relative border-3 border-dashed" >
                <Link to="/Support/Contact">
                    <Flex justifyContent='between'>
                        <div className="flex-1 position-relative ">
                            <h5 className="mb-0 mt-2">Request New Integration</h5>
                        </div>
                        <FontAwesomeIcon icon={faPlusHexagon} size="3x" />
                    </Flex>
                </Link>
            </Card.Header>
        </Card>
    </>)
}

export default RequestIntegration