import React, { useState, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from 'context/Context';
import rawThreads from 'data/chat/threads';
import rawMessages from 'data/chat/messages';
import groups from 'data/chat/groups';
import { arrayReducer } from 'reducers/arrayReducer';
import { SocketContext } from 'context/SocketContext';
import APICommunicator from 'api/APICommunicator';
import { useAuth } from 'context/AuthContext';



const API = new APICommunicator('Users')
const ChatProvider = ({ children }) => {

  const { getCurrentUser } = useAuth()
  const { chatSocket } = useContext(SocketContext);
  const CurrentUserData = getCurrentUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [users, setUsers] = useState([])
  const [messages, messagesDispatch] = useReducer(arrayReducer, rawMessages);
  const [threads, threadsDispatch] = useReducer(arrayReducer, rawThreads);
  const [currentThread, setCurrentThread] = useState(threads[0]);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeThreadId, setActiveThreadId] = useState(threads[0]?.id);
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);

  const getUser = thread => {
    let user = {};
    if (thread.type === 'group') {
      user = users.find(x => x.uuid === thread.userId)
    } else {
      user = users.find(x => x.uuid === thread.userId);
    }
    return user;
  };

  const getThread = thread => threads.find(x => x.id === thread.id)

  const getUserList = () => {
    setError(false)
    setLoading(true)
    API.GET('/')
      .then(Response => {
        setLoading(false)
        if (!Response || Response.status !== 'success') return handleError(Response)
        const AvailableUsers = Response.content.filter(u => (u.status === '0' && u.uuid !== CurrentUserData.uuid))
        console.log('AvailableUsers', AvailableUsers)
        AvailableUsers.map((U, i) => threadsDispatch({
          type: 'ADD',
          payload: {
            id: `user_${U.uuid}`,
            userId: U.uuid,
            type: 'user',
            messagesId: U.uuid,
            read: true
          }
        }))
        setUsers(AvailableUsers)
      })
      .catch(handleError)
  }

  const handleError = Response => setError(API.handleResponseErrors(Response))

  useEffect(() => {
    // console.log(threads)
  }, [threads])

  useEffect(() => {
    getUserList()
  }, [])

  const value = {
    users,
    groups,
    threads,
    getUser,
    messages,
    activeThreadId,
    setActiveThreadId,
    threadsDispatch,
    messagesDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    isOpenThreadInfo,
    setIsOpenThreadInfo,
    currentThread,
    setCurrentThread,
    scrollToBottom,
    setScrollToBottom
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
