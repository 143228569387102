export const DashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      active: true,
      icon: ['fad', 'coffee'],
      exact: true,
      to: '/_d/',
    }
  ]
};

export const OperationsRoutes = {
  label: 'Operations',
  labelDisable: false,
  children: [
    {
      name: 'Booking',
      active: true,
      exact: false,
      to: '/_d/Operations/Booking/',
      icon: ['fad', 'user-headset'],
      restricted_access: true,
    },
    {
      name: 'Scheduling',
      active: true,
      exact: false,
      to: '/_d/Operations/Scheduling/',
      icon: ['fad', 'calendar-lines-pen'],
      restricted_access: true,
    },
    {
      name: 'Orders',
      active: true,
      exact: false,
      to: '/_d/Operations/Orders/',
      icon: ['fad', 'cubes'],
      restricted_access: true,
    },
    {
      name: 'Chat',
      active: true,
      exact: true,
      to: '/_d/Operations/Chat/',
      icon: ['fad', 'comments'],
      restricted_access: false,
    },
    {
      name: 'Email',
      active: true,
      exact: true,
      to: '/_d/Operations/Email/',
      icon: ['fad', 'inbox-full'],
      restricted_access: false,
    }
  ]
};

export const AccountingRoutes = {
  label: 'Accounting',
  labelDisable: false,
  icon: ['fad', 'usd-circle'],
  children: [

    {
      name: 'Dashboard',
      active: true,
      exact: false,
      to: '/_d/Accounting/Dashboard/',
      icon: ['fad', 'analytics'],
      restricted_access: true,
    },

    {
      name: 'Receivables',
      active: true,
      exact: false,
      to: '/_d/Accounting/Receivables/',
      icon: ['fad', 'piggy-bank'],
      children: [
        {
          name: 'Invoices',
          active: true,
          exact: false,
          to: '/_d/Accounting/Receivables/Invoices/',
          icon: ['fad', 'file-invoice'],
          restricted_access: true,
        }
      ]
    },
    {
      name: 'Payables',
      active: true,
      exact: false,
      to: '/_d/Accounting/Payables/',
      icon: ['fad', 'money-check-dollar-pen'],
      children: [
        {
          name: 'Statements',
          active: true,
          exact: false,
          to: '/_d/Accounting/Payables/Statements/',
          icon: ['fad', 'file-invoice-dollar'],
          restricted_access: true,
        }
      ]
    },
    {
      name: 'Expenses',
      active: true,
      exact: false,
      to: '/_d/Accounting/Expenses/',
      icon: ['fad', 'hand-holding-usd'],
      restricted_access: true,
    }
  ]
};

export const SafetyRoutes = {
  label: 'Safety',
  labelDisable: false,
  children: [
    {
      name: 'Incidents',
      active: true,
      exact: false,
      to: '/_d/Safety/Incidents/',
      icon: ['fad', 'car-burst'],
      restricted_access: true,
    },
    {
      name: 'Service',
      active: true,
      exact: false,
      to: '/_d/Safety/Service/',
      icon: ['fad', 'screwdriver-wrench'],
      restricted_access: true,
    }
  ]
};

export const DirectoryRoutes = {
  label: 'Directory',
  labelDisable: false,
  children: [
    {
      name: 'Drivers',
      active: true,
      exact: false,
      to: '/_d/Directory/Drivers/',
      icon: ['fad', 'steering-wheel'],
      restricted_access: true,
    },
    {
      name: 'Equipment',
      active: true,
      exact: false,
      to: '/_d/Directory/Equipment/',
      icon: ['fad', 'garage'],
      restricted_access: true,
    },
    {
      name: 'Companies',
      active: true,
      exact: false,
      to: '/_d/Directory/Companies/',
      icon: ['fad', 'buildings'],
      restricted_access: true,
    },
    {
      name: 'Contacts',
      active: true,
      exact: false,
      to: '/_d/Directory/Contacts/',
      icon: ['fad', 'address-card'],
      restricted_access: true,
    },
    {
      name: 'Places',
      active: true,
      exact: false,
      to: '/_d/Directory/Places/',
      icon: ['fad', 'map-location-dot'],
      restricted_access: true,
    }
  ]
};

export const ReportsRoutes = {
  label: 'More',
  labelDisable: false,
  children: [
    {
      name: 'Reports',
      active: true,
      exact: false,
      to: '/_d/Reports/',
      icon: ['fad', 'file-chart-column'],
      restricted_access: true,
    },
    {
      name: 'Statistics',
      active: true,
      exact: false,
      to: '/_d/Statistics/',
      icon: ['fad', 'chart-simple'],
      restricted_access: true,
    }
  ]
};

export const SettingsRoutes = {
  label: 'Settings and Management',
  labelDisable: false,
  children: [
    {
      name: 'Company Settings',
      active: true,
      exact: false,
      to: '/_d/Settings/Company/',
      icon: ['fad', 'cogs'],
      restricted_access: true,
    },
    {
      name: 'Users',
      active: true,
      exact: false,
      to: '/_d/Users/',
      icon: ['fad', 'users-gear'],
      restricted_access: true,
    },
    {
      name: 'User Roles',
      active: true,
      exact: false,
      to: '/_d/UserRoles/',
      icon: ['fad', 'fa-user-lock'],
      restricted_access: true,
    },
    // {
    //   name: 'Preferences',
    //   active: true,
    //   exact: false,
    //   to: '/_d/Settings/Preferences/',
    //   icon: ['fad', 'sliders-h']
    // },
    // {
    //   name: 'Billing',
    //   active: true,
    //   exact: false,
    //   to: '/_d/Billing/',
    //   icon: ['fad', 'receipt'],
    //   restricted_access: true,
    // },
  ]
};

export const HelpRoutes = {
  label: 'Help',
  labelDisable: false,
  children: [
    // {
    //   name: 'Knwoledgebase',
    //   active: true,
    //   exact: false,
    //   to: '/_d/Support/Knowledgebase/',
    //   icon: ['fad', 'books'],
    //   restricted_access: false,
    // },
    {
      name: 'FAQ',
      active: true,
      exact: false,
      to: '/_d/Support/FAQ/',
      icon: ['fad', 'comments-question-check'],
      restricted_access: false,
    },
    {
      name: 'Contact Support',
      active: true,
      exact: false,
      to: '/_d/Support/Contact/',
      icon: ['fad', 'life-ring'],
      restricted_access: false,
    },
    {
      name: 'Privacy Policy',
      active: true,
      exact: false,
      to: '/_d/Support/PrivacyPolicy/',
      icon: ['fad', 'shield-quartered'],
      restricted_access: false,
    },
    {
      name: 'Terms and Conditions',
      active: true,
      exact: false,
      to: '/_d/Support/TOS/',
      icon: ['fad', 'section'],
      restricted_access: false,
    },
    {
      name: 'Changelog',
      active: true,
      exact: false,
      to: '/_d/Support/Changelog/',
      icon: ['fad', 'code-branch'],
      restricted_access: false,
    }
  ]
};

export default [
  DashboardRoutes,
  OperationsRoutes,
  AccountingRoutes,
  SafetyRoutes,
  DirectoryRoutes,
  ReportsRoutes,
  SettingsRoutes,
  HelpRoutes
];