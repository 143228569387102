import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { isAuthenticated, useAuth } from "../context/AuthContext"

export default function PrivateRoute({ component: Component, ...rest }) {

    const { getCurrentUser } = useAuth()
    const UserData = getCurrentUser()
    const trimmed = { ...UserData }
    delete trimmed.permissions

    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') console.log(trimmed)
    }, [])

    return (
        // <Route {...rest} render={props =>
        //     isAuthenticated() ?
        //         <Component {...props} /> :
        //         <Redirect to="/login" />
        // } />
        <Route {...rest} render={props =>
            <Component {...props} />
        } />
    )
}