import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, Row, Card, Placeholder, OverlayTrigger, Tooltip, Form, Modal } from 'react-bootstrap';
import facebook from 'assets/img/logos/facebook.png';
import twitter from 'assets/img/logos/twitter.png';
import linkedin from 'assets/img/logos/linkedin.png';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileBanner from '../ProfileBanner';
import VerifiedBadge from 'components/common/VerifiedBadge';
import { faAddressCard, faBan, faEnvelopeOpen, faImagePortrait, faInfoCircle, faKey, faLink, faMapLocation, faMessage, faPenToSquare, faPhoneAlt, faPlusCircle, faRotateLeft, faTimes, faUserUnlock } from '@fortawesome/pro-duotone-svg-icons';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { actions } from 'react-table';
import { getUserData, hasPermission, isAuthenticated, hasPermissions } from 'context/AuthContext';
import EditUserModal from 'components/pages/Settings/Users/modals/EditUserModal'
import EditSocialMediaModal from 'components/pages/Settings/Users/modals/EditSocialMediaModal'
import ChangeRoleModal from '../../modals/ChangeRoleModal';
import APICommunicator from 'api/APICommunicator'
import { toast } from 'react-toastify';
import { useAuth } from 'context/AuthContext'
import moment from 'moment';


const Banner = ({ user, loading }) => {
  const API = new APICommunicator('Users');
  const { forgotten_password } = useAuth();
  const CurrentUserData = isAuthenticated() ? JSON.parse(getUserData()) : false
  const [editModalShow, setEditModalShow] = useState(false)
  const [editSMModalShow, setEditSMModalShow] = useState(false)
  const [editRoleModalShow, setEditRoleModalShow] = useState(false)
  const [SMData, setSMData] = useState(false)
  const [USER, setUser] = useState(user)
  const [USER_SANITIZED, setUserSanitized] = useState(false)
  const hiddenFileInput = React.useRef(null);
  const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })
  const reloadUserData = () => { }
  const messageHandle = () => { };
  const editHandle = () => setEditModalShow(true);
  const disableHandle = () => {
    if (!USER) return toast.error('User not defined', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
    API.PUT(`/${USER.uuid}/Disable`).then(Response => {
      if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
      setUser(Response.content)
      toast.success('User successfuly disabled!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    })
  };



  const enableHandle = () => {
    if (!USER) return toast.error('User not defined', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
    API.PUT(`/${USER.uuid}/Enable`).then(Response => {
      if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
      setUser(Response.content)
      toast.success('User successfuly enabled!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    })
  };

  const handlePasswordReset = () => {
    if (!USER) return toast.error('User not defined', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
    console.log(USER)
    forgotten_password(USER.username)
      .then(() => toast.success('New password request sent!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      }))
      .catch(handleErrorWithToast)
  }

  const changeProfilePicture = e => {
    var formData = new FormData()
    const FILES = e.target.files
    const AcceptedFiles = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png', 'image/gif']
    if (!FILES[0]) return () => { console.log('File not upoloaded, no file detected'); console.table(FILES) }
    console.log(FILES[0])
    if (AcceptedFiles.indexOf(FILES[0].type) <= -1) return toast.error('File type not allowed', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
    formData.append('file', FILES[0])

    API.UPLOAD(`/${USER.uuid}/ChangeProfilePicture`, formData)
      .then(Response => {
        if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
        setUser(Response.content)
        toast.success('Profile picture updated!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
      .catch(e => handleErrorWithToast(e))

  }
  const ChangeProfilePictureButtonClick = event => {
    hiddenFileInput.current.click();
  }

  const RemoveProfilePicture = event => {
    API.PUT(`/${USER.uuid}`, {
      profile_picture: '',
      service_unique_id: USER?.service_unique_id
    })
      .then(Response => {
        if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
        setUser(Response.content)
        toast.success('Profile picture removed!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
      .catch(handleErrorWithToast)
  }

  const editSocialMediaHandler = (field_id, field_value, field_label) => {
    // event.preventDefault();
    setSMData({
      'field_id': field_id,
      'field_value': field_value || '',
      'field_label': field_label
    })
    setEditSMModalShow(true)
  }
  const roleChangeHandle = () => {
    setEditRoleModalShow(true)
  }
  const rightSidedItems = [
    {
      'name': 'Facebook',
      link: USER?.social_facebook,
      'icon': <FontAwesomeIcon icon={faFacebook} className="me-2" />,
      'textcolor': 'text-facebook d-inline-block',
      'action': (!loading && (CurrentUserData.uuid === USER.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) ? <div className='text-primary cursor-pointer d-inline-block text-end' onClick={event => editSocialMediaHandler('social_facebook', USER?.social_facebook, 'Facebook')}><h6 className="mb-0"> <FontAwesomeIcon icon={faPenToSquare} /></h6></div> : ''
    },
    {
      'name': 'LinkedIn',
      'link': USER?.social_linkedin,
      'icon': <FontAwesomeIcon icon={faLinkedin} className="me-2" />,
      'textcolor': 'text-linkedin d-inline-block',
      'action': (!loading && (CurrentUserData.uuid === USER.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) ? <div className='text-primary cursor-pointer d-inline-block  text-end' onClick={event => editSocialMediaHandler('social_linkedin', USER?.social_linkedin, 'LinkedIn')}><h6 className="mb-0"> <FontAwesomeIcon icon={faPenToSquare} /></h6></div> : ''
    },
    {
      'name': 'Twitter',
      'link': USER?.social_twitter,
      'icon': <FontAwesomeIcon icon={faTwitter} className="me-2" />,
      'textcolor': 'text-twitter d-inline-block',
      'action': (!loading && (CurrentUserData.uuid === USER.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) ? <div className='text-primary cursor-pointer d-inline-block text-end' onClick={event => editSocialMediaHandler('social_twitter', USER?.social_twitter, 'Twitter')}><h6 className="mb-0"> <FontAwesomeIcon icon={faPenToSquare} /></h6></div> : ''
    }
  ];



  useEffect(() => {
    setUser(user)
    let user_sanitized = { user };

    setUserSanitized({
      address_1: user.address_1,
      address_2: user.address_2,
      city: user.city,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      profile_picture: user.profile_picture,
      role: user.role,
      service_unique_id: user.service_unique_id,
      social_facebook: user.social_facebook,
      social_linkedin: user.social_linkedin,
      social_twitter: user.social_twitter,
      state: user.state,
      status: user.status,
      timezone: user.timezone,
      title: user.title,
      uuid: user.uuid,
      zip: user.zip,
    })

  }, [user])
  // setTimeout(() => { setUser(user) }, 50)
  const ACTIONS = [
    // {
    //   name: 'Message',
    //   onClick: () => messageHandle(),
    //   button: i => <OverlayTrigger overlay={<Tooltip>Message</Tooltip>} key={i}><Button variant="falcon-info" key="Message" size="sm" className="white" onClick={messageHandle}>
    //     <FontAwesomeIcon icon={faMessage} />
    //   </Button></OverlayTrigger>
    // },
    {
      name: 'Edit',
      onClick: () => editHandle(),
      button: i => <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>} key={i}><Button variant="falcon-primary" size="lg" onClick={editHandle}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </Button></OverlayTrigger>
    },
    {
      name: 'Edit Role',
      onClick: () => roleChangeHandle(),
      button: i => <OverlayTrigger overlay={<Tooltip>Edit Role</Tooltip>} key={i}><Button variant="falcon-primary" size="lg" onClick={roleChangeHandle}>
        <FontAwesomeIcon icon={faUserUnlock} />
      </Button></OverlayTrigger>
    },
    {
      name: 'Disable',
      onClick: () => disableHandle(),
      button: i => <OverlayTrigger overlay={<Tooltip>Disable</Tooltip>} key={i}><Button variant="falcon-danger" size="lg" onClick={disableHandle}>
        <FontAwesomeIcon icon={faBan} />
      </Button></OverlayTrigger>
    },
    // {
    //   name: 'Enable',
    //   onClick: () => enableHandle(),
    //   button: i => <OverlayTrigger overlay={<Tooltip>Restore</Tooltip>} key={i}><Button variant="falcon-success" size="lg" onClick={enableHandle}>
    //     <FontAwesomeIcon icon={faRotateLeft} />
    //   </Button></OverlayTrigger>
    // },
    {
      name: 'PasswordReset',
      onClick: () => enableHandle(),
      button: i => <OverlayTrigger overlay={<Tooltip>Reset Password</Tooltip>} key={i}><Button variant="falcon-warning" size="lg" onClick={handlePasswordReset}>
        <FontAwesomeIcon icon={faKey} />
      </Button></OverlayTrigger>
    }
  ];

  return (
    <>
      <ProfileBanner className="mt-3 mb-3" >
        <ProfileBanner.Header profile_picture={USER?.profile_picture} firstname={USER?.firstname} lastname={USER?.lastname} />
        <ProfileBanner.Body className="pt-lg-0">
          <Row>
            <Col lg={4} className="pt-7">
              {(USER && (CurrentUserData.uuid === USER?.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) && <Form.Group controlId="formFile" size="sm">
                <Form.Label>
                  <ButtonToolbar>
                    <ButtonGroup className="me-2 mb-2">
                      <Button variant='falcon-primary' size="sm" className="mb-1" onClick={ChangeProfilePictureButtonClick}>
                        <FontAwesomeIcon icon={faImagePortrait} /> Update profile picture
                      </Button>
                      <Button variant='falcon-danger' size="sm" className="mb-1" onClick={RemoveProfilePicture}><FontAwesomeIcon icon={faTimes} /></Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Form.Label>
                <Form.Control type="file" className={'d-none'} ref={hiddenFileInput} onChange={changeProfilePicture} accept=".jpg,.png,.gif,.bmp" />
              </Form.Group>
              }
              <div className="text-muted">{USER?.uuid ? USER?.date_added !== '0000-00-00' && `Member since ${moment(USER?.date_added).format('MMM DD, YYYY')}` : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={12} /></Placeholder>}</div>
            </Col>
            <Col className="ps-2 ps-lg-3">
              {/* name and title  */}
              <h4 className="mb-1">
                {(USER?.firstname && USER?.lastname) ? `${USER?.firstname} ${USER?.lastname}` : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={12} /></Placeholder>}
              </h4>
              <h5 className="fs-0 fw-normal">
                {USER?.uuid ? USER?.title || <br /> : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={6} /></Placeholder>}
              </h5>
              <div className="border-dashed-bottom my-3"></div>
              {/* title */}
              <Flex alignItems="center" className="mb-2 text-info">
                <FontAwesomeIcon className="me-2" icon={faMapLocation} />
                <div className="flex-1">
                  <h6 className="mb-0">{USER?.uuid ? USER?.timezone : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={6} /></Placeholder>}</h6>
                </div>
              </Flex>
              {/* email */}
              <Link to='#' onClick={() => window.location = `mailto:${USER?.email}`} rel="noopener noreferrer" className="text-info" >
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon className="me-2" icon={faEnvelopeOpen} />
                  <div className="flex-1">
                    <h6 className="mb-0">{USER?.uuid ? USER?.email : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={7} /></Placeholder>}</h6>
                  </div>
                </Flex>
              </Link>
              {/* phone */}
              <Link to='#' onClick={() => window.location = `tel:${USER?.phone}`} rel="noopener noreferrer" className="text-info" >
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon className="me-2" icon={faPhoneAlt} />
                  <div className="flex-1">
                    <h6 className="mb-0">{USER?.uuid ? USER?.phone : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={6} /></Placeholder>}</h6>
                  </div>
                </Flex>
              </Link>
            </Col>
            <Col className="ps-2 ps-lg-3">
              {USER?.uuid ? <ButtonToolbar>
                <ButtonGroup className="me-2 mb-2">
                  {ACTIONS.map((ACTION, i) => {
                    if (ACTION.name === 'Message' && CurrentUserData.uuid !== USER?.uuid && USER?.status === '0') return ACTION.button(i)
                    if (ACTION.name === 'Edit' && (CurrentUserData.uuid === USER?.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) return ACTION.button(i)
                    if (ACTION.name === 'Edit Role' && (CurrentUserData.uuid === USER?.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS', 'MANAGE_ROLES']))) return ACTION.button(i)
                    if (ACTION.name === 'Disable' && (CurrentUserData.uuid !== USER?.uuid && ((USER?.status === '0') && hasPermissions(['MANAGE_USERS', 'VIEW_USERS'])))) return ACTION.button(i)
                    if (ACTION.name === 'Enable' && (CurrentUserData.uuid !== USER?.uuid && ((USER?.status === '1') && hasPermissions(['MANAGE_USERS', 'VIEW_USERS'])))) return ACTION.button(i)
                    if (ACTION.name === 'PasswordReset' && (CurrentUserData.uuid === USER?.uuid || hasPermissions(['MANAGE_USERS', 'VIEW_USERS']))) return ACTION.button(i)
                    return ''
                  })}
                </ButtonGroup>
              </ButtonToolbar> : <Placeholder as={Card.Text} animation="glow"><Placeholder xs={12} /></Placeholder>}
              <div className="border-dashed-bottom my-3"></div>
              {!loading && rightSidedItems.map(item => (
                <div key={item.name}>
                  <Link
                    to={{ pathname: item.link }}
                    className={item.link ? item.textcolor : 'text-muted cursor-default disabled-link d-inline-block'}
                    // style={{ 'pointerEvents': item.link ? 'auto' : 'none' }}
                    target={item.link ? "_blank" : "_self"}
                    rel="noopener noreferrer">
                    <Flex alignItems="center" className="mb-2">
                      {item.icon}
                      <div className="flex-1">
                        <h6 className="mb-0">
                          {
                            !loading ?
                              <div>{item.name}</div>
                              :
                              <Placeholder as={Card.Text} animation="glow"><Placeholder xs={12} /></Placeholder>
                          }
                        </h6>
                      </div>
                    </Flex>
                  </Link> {item.action}

                </div>
              ))}
            </Col>
          </Row>
        </ProfileBanner.Body>
      </ProfileBanner >
      <EditUserModal
        show={editModalShow}
        USER_ID={USER?.uuid}
        handleClose={() => { setEditModalShow(false) }}
        successCallback={u => { setUser(u) }}
        inheritedFormData={USER}
      />
      <EditSocialMediaModal
        show={editSMModalShow}
        inheritedFormData={SMData}
        handleClose={() => { setEditSMModalShow(false) }}
        successCallback={u => { setUser(u) }}
        user={USER}
      />
      <ChangeRoleModal
        show={editRoleModalShow}
        handleClose={() => { setEditRoleModalShow(false) }}
        successCallback={u => { setUser(u) }}
        USER={USER}
      />
    </>
  );
};

export default Banner;
