import React from 'react'
import { Col, Row, Offcanvas } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom'
import Permissions from 'data/important/permissions'


const RoleInfo = ({ show, handleClose, ROLE, ...rest }) => {

    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement='end'
                {...rest}
            >
                <Offcanvas.Header className="" closeButton>
                    <div>
                        <h4>{ROLE?.name}</h4>
                        <p className="text-muted fs--1 mb-0">{ROLE?.description}</p>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <Row className="gx-0 gy-1 text-center">
                        <Col>
                            <div className="border-dashed-bottom my-3"></div>
                            <p className='text-muted'>Assigned users</p>
                        </Col>
                    </Row>
                    <Row className="gx-0 gy-1 text-center">
                        {(ROLE?.users) && (ROLE.users.length >= 1) ? ROLE.users.map(USER =>
                            <Col key={USER.uuid} xs={2}>
                                <Link to={`/Users/${USER.uuid}`}>
                                    <div className="bg-white dark__bg-1100 p-0 h-100">
                                        <Avatar
                                            size="2xl"
                                            className="mb-0"
                                            src={USER.profile_picture && USER.profile_picture}
                                            name={(USER.firstname && USER.lastname) && `${USER.firstname} ${USER.lastname}`}
                                            mediaClass="shadow-sm"
                                            width={100}
                                        />
                                        <h6 className="mb-1">
                                            {USER.firstname} {USER.lastname}
                                        </h6>
                                    </div>
                                </Link>
                            </Col>
                        ) :
                            <i className="text-center fs--2 text-muted">No users assigned</i>
                        }
                    </Row>
                    <Row className="gx-0 gy-1 text-center">
                        <Col>
                            <div className="border-dashed-bottom my-3"></div>
                            <p className='text-muted'>Permission list</p>
                        </Col>
                    </Row>
                    <Row className="gx-0 gy-1">
                        {(ROLE?.permissions) && (ROLE.permissions.length >= 1) ? ROLE.permissions.map(PERMISSION =>
                            <div key={PERMISSION}>
                                <h6 className="mb-0">
                                    {Permissions.find(x => x.code_name === PERMISSION)?.name}
                                </h6>
                                <p className="fs--2 mb-1">
                                    {Permissions.find(x => x.code_name === PERMISSION)?.description}
                                </p>
                            </div>) :
                            <i className="text-center fs--2 text-muted">No permissions selected</i>
                        }
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RoleInfo;