import StarRating from "components/common/StarRating";
import { copyToClipBoard } from "helpers/utils";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import moment from "moment";
import SampleUserData from 'data/sample/Users.json'
import Comments from "./Comments";
import GoogleMap from 'components/map/GoogleMap';
import Avatar from "components/common/Avatar";
import { isAuthenticated, getUserData } from "context/AuthContext";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-duotone-svg-icons";
import AverageHoldTime from "../charts/AverageHoldTime";
import nl2br from "react-nl2br";
import MarkerIcon from "assets/img/icons/map-marker.png"
import SimpleBarReact from 'simplebar-react';





const PlaceView = ({ show, handleClose, place, ...rest }) => {
    const LoggedInUser = isAuthenticated() ? JSON.parse(getUserData()) : false
    const [PLACE, setPlace] = useState(false)
    const [rating, setRating] = useState(0)
    const [comments, setComments] = useState(0)
    const [newComment, setNewComment] = useState('')
    const [newRating, setNewRating] = useState('')
    const [mapCenter, setMapCenter] = useState({
        lat: '37.6',
        lng: '-95.665'
    })
    const [isMapIdle, setIsMapIdle] = useState(false)
    const [bodyHeight, setBodyHeight] = useState(10)
    const headerRef = useRef(null)
    const TOTAL_USERS = SampleUserData.length
    var NUMBER_COMMENTS = Math.floor(Math.random() * (TOTAL_USERS - 0 + 1) + 0)

    const handleRatingChange = v => {
        console.log(v)
    }

    const handleSubmitComment = e => {
        e && e.preventDefault()
        console.log(newComment, newRating)
        if (newComment || newRating) comments.unshift({
            userId: LoggedInUser.uuid,
            avatarSrc: LoggedInUser.profile_picture,
            name: `${LoggedInUser.firstname} ${LoggedInUser.lastname}`,
            content: newComment,
            postTime: 'Just now',
            rating: newRating
        })
        setNewComment('')
        setNewRating(0)
    }

    useEffect(() => {

        NUMBER_COMMENTS = Math.floor(Math.random() * (TOTAL_USERS - 0 + 1) + 0)
        var comments = []
        for (let i = 0; i < NUMBER_COMMENTS; i++) {
            let user = SampleUserData[i];
            comments[i] = {
                id: user.id,
                userId: user.uuid,
                avatarSrc: user.profile_picture,
                name: `${user.firstname} ${user.lastname}`,
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                postTime: moment().format('MM/DD/YY HH:mm'),
                rating: Math.floor(Math.random() * (5 - 0 + 1) + 0)
            }
        }
        setComments(comments)

    }, [place])

    useEffect(() => {
        if (place) setPlace(place)
        setRating(Math.floor(Math.random() * (5 - 0 + 1) + 0))
        setIsMapIdle(false)
    }, [place])


    useEffect(() => {
        console.log(PLACE)
        if (PLACE && isMapIdle) setMapCenter({
            lat: parseFloat(PLACE.lat),
            lng: parseFloat(PLACE.lng)
        })
    }, [isMapIdle, PLACE])

    useEffect(() => {
        const calc = window.innerHeight - (headerRef?.current?.clientHeight || 0)
        console.log('bodyHeight', calc)
        setBodyHeight(calc)
    }, [PLACE])
    return (<>
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement='end'
            backdrop={true}
            scroll={false}
            {...rest}
        >
            <Offcanvas.Header className=" mb-0 " ref={headerRef} closeButton>
                <Flex justifyContent="between" className="w-100">
                    <div>
                        <h4 className="fw-bold">
                            {PLACE?.name}
                            <p className="text-muted fs--1 mb-0 fw-normal">{PLACE?.type}</p>
                        </h4>
                        {(PLACE?.phone || PLACE?.email) && <div className="mb-1">
                            {PLACE?.phone &&
                                <div>
                                    <a href={`tel:${PLACE?.phone}`}>
                                        <FontAwesomeIcon icon={faPhone} /> {PLACE?.phone}
                                    </a>
                                </div>
                            }
                            {PLACE?.email &&
                                <div>
                                    <a href={`mailto:${PLACE?.email}`}>
                                        <FontAwesomeIcon icon={faEnvelope} /> {PLACE?.email}
                                    </a>
                                </div>
                            }
                        </div>
                        }
                        <OverlayTrigger placement="left" overlay={<Tooltip>Click to copy</Tooltip>}>
                            <div className="cursor-pointer fs--1" onClick={() => copyToClipBoard(`${PLACE?.address}, ${PLACE?.city}, ${PLACE?.state} ${PLACE.zip}`)}>
                                {PLACE?.address}<br />
                                {PLACE?.city}, {PLACE?.state} {PLACE.zip}
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Click to copy</Tooltip>}>
                            <span className="text-400 cursor-pointer" onClick={() => copyToClipBoard(`${PLACE?.lat}, ${PLACE?.lng}`)}>
                                {PLACE?.lat}, {PLACE?.lng}
                            </span>

                        </OverlayTrigger>
                        <br />
                        <StarRating
                            rating={PLACE?.rating || 0}
                            readonly={true}
                            size="1x"
                            className=""
                        />
                        <br />
                        {(PLACE?.rating && NUMBER_COMMENTS >= 1) ?
                            <><b>{rating}</b> of <b>5</b> stars from <b>{NUMBER_COMMENTS}</b> reviews</>
                            :
                            <span className="text-muted fs--1"><i>not yet rated</i></span>
                        }
                    </div>
                </Flex>
            </Offcanvas.Header>
            <Offcanvas.Body className=" mt-0 px-0 pt-0" style={{ overflow: 'hidden' }}>
                <SimpleBarReact className="pb-2" style={{ maxHeight: bodyHeight, overflowX: 'hidden' }}>
                    <Row className="px-3">
                    <Col xs={12} className="bg-100 py-2 border-1 border-dark">
                        <AverageHoldTime />
                    </Col>
                </Row>
                    <Row>
                        <Col>
                    <GoogleMap
                                initialCenter={mapCenter}
                                center={mapCenter}
                                markerIcon={{
                                    url: MarkerIcon
                        }}
                        zoomControl={false}
                        scaleControl={false}
                        streetViewControl={false}
                        rotateControl={false}
                        fullscreenControl={false}
                                mapType="hybrid"
                        mapStyle="Default"
                        darkStyle="Midnight"
                        className="min-vh-25 my-2"
                                onIdle={() => {
                                    setIsMapIdle(true)
                                }}
                            />
                        </Col>
                </Row>
                    <Row className="">
                    <Col>
                            {PLACE?.internal_notes && <>
                                <h6 className="fs--1 mb-0 bg-200 border-bottom-0 bg-light px-3 py-2 text-warning fw-bold">
                                    Internal Notes
                                </h6>
                                <div className={`fs--1 py-1 mb-1 text-warning p-3`} >
                                    {nl2br(PLACE?.internal_notes)}
                            </div>
                            </>
                        }
                            {PLACE?.public_notes && <>
                                <h6 className="fs--1 mb-0 bg-light px-3 py-2 fw-bold">
                                    Public Notes
                                </h6>
                                <div className={`fs--1 p-3`}>
                                    {nl2br(PLACE?.public_notes)}
                            </div>
                            </>
                        }
                    </Col>
                </Row>

                <Row className="border-top-1 border-top-dashed mt-3 px-3">
                    <h6 className="mt-1 text-muted text-500">Reviews:</h6>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmitComment} >
                            <Flex alignItems="center" className="border-top border-200 pt-3">
                                <Avatar
                                    src={LoggedInUser?.profile_picture && LoggedInUser.profile_picture}
                                    name={LoggedInUser && (`${LoggedInUser.firstname} ${LoggedInUser.lastname}`)}
                                    size="xl"
                                />
                                <Form.Control
                                    type="text"
                                    onKeyPress={event => (event.key === 'Enter') && handleSubmitComment(false)}
                                    className="roudned-pill ms-2 fs--1"
                                    placeholder="Write a comment"
                                    value={newComment}
                                    onChange={e => setNewComment(e.target.value)}
                                />
                            </Flex>
                            <Flex alignItems="center" justifyContent="between" className="pt-1">
                                <div className="mt-1">
                                    <h6 className="d-inline-block fs--2">Rating:</h6> <StarRating rating={newRating || 0} handleChange={v => setNewRating(v)} />
                                </div>
                                <Button variant="falcon-primary" className="rounded-pill" size="sm" type="submit" >Add review</Button>
                            </Flex>

                        </Form>
                    </Col>
                    <Col>
                        <Comments comments={comments} />
                    </Col>
                </Row>
                </SimpleBarReact>
            </Offcanvas.Body>

        </Offcanvas>
    </>)
}


export default PlaceView