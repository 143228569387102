import React, { useState } from "react";
import AtlassDataTable from "components/common/AtlassTable"
import { Button, ButtonGroup, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEmptySet, faPenToSquare, faSearch, faSpinnerThird, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import { hasPermissions } from "context/AuthContext";

import { AtlassPlaceTypeSelect, AtlassStateSelect_NA } from "components/common/AtlassSelect";
import StarRating from "components/common/StarRating";
import { debounce } from "helpers/utils";
import IconButton from "components/common/IconButton";
import { AddNewContactDefaultButton } from ".";
import EmptySetMessage from "components/common/EmptySetMessage";

const FiltersBox = ({ onFilterChanged, loading }) => {

    return <Row className="flex-end-center mb-3">
        {/* Filter Searchbox */}
        <Col className="mb-2 mb-lg-0" xs={12} sm={12} lg={12}>
            <div className="position-relative">
                <Form className="position-relative">
                    <Form.Control
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className="search-input ps-4"
                        onChange={e => onFilterChanged('search', e.target.value)}
                    />
                    {loading ?
                        <FontAwesomeIcon icon={faSpinnerThird} className="position-absolute top-0 mt-2 ms-2 text-primary search-box-icon fa-spin" />
                        :
                        <FontAwesomeIcon icon={faSearch} className="position-absolute top-0 mt-2 ms-2 text-400 search-box-icon"
                        />
                    }
                </Form>
            </div>
        </Col>

    </Row>
}

export const ContactsTable = ({
    actionCallback = () => { }
}) => {

    const [Filters, setFilters] = useState(false)
    const [viewWindow, setViewWindow] = useState(false)
    const [editWindow, setEditWindow] = useState(false)
    const [editItem, setEditItem] = useState(false)


    const handleViewClick = itemData => setViewWindow(itemData)

    const handleEditClick = itemData => {
        setEditWindow(true)
        setEditItem(itemData)
    }
    const handleDeleteClick = itemData => { }

    const handleFilterChange = (name, value) => debounce(() => {

        setFilters(current => {
            const newFilters = {
                ...current,
                [name]: value
            }
            if (!value) delete newFilters[name]
            return newFilters
        })

    }, 150)()

    const [loading, setLoading] = useState(true)

    const columns = [
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'company',
            Header: 'Company',
        },
        {
            accessor: 'phone',
            Header: 'Contact',
            canSort: false,
            Cell: rowData => {
                const { phone, email } = rowData
                return <>
                    <div><a href={`tel:${phone}`} >{phone}</a></div>
                    <a href={`mailto:${email}`}>{email}</a>
                </>
            }
        },
        {
            accessor: 'id',
            Header: 'Actions',
            defaultSort: 'desc',
            headerClassName: 'bg-200 text-900 text-nowrap align-end',
            canSort: false,
            Cell: rowData => <ButtonToolbar className=" justify-content-end">
                <ButtonGroup>
                    {hasPermissions(['MANAGE_CONTACTS']) && <Button variant="falcon-default" onClick={() => { handleEditClick(rowData); }} size="sm"><FontAwesomeIcon icon={faPenToSquare} /></Button>}
                    {hasPermissions(['MANAGE_CONTACTS']) && <Button variant="falcon-danger" onClick={() => { handleDeleteClick(rowData) }} size="sm"><FontAwesomeIcon icon={faTrashAlt} /></Button>}
                </ButtonGroup>
            </ButtonToolbar>
        }
    ];

    return <>
        <div className="px-3 mt-1">
            <FiltersBox onFilterChanged={handleFilterChange} loading={loading} />
        </div>
        <AtlassDataTable
            source={[]}
            columns={columns}
            requestFilters={Filters}
            isLoading={setLoading}
            tableMisc={{
                noResultsDisplay: <EmptySetMessage
                    condition={Object.keys(Filters).length >= 1}
                    action={hasPermissions(['VIEW_CONTACTS', 'MANAGE_CONTACTS']) && <AddNewContactDefaultButton />}
                />
            }}
        />
    </>
}