import { getRandomNumber } from "helpers/utils";
import { inLastMonth } from "is_js";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuid } from 'uuid';

export const AxleAndTires = ({ Axle }) => {

    return (<>

    </>)
}
const ri = arr => arr[getRandomNumber(0, arr.length - 1)];

export const Axle = ({ Axle }) => {
    return <div className="bg-300 rounded-3 px-6 py-2 m-2 d-inline-block"></div>
}

export const Tire = ({ Tire }) => {
    return <OverlayTrigger overlay={<Tooltip>asd</Tooltip>}>
        <div className="bg-600 rounded d-inline-block px-2 py-3 bg-chevrons">
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
            <div><b></b><b></b><b></b><b></b><b></b></div>
        </div>
    </OverlayTrigger>
}

export default ({ Vehicle, TireServiceRecords }) => {

    // * TEMP
    const Axles = [
        {
            uuid: uuid(),
            model: null,
            type: null,
            tireCount: 2,
            tires: [
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 1,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 0,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                }
            ]
        },
        {
            uuid: uuid(),
            model: null,
            type: null,
            tireCount: 4,
            tires: [
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 1,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 3,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 0,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 2,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                }
            ]
        },
        {
            uuid: uuid(),
            model: null,
            type: null,
            tireCount: 4,
            tires: [
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 1,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 3,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 0,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                },
                {
                    uuid: uuid(),
                    replaced: 'no',
                    position: 2,
                    size: '295/75R22.5',
                    makeAndModel: 'Bridgestone',
                    type: 'NEW'
                }
            ]
        }
    ]

    const TSR = [{
        type: 'REPLACE',
        description: null,
        tire: ri(Axles[0].tires)
    }, {
        type: 'REPAIR',
        description: null,
        tire: ri(Axles[0].tires)
    }]

    const getSetOfTires = Axle => {

        const Tires = Axle.tires.sort((a, b) => a.position - b.position)
        const TireCount = Tires.length
        let FirstSet = []
        let SecondSet = []

        for (let i = 0; i < (TireCount / 2); i++) {
            FirstSet.push(Tires[i])
        }
        for (let i = TireCount - 1; i > (TireCount / 2) - 1; i--) {
            SecondSet.push(Tires[i])
        }

        return {
            first: FirstSet,
            second: SecondSet.sort((a, b) => a.position - b.position)
        }
    }

    getSetOfTires(Axles[0])
    return <div className="text-center  justify-content-center ">
        <i className="text-300">NOSE</i>
        {Axles.map(axle => <div key={axle.uuid} className=" align-center justify-center center">
            <div className=" d-flex gap-2 justify-content-center mb-1">

                {getSetOfTires(axle).first.map(tire =>
                    <Tire Tire={tire} key={tire.uuid} />
                )}

                <Axle Axle={axle} />

                {getSetOfTires(axle).second.map(tire =>
                    <Tire Tire={tire} key={tire.uuid} />
                )}
            </div>
        </div>)}
        <i className="text-300">REAR</i>
    </div>
}