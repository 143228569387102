import React from "react";
import { Placeholder, Table } from "react-bootstrap";

const TableLoadingPlaceholder = ({
    rows,
    cols,
}) => {

    const Rows = Array(rows || 4).fill().map((x, i) => i)
    const Cols = Array(cols || 3).fill().map((x, i) => i)

    return <Table borderless className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info" >
        <tbody>
            {Rows.map(i =>
                <tr key={i}>
                    {Cols.map(k =>
                        <td key={k}><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                    )}
                </tr>
            )}
        </tbody>
    </Table>
}
export default TableLoadingPlaceholder