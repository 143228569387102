import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import { getColor, getRandomNumber } from 'helpers/utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer
]);


const minutesToHourMinutes = minutes => {
    return {
        'hours': Math.floor(minutes / 60),
        'minutes': minutes % 60
    }
}

const minutesToHourMinutesString = m => `${minutesToHourMinutes(m).hours}h ${minutesToHourMinutes(m).minutes}m`

const getOptions = (data, radius) => ({
    color: [
        getColor(data.chartData[0].color),
        getColor(data.chartData[1].color),
        getColor(data.chartData[2].color)
    ],

    tooltip: {
        padding: [7, 10],
        transitionDuration: 1,
        backgroundColor: getColor('200'),
        borderColor: getColor('200'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        formatter: params =>
            `${params.data.name}: <strong>${params.percent}%</strong> of the visits`
    },
    xAxis: {
        type: 'category',
        show: false,
        boundaryGap: false
    },
    yAxis: {
        show: false,
        type: 'value',
        boundaryGap: false
    },
    series: [
        {
            name: minutesToHourMinutesString(data.chartInfo.totalAverage),
            type: 'pie',
            radius,
            avoidLabelOverlap: false,
            emphasis: {
                scale: false
            },
            itemStyle: {
                transitionDuration: 150,
                borderWidth: 1,
                borderColor: getColor('200')
            },
            label: {
                show: true,
                position: 'center',
                formatter: '{a}',
                fontSize: 15,
                color: getColor('dark')
            },
            data: data.chartData.map(item => ({
                ...item
            }))
        }
    ]
});


const HoldTimeItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    const percentage = ((value * 100) / total).toFixed(0);
    return (
        <Flex
            alignItems="center"
            justifyContent="between"
            className={`w-75 fw-semi-bold fs--2 ${index == 0 && 'mt-1'}`}
        >
            <p className="mb-0">
                <FontAwesomeIcon
                    icon="circle"
                    className={`me-2 text-${color || 'text-defaul'}`}
                />
                {name}
            </p>
            <div className="">{percentage}%</div>
        </Flex>
    );
};

const AverageHoldTime = () => {


    const RecordCount = getRandomNumber(400, 500);
    var AllRecords = []
    for (let i = 0; i < RecordCount; i++) {
        AllRecords[i] = getRandomNumber(30, 730)
    }

    // const MinWait = getRandomNumber(30, 120);
    // const MaxWait = getRandomNumber(120, 700);
    const totalAverage = (AllRecords.reduce((a, b) => a + b) / AllRecords.length).toFixed(0)
    const under2Hours = AllRecords.filter(item => item <= 120).length
    const under5Hours = AllRecords.filter(item => (item > 120 || item <= 300)).length
    const over5Hours = AllRecords.filter(item => item > 300).length

    const averageHoldTimes = {
        'chartData': [
            { id: 1, value: under2Hours, name: 'Under 2 hours', color: 'success' },
            { id: 2, value: under5Hours, name: 'Under 5 hours', color: 'warning' },
            { id: 3, value: over5Hours, name: 'Over 5 hours', color: 'danger' }
        ],
        'chartInfo': {
            'recordCount': RecordCount,
            'totalAverage': totalAverage
        }
    }
    return (<>
        <Row className="justify-content-between g-0">
            <Col xs={6} sm={6} xxl className="pe-2">
                <h6 className="mt-y mb-0 fw-bold">Average Hold Times<div className="text-info fw-normal"> Data from {averageHoldTimes.chartInfo.recordCount} visits</div></h6>
                {averageHoldTimes.chartData.map((item, index) =>
                    <HoldTimeItem
                        item={item}
                        index={index}
                        key={index}
                        total={averageHoldTimes.chartInfo.recordCount}
                    />
                )}
            </Col>
            <Col xs="auto">
                <div className="ps-0">
                    <ReactEChartsCore
                        echarts={echarts}
                        option={getOptions(averageHoldTimes, ['100%', '87%'])}
                        style={{ width: '5.625rem', height: '5.625rem' }}
                    />

                </div>
            </Col>
        </Row>
    </>)
}

export default AverageHoldTime