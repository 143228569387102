import { render } from "@testing-library/react";
import Background from "components/common/Background";
import React from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Logo from 'assets/img/logos/DAT.png'
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle, faLink, faLinkHorizontalSlash, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";
import IconAlert from "components/common/IconAlert";


const DATIntegration = (props) => {
    return (<>
        <Card {...props}>
            <Card.Header className="position-relative" >
                <Flex justifyContent='between'>
                    <div className="flex-1 position-relative ">
                        <h5>DAT LoadBoards</h5>
                        <p className="fs--1 text-muted mb-0">Connect to DAT so you can find loads easier and faster</p>
                    </div>
                    <Image height={48} src={Logo} className="bg-white px-2 py-1 light rounded" />
                </Flex>
            </Card.Header>
        </Card>
    </>)
}

export default DATIntegration