import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dashboardIllustration from 'assets/img/illustrations/aniket/Illustration-SVG.svg';
import bg1 from 'assets/img/backgrounds/shutterstock_v25087742.jpg';
import bgv1 from 'assets/img/backgrounds/shutterstock_v250877421.webm';
import dashboard from 'assets/img/generic/dashboard-alt.png';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import LoadsPage from 'assets/img/platform-illustrations/Loads-page.png'


const Hero = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      video={[bgv1]}
      // bg="dark"
      overlay="0"
      position="center bottom"

    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-8 pb-sm-0 pb-xl-0">
        <Col
          md={12}
          lg={10}
          xl={4}
          className="pb-2 pb-xl-9 text-center text-xl-start"
        >
          {/* <Button
            as={Link}
            variant="outline-success"
            className="mb-4 fs--1 border-2 rounded-pill"
            to="#!"
          >
            <span className="me-2" role="img" aria-label="Gift">
              ⚡
            </span>
            Request a demo
          </Button> */}
          <h1 className="text-white fw-light">
            Bring
            <Typed
              strings={['technology', 'the future', 'efficiency', 'profitability', 'success']}
              typeSpeed={40}
              backSpeed={50}
              className="fw-bold ps-2"
              loop
            />
            <br />
            to your business
          </h1>
          <p className="lead text-white opacity-75">
            
          </p>
        </Col>
        <Col
          xl={{ span: 8 }}
          className="align-self-end"
          style={{ "marginTop": "-4rem" }}
        >
          <Link to="/" className="">
            <img
              className="img-fluid pt-sm-6"
              // src={isDark ? dashboardDark : dashboard}
              src={LoadsPage}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
