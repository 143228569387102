
import Flex from "components/common/Flex";
import IconAlert from "components/common/IconAlert";
import GoogleMap from "components/map/GoogleMap";
import React, { useContext, useEffect, useState } from "react";
import AppContext from 'context/Context';
import { Button, Offcanvas, Tab, Table, Tabs, } from "react-bootstrap";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import MarkerIcon from "assets/img/icons/map-marker.png"
import { Link } from "react-router-dom";
import { RawDetailsDecode, GoogleAutocomplete, DecodePlaceDetails } from "helpers/googleMapUtils";
import { faLightbulb, faPenToSquare, faPlusHexagon, faSave, faSparkles } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MemoryDismissibleAlert from "components/common/DismissibleAlert";
import AddEditPlaceFormNoSubmit from "../forms/AddEditPlaceFormNoSubmit";






const DisplayPlaceDetails = ({ place }) => {

    return <>
        <span className="text-500 fs--2 ps-2 mb-0">Data from the location you selected:</span>
        <Table className="fs--1 mb-0">
            <tbody>
                {RawDetailsDecode.map(item =>
                    <tr key={item.Display}>
                        <td className="bg-100 py-1" dangerouslySetInnerHTML={{ __html: item.Display }}>

                        </td>
                        <td className="fw-bold py-1">{place[item.DataName]}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    </>

}

const AddEdit = ({ place, show, handleClose, ...rest }) => {

    const DefaultMapCenter = {
        lat: '37.6',
        lng: '-95.665'
    }
    const DefaultPlaceViewZoom = 17
    const DefaultMapZoom = 4

    const {
        config: { isDark }
    } = useContext(AppContext);
    const [activeTab, setActiveTab] = useState('quick')
    const [PLACE, setPlace] = useState(false)
    const [formData, setFormData] = useState(false)
    const [mapCenter, setMapCenter] = useState(DefaultMapCenter)
    const [isMapIdle, setIsMapIdle] = useState(false)
    const [addressWarning, setAddressWarning] = useState(false)
    const [mapZoom, setMapZoom] = useState(DefaultMapZoom)
    const {
        placesService,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 750,
        language: 'en',
        options: {
            type: 'establishment',//https://developers.google.com/maps/documentation/javascript/supported_types  TABLE 3
            componentRestrictions: { country: ['us', 'ca', 'mx'] } //https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes Alpha-2 code
        }
    });

    const handleSubmit = e => {
        e.preventDefault();
        console.log(PLACE)
    }


    useEffect(() => {
        if (place) {
            setPlace({
                ...place,
                address_formatted: place.address_formatted ? place.address_formatted : `${place.address}, ${place.address2 ? place.address2 + ',' : ''} ${place.city}, ${place.state} ${place.zip}`
            })
            if (place?.lat && place?.lng && isMapIdle) setMapCenter({
                lat: place.lat,
                lng: place.lng
            })
            if (place?.lat && place?.lng && isMapIdle) setMapZoom(17);
            return
        }

        if (isMapIdle) setMapCenter(mapCenter)


    }, [place, isMapIdle])



    useEffect(() => {

        setAddressWarning(PLACE && PLACE.address_formatted?.toLowerCase().includes(PLACE.name?.toLowerCase()))
        setFormData(PLACE)

    }, [PLACE])

    const handleOnPlaceSelected = (Place) => {
        const decodedPlace = DecodePlaceDetails(Place)

        setPlace(decodedPlace);

        setMapCenter({
            lat: decodedPlace.lat,
            lng: decodedPlace.lng
        });
        setMapZoom(DefaultPlaceViewZoom)
        console.log(decodedPlace)
    }

    const handleMapMarkerDrag = (e, a, b) => {
        const coords = { lat: a.position.lat(), lng: a.position.lng() }
        setMapCenter(coords)
        setPlace({
            ...PLACE,
            lat: coords.lat,
            lng: coords.lng,
            coordinates: `${coords.lat}, ${coords.lng}`
        })
    }

    const handleMapOnClick = (e, a, clickedPlace) => {

        if (clickedPlace && clickedPlace.placeId) placesService?.getDetails({
            placeId: clickedPlace.placeId
        }, (place, status) => {
            const decodedPlace = DecodePlaceDetails(place)
            setPlace(decodedPlace);

            setMapCenter({
                lat: decodedPlace.lat,
                lng: decodedPlace.lng
            });
        })
    }


    useEffect(() => {
        if (isMapIdle && !place) setMapCenter(DefaultMapCenter)
        if (isMapIdle && !place) setMapZoom(DefaultMapZoom)
    }, [isMapIdle])


    useEffect(() => {
        setIsMapIdle(false)
        if (!place && isMapIdle) setMapCenter(mapCenter)
        if (place) setActiveTab('manual')
    }, [show])


    return (<>
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement='end'
            backdrop={true}
            scroll={false}
            {...rest}
        >
            <Offcanvas.Header className="py-0 mb-0 " closeButton>

                <Flex justifyContent="between" className="w-100">
                    <div>
                        <h4 className="mt-3">
                            <FontAwesomeIcon icon={faPlusHexagon} /> Add place
                        </h4>
                    </div>
                </Flex>

            </Offcanvas.Header>
            <Offcanvas.Body className="mt-0 px-0 pt-0" style={{ overflowX: 'hidden' }}>

                {/* Documentation Alert Tip */}
                <MemoryDismissibleAlert
                    UniqueMemoryName="Alert-Places-AddEdit-Documentation"
                    variant="info"
                    className="fs--1 py-1"
                    Icon={<FontAwesomeIcon icon={faSparkles} className="me-2" />}
                >
                    <div className="fs--1">
                        New, quicker way to find places. Read the <Link to="/knwoledgebase/how-to-add-new-place">documentation</Link>
                    </div>
                </MemoryDismissibleAlert>


                <Tabs defaultActiveKey="quick" id="asdads" activeKey={activeTab} onSelect={k => setActiveTab(k)}>
                    <Tab eventKey="quick" title="Quick" >

                        {/* Address / Business Google Autocomplete Search  */}
                        <div className='p-3'>
                            <GoogleAutocomplete
                                placeSelected={handleOnPlaceSelected} />
                        </div>


                        {/* Map Container */}
                        <div className="position-relative">
                            <GoogleMap
                                streetViewControl={false}
                                rotateControl={false}
                                initialCenter={mapCenter}
                                zoom={mapZoom}
                                center={mapCenter}
                                onIdle={() => { setIsMapIdle(true) }}
                                markerDraggable={true}
                                onMarkerDragend={handleMapMarkerDrag}
                                clickableIcons={true}
                                onClick={handleMapOnClick}
                                markerIcon={{
                                    url: MarkerIcon
                                }}
                                mapStyle="Default"
                                darkStyle="Midnight"
                                mapType={isDark ? 'default' : 'hybrid'}
                                className="min-vh-50"
                            />

                            {/* Map Tips */}
                            <div className="px-2 position-absolute w-100 bottom-0">
                                <MemoryDismissibleAlert
                                    UniqueMemoryName="Alert-Places-AddEdit-MapTips"
                                    IconName={faLightbulb}
                                    variant="info"
                                    className="fs--2 mb-2"
                                >
                                    <div className="fs--1">
                                        <b>Map Tips:</b> <br />
                                        Drag the red marker to adjust location precisely
                                        <br />
                                        Click on a business to select it
                                    </div>
                                </MemoryDismissibleAlert>
                            </div>
                        </div>
                        {/* Place Details Breakdown */}
                        {formData && <DisplayPlaceDetails place={formData} />}
                    </Tab>


                    <Tab eventKey="manual" title="Manual" className='p-3'>
                        {/* Manual Form */}
                        <AddEditPlaceFormNoSubmit
                            place={PLACE}
                            onChange={newPlaceData => { setFormData(newPlaceData) }}
                        />
                    </Tab>


                </Tabs>

                {addressWarning &&
                    <IconAlert variant="warning">
                        Seems like the <b>name</b> of this place is the same as the <b>address</b>. Please check that you're using the <b>correct business name</b>. If not, click on the button to make changes before saving
                    </IconAlert>
                }

                <Flex className="mt-2 p-2 " justifyContent="end">

                    {activeTab === 'quick' &&
                        <Button variant="warning" className="" onClick={() => { setActiveTab('manual') }} size="sm"><FontAwesomeIcon icon={faPenToSquare} /> Make changes then save</Button>
                    }

                    <Button variant="primary" className="ms-auto" size="sm"
                        onClick={handleSubmit}
                        disabled={!PLACE ||
                            !PLACE.name ||
                            !PLACE.address ||
                            !PLACE.city ||
                            !PLACE.state
                        }
                    >
                        <FontAwesomeIcon icon={faSave} /> Save Place
                    </Button>
                </Flex>


            </Offcanvas.Body>
        </Offcanvas>
    </>)
}

export default AddEdit