import { useQuery } from "@apollo/client";
import { faCarGarage, faGarageCar, faCarWrench, faScrewdriverWrench, faEmptySet, faPenToSquare, faSearch, faTire, faTrashAlt, faTruckTow } from "@fortawesome/pro-duotone-svg-icons";
import { faGarageCar as faGarageCarLight, faLocationDot, faTruckTow as faTruckTowLight } from '@fortawesome/pro-light-svg-icons'
import { faTruckTow as faTruckTowSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetPlaces } from "api/GraphQL/Queries";
import IconButton from "components/common/IconButton";
import SoftBadge from "components/common/SoftBadge";
import { VehicleShortTag, VehicleTextAndIconOnly } from "components/pages/Directory/Vehicles/VehicleShortTag";
import { TRC } from "helpers/models/Repairs";
import { getRandomNumber } from "helpers/utils";
import { LoremIpsum } from "lorem-ipsum";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBarReact from 'simplebar-react';
import { v4 as uuid } from 'uuid';
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";

const lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4
    },
    wordsPerSentence: {
        max: 16,
        min: 4
    }
})
const ri = arr => arr[getRandomNumber(0, arr.length - 1)];//random item
const mri = (arr, limit = false, min = false) => {
    const newArr = []
    for (let i = 0; i < getRandomNumber(min || 0, limit || arr.length - 1); i++) {
        newArr.push(ri(arr))
    }
    return newArr
}
const tagsData = [
    'Tire Change',
    '123154123',
    'PM & AI',
    '645390349432',
    'Major Breakdown',
    '125 4563623',
    'Fuel Pump',
    '7345234',
    'Radiator',
    '69293298',
    'Brakes',
    'FAILED',
    'EGR Cooler',
    'Air Filter'
]
const serviceVisitTypes = [{
    name: 'SHOP',
    icon: <FontAwesomeIcon icon={faCarGarage} />
}, {
    name: 'OWN SHOP',
    icon: <FontAwesomeIcon icon={faGarageCar} />
}, {
    name: 'ROAD-SERVICE',
    icon: <FontAwesomeIcon icon={faCarWrench} />
}, {
    name: 'OWN ROAD-SERVICE',
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />
}, {
    name: 'TOW',
    icon: <FontAwesomeIcon icon={faTruckTow} />
}, {
    name: 'OWN TOW',
    icon: <FontAwesomeIcon icon={faTruckTow} />
}]
const serviceVisitStatuses = ['UPCOMING', 'ONGOING', 'WAITING_FOR_PARTS', 'COMPLETED', 'UNKNOWN']
const maintenanceTypes = [{
    name: 'FRHT PM Service',
    description: 'Oil change for Freightliner Cascadia with a DD13 Engine. Use 15W-30 oil. Preferred brands: Delvac, Rotella'
}, {
    name: 'Volvo PM Service',
    description: 'Oil change for Volvo trucks. Use 15W-40 oil. Preferred brands: Delvac, Rotella'
}, {
    name: 'DOT Inspection',
    description: 'Oil change for Freightliner Cascadia with a DD13 Engine. Use 15W-30 oil. Preferred brands: Delvac, Rotella'
}, {
    name: 'External Wash',
    description: 'Vehicle external wash to prevent corrosion and dirt-wear'
}, {
    name: 'Washout',
    description: 'Trailer box washout to remove dirt and lingering odors'
}, {
    name: 'Reefer PM',
    description: 'Trailer box washout to remove dirt and lingering odors'
}]
const vehiclesData = [
    {
        uuid: uuid(),
        type: 'SEMI_TRUCK',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'PICKUP_TRUCK',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'TOW_TRUCK',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'STEP_VAN',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'SPECIALTY',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'SEMI_TRAILER',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'BOX_TRUCK',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'CARGO_VAN',
        vehicle_id: getRandomNumber(1, 9999)
    },
    {
        uuid: uuid(),
        type: 'PASSENGER_VAN',
        vehicle_id: getRandomNumber(1, 9999)
    }
]
const maintenanceData = [{
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}, {
    type: ri(maintenanceTypes),
    description: lorem.generateSentences(getRandomNumber(1, 3)),
    laborCost: getRandomNumber(0, 5000),
    partsCost: getRandomNumber(0, 5000),
}]
const repairComponentsData = [
    'ENGINE',
    'TRANSMISSION_AND_DRIVETRAIN',
    'SUSPENSION_AND_BRAKES',
    'ELECTRICAL',
    'COOLANT_SYSTEM',
    'FUEL_SYSTEM',
    'AIR_SYSTEM',
    'BODY_AND_CHASIS',
    'AFTERTREATMENT_AND_EXHAUST',
    'OTHER'
]
const repairRecordsData = [{
    component: TRC(ri(repairComponentsData)),
    description: lorem.generateSentences(2)
}, {
    component: TRC(ri(repairComponentsData)),
    description: lorem.generateSentences(2)
}, {
    component: TRC(ri(repairComponentsData)),
    description: lorem.generateSentences(2)
}, {
    component: TRC(ri(repairComponentsData)),
    description: lorem.generateSentences(2)
}, {
    component: TRC(ri(repairComponentsData)),
    description: lorem.generateSentences(2)
}]
export const sampleData = [
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "Dresick COOLING-Huron, CA",
            "address": {
                "administritive_area": "CA",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 36.1377385,
                "lng": -120.1545818,
                "locality": "Huron",
                "postal_code": "93234",
                "thoroughfare": "19935 W Jayne Ave"
            },
            "uuid": "000dd0df-0087-4311-8b8e-8f6d20ef7a38"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    },
    {
        uuid: uuid(),
        type: ri(serviceVisitTypes),
        status: ri(serviceVisitStatuses),
        tags: mri(tagsData, 3),
        conducted: '2022-12-16T06:01:38.589Z',
        description: lorem.generateSentences(2),
        maintenance: mri(maintenanceData, 3),
        repairs: mri(repairRecordsData, 5, 1),
        tires: [],
        vehicles: mri(vehiclesData, 5, 2),
        equipment: [],
        place: {
            "name": "LEGENDARY BAKING - DENVER,CO",
            "address": {
                "administritive_area": "CO",
                "country": {
                    "ISO3": "USA"
                },
                "lat": 39.7828569,
                "lng": -104.9920871,
                "locality": "Denver",
                "postal_code": "80216",
                "thoroughfare": "400 W. 48th Ave"
            },
            "uuid": "003db50b-310c-423a-a299-d3190f8ff49d"
        },
        created: '',
        updated: ''
    }
]

export const SERVICE_RECORD_STATUS_PROPERTIES = {
    UPCOMING: {
        bg: 'dark',
        text: 'UPCOMING',
        tooltip: 'UPCOMING'
    },
    ONGOING: {
        bg: 'primary',
        text: 'ONGOING',
        tooltip: 'ONGOING'
    },
    WAITING_FOR_PARTS: {
        bg: 'warning',
        text: 'PARTS',
        tooltip: 'WAITING FOR PARTS'
    },
    COMPLETED: {
        bg: 'success',
        text: 'COMPLETED',
        tooltip: 'COMPLETED'
    },
    UNKNOWN: {
        bg: 'secondary',
        text: 'UNKNOWN',
        tooltip: 'UNKNOWN'
    }
}
export const ServiceRecordType = ({ ServiceRecord, ...rest }) => {
    const TYPE = ServiceRecord.type
    return (<>
        <h6 className="align-center rounded-1 bg-light p-1 mb-1">
            {/* <Badge className="d-block" bg="light"> */}
            <Link to={ServiceRecord.uuid} className="d-flex justify-content-start fw-semi-bold gap-2" {...rest}>
                {TYPE.icon}
                <span className="fw-semi-bold">{TYPE.name}</span>
            </Link>
            {/* </Badge> */}
        </h6>
    </>)
}
export const ServiceRecordStatusTag = ({ ServiceRecord }) => {
    const STATUS = SERVICE_RECORD_STATUS_PROPERTIES[ServiceRecord.status]
    return (<>
        <SoftBadge bg={STATUS.bg} className="me-1">{STATUS.text}</SoftBadge>
    </>)
}
export const RepairRecordTag = ({ Record }) => <SoftBadge pill bg='danger' className="d-flex justify-content-between text-truncate align-center">
    <FontAwesomeIcon icon={Record.component.iconNameOnly} size="1x" className="me-4" /> <span className="fs--2 text-truncate  fw-bold">{Record.component.tag_name}</span>
</SoftBadge>
export const MaintenaceRecordTag = ({ Record }) => <SoftBadge pill bg='primary' className="text-center text-truncate" >
    <span className="fs--2 fw-bold">{Record.type.name}</span>
</SoftBadge>


const SegmentTitle = ({ Title }) => <h6 className="text-400 fs--2">{Title}</h6>

const Segment = ({ Title, children, ...rest }) => <Col className="text-center" {...rest}>
    <div className="px-2">
        <SegmentTitle Title={Title} />
        {children}
    </div>
</Col>

export const ServiceRecordCard = ({ ServiceRecord }) => {
    const history = useHistory()
    return (<>
        <Card className="p-3 bg-whiterounded-3 shadow-sm card-view-height">
            <SimpleBarReact>
                <div className="d-flex">
                    <Segment Title="" className="text-start" xs={12} md={3} >
                        <div>
                            <ServiceRecordType ServiceRecord={ServiceRecord} />
                        </div>
                        <div>
                            <div>
                                {
                                    ServiceRecord.vehicles.length >= 1 ?
                                        ServiceRecord.vehicles.map(Vehicle => <VehicleShortTag key={Vehicle.uuid} Vehicle={Vehicle} />)
                                        :
                                        <SoftBadge bg='light'><i>No vehicles selected</i></SoftBadge>
                                }
                            </div>
                            <ServiceRecordStatusTag ServiceRecord={ServiceRecord} />
                            {
                                ServiceRecord.tags.length >= 1 ?
                                    ServiceRecord.tags.map((Tag, i) => <SoftBadge bg='info' className="me-1" key={i}>{Tag}</SoftBadge>)
                                    :
                                    <SoftBadge bg='light'><i>No tags</i></SoftBadge>
                            }
                        </div>
                        <div>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{moment(ServiceRecord.conducted).format('MM/DD/YYYY')}</Tooltip>}>
                                <SoftBadge bg="light">Conducted
                                    <span className="fw-bold ms-1">
                                        {moment(ServiceRecord.conducted).format('MMM DD')}
                                    </span>
                                </SoftBadge>
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{moment(ServiceRecord.conducted).format('MM/DD/YYYY')}</Tooltip>}>
                                <SoftBadge bg="light"><FontAwesomeIcon icon={faLocationDot} />
                                    <Link to="#" className="fw-bold ms-1 text-body">
                                        {ServiceRecord.place.name}
                                    </Link>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    </Segment>
                    <Segment Title="" xs="auto" md={3}> {/* Repairs */}
                        <Row className="g-1">
                            {
                                ServiceRecord.repairs.length >= 1 ?
                                    ServiceRecord.repairs.map((R, i) => <RepairRecordTag key={i} Record={R} />)
                                    :
                                    <SoftBadge bg='light'><i>No repair records</i></SoftBadge>
                            }
                        </Row>
                    </Segment>
                    <Segment Title="" xs="auto" md={3}> {/* Maintenance */}
                        <Row className="g-1">
                            {
                                ServiceRecord.maintenance.length >= 1 ?
                                    ServiceRecord.maintenance.map((M, i) => <MaintenaceRecordTag key={i} Record={M} />)
                                    :
                                    <SoftBadge bg='light'><i>No maintenance records</i></SoftBadge>
                            }
                        </Row>
                    </Segment>
                    <Segment Title="" xs="auto" md={2}> {/* Tires */}
                        {ServiceRecord.vehicles.map((Vehicle, i) => (i % 2 == 0) ? <div key={i}>
                            <SoftBadge pill bg="warning" className="nowrap text-nowrap fs--2 d-block mb-1 d-flex justify-content-between align-items-center">
                                <span className="me-2"><b className="fs--1">{getRandomNumber(1, 4)}</b> <FontAwesomeIcon icon={faTire} /></span>
                                <VehicleTextAndIconOnly key={Vehicle.uuid} Vehicle={Vehicle} />
                            </SoftBadge>
                        </div> : <></>)}
                    </Segment>
                    <Segment Title="" className="text-end" xs={1}>
                        <ButtonGroup vertical>
                            <Button size="sm" variant="outline-primary" onClick={() => { history.push(`Edit/${ServiceRecord.uuid}`) }}>
                                <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </OverlayTrigger>
                            </Button>
                            <Button size="sm" variant="outline-danger">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </ButtonGroup>
                    </Segment>
                </div>
            </SimpleBarReact>
        </Card>
    </>)
}

const ServiceRecordsList = () => {
    const [searchField, setSearchField] = useState('');
    const [selectField, setSelectField] = useState('');

    // const {
    //     error,
    //     loading,
    //     data
    // } = useQuery(GetPlaces)

    // useEffect(() => {
    //     console.log(data)
    // }, [data])
    // console.log(sampleData)
    return (<>
        {(sampleData.length >= 1) ? sampleData.map((ServiceRecord, index) => {
            return <ServiceRecordCard key={ServiceRecord.uuid} ServiceRecord={ServiceRecord} />
        }) :
            <div className="text-400 my-4">
                <FontAwesomeIcon icon={(searchField == '' && selectField == '') ? faEmptySet : faSearch} className="mb-4" size="6x" />
                {(searchField == '' && selectField == '') ? <h5>No records</h5> : <h5>No results</h5>}
                <p>You can add a new record by clicking the button below</p>
                <IconButton variant="primary" icon="plus" size="md" transform="shrink-3">
                    <span className="d-none d-sm-inline-block ms-1" /*onClick={() => setModalShow(true)}*/>Add new record</span>
                </IconButton>
            </div>}
    </>)
}

export default ServiceRecordsList