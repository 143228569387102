import React, { useState, useEffect } from 'react'
import { Button, FloatingLabel, Form, FormCheck, ListGroup, Modal } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import Permissions from 'data/important/permissions'
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { getUserData } from 'context/AuthContext';

const API = new APICommunicator('UserRoles');

const AddEditRoleModal = ({ show, handleClose, successCallback, ROLE }) => {

    const [roleName, setRoleName] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState([])

    const handleSubmit = e => {
        e.preventDefault()
        API.PUT(`/${ROLE.uuid}`, {
            permissions: selectedPermissions
        }).then(Response => {
            if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
            if (typeof successCallback === 'function') successCallback(Response.content)
            handleClose()

        }).catch(handleErrorWithToast)

    }

    const handlePermissionChange = e => {
        // console.log(e.target.checked)
        // console.log(e.target.id)
        // console.log(selectedPermissions.indexOf(e.target.id) >= 0)

        if (e.target.checked && selectedPermissions.indexOf(e.target.id) <= -1) setSelectedPermissions([...selectedPermissions, e.target.id]);
        if (!e.target.checked && selectedPermissions.indexOf(e.target.id) >= 0) {
            let newArray = [...selectedPermissions]
            let i = newArray.indexOf(e.target.id)
            newArray.splice(i, 1)
            // console.log(newArray)
            setSelectedPermissions(newArray)
        }


    }
    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })
    useEffect(() => {
        // console.table(selectedPermissions)
    }, [selectedPermissions])

    useEffect(() => {
        setRoleName(ROLE.name)
        setSelectedPermissions(ROLE.permissions)
    }, [ROLE, show])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {ROLE ? `Edit role` : `Add new role`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Group>
                            <FloatingLabel label="Name" className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    onChange={e => {
                                        setRoleName(e.target.value)
                                    }}
                                    defaultValue={ROLE.name}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <ListGroup variant="flush">
                            {Permissions.length >= 1 && Permissions.map(PERMISSION =>
                                <ListGroup.Item key={PERMISSION.code_name} className="fs--2 pb-0">
                                    <FormCheck
                                        type="switch"
                                        id={PERMISSION.code_name}
                                        style={{ minHeight: '0' }}

                                    >
                                        <FormCheck.Input
                                            onChange={handlePermissionChange}
                                            className="mt-2"
                                            style={{ minHeight: '0' }}
                                            defaultChecked={ROLE?.permissions ? ROLE.permissions.indexOf(PERMISSION.code_name) >= 1 : false}
                                        />
                                        <FormCheck.Label as='div' className='d-block'>
                                            <div className="ms-3">
                                                <h6 className="mb-0">{PERMISSION.name}</h6>
                                                <span className="text-muted">{PERMISSION.description}</span>
                                            </div>
                                        </FormCheck.Label>
                                    </FormCheck>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={!roleName}>
                            Submit</Button>
                    </Form.Group>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddEditRoleModal;