import React, { useEffect, useState } from 'react'
import { Card, Col, Placeholder, Row, Table, Alert, Tooltip, Button, OverlayTrigger, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import IconButton from 'components/common/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock, faTrashXmark, faPenToSquare } from '@fortawesome/pro-duotone-svg-icons'
import SimpleBarReact from 'simplebar-react';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { hasPermission, hasPermissions } from 'context/AuthContext'
import APICommunicator from 'api/APICommunicator'
import AddEditRoleModal from './modals/AddEditRoleModal'
import UserListModal from './modals/UserListModal'
import PermissionListModal from './modals/PermissionListModal'
import { Link } from 'react-router-dom'
import RoleEdit from './offcanvas/RoleEdit'
import RoleInfo from './offcanvas/RoleInfo'

const API = new APICommunicator('UserRoles')

const UserRoles = () => {
    document.title = `User Roles`
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [formattedRoleList, setFormattedRoleList] = useState(false)
    const [roleList, setRoleList] = useState(false)
    const [EDITTING_ROLE, setEDITTING_ROLE] = useState(false)
    const [infoModalRole, setInfoModalRole] = useState(false)
    const [offCanvasEditShow, setOffCanvasEditShow] = useState(false)
    const [offCanvasInfoShow, setOffCanvasInfoShow] = useState(false)
    const loadData = () => {
        setError(false)
        setLoading(true)
        API.GET(`/`)
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleError(Response)
                setRoleList(Response.content)
            })
            .catch(e => {
                setLoading(false)
                handleError(e)
            })
    }

    const handleError = Error => setError(API.handleResponseErrors(Error))

    const deleteHandle = () => { }
    const ACTIONS = [
        {
            name: 'Edit',
            hasPermission: hasPermissions(['MANAGE_ROLES', 'VIEW_ROLES']),
            button: (ROLE, i) => <Button variant="falcon-primary" size="sm" key={i} onClick={() => { setEDITTING_ROLE(ROLE); setOffCanvasEditShow(true) }}>
                <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
            // button: (ROLE, i) => <Link to={`/UserRoles/${ROLE.uuid}`} key={i} ><Button variant="falcon-primary" size="sm" >
            //     <FontAwesomeIcon icon={faPenToSquare} />
            // </Button></Link>
        },
        {
            name: 'Delete',
            hasPermission: hasPermissions(['MANAGE_ROLES', 'VIEW_ROLES']),
            button: i => <Button key={i} variant="falcon-danger" size="sm" onClick={deleteHandle}>
                <FontAwesomeIcon icon={faTrashXmark} />
            </Button>
        }
    ]

    const handleSuccessCallback = (ROLE) => {
        if (ROLE) {
            const i = roleList.findIndex(role => role.uuid === ROLE.uuid);
            if (i >= 0) return roleList[i] = ROLE;
            return roleList.push(ROLE);
        }
        loadData()
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        (roleList) && setFormattedRoleList(roleList.map(role => ({
            ...role,
            users: role.users.filter(user => user.deleted !== '1')
        })))
    }, [roleList])

    return (
        <>
            <Row className="d-flex mb-3 mt-4">
                <Col xs="auto" className="pt-2 ps-4">
                    <FontAwesomeIcon icon={faUserLock} className="me-0 ms-2" size="2x" />
                </Col>
                <Col className="ps-0">
                    <h5 className="mb-0 mt-1 text-primary position-relative">
                        <span className="bg-200 dark__bg-1100 px-2 rounded">User Roles</span>
                        <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                    </h5>
                    <p className="mb-0 px-2 d-none d-sm-inline-block">List of roles to manage user access throughout the system</p>
                </Col>

                {hasPermissions(['MANAGE_ROLES', 'VIEW_ROLES']) &&
                    <Col xs='auto'>
                        {/* <Link to={'/UserRoles/New'}> */}
                        <IconButton variant="primary" icon="plus" size="sm" transform="shrink-3" onClick={() => { setEDITTING_ROLE('NEW'); setOffCanvasEditShow(true) }}>
                            <span className="d-none d-sm-inline-block ms-1">Add a new role</span>
                        </IconButton>
                        {/* </Link> */}
                    </Col>
                }
            </Row>
            <Card className="h-100 mt-0">
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        {loading ?
                            <Table borderless className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info" >
                                <tbody>
                                    {[1, 2, 3, 4].map(i =>
                                        <tr key={i}>
                                            <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                            <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                            <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            :
                            error ?
                                <div className="px-4 pt-3">
                                    <Alert variant="danger">
                                        <Alert.Heading>Error loading component!</Alert.Heading>
                                        {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                                            (typeof error === 'object') ? error.map(e => <div key={e} style={{ 'whiteSpace': 'pre-line' }}>{e}</div>) : <div style={{ 'whiteSpace': 'pre-line' }}>{error}</div>
                                        }
                                        <div className="text-center">
                                            <IconButton variant="danger" icon="sync-alt" size="md" transform="shrink-3">
                                                <span className="d-none d-sm-inline-block ms-0" onClick={() => loadData()}>Reload</span>
                                            </IconButton>
                                        </div>
                                    </Alert>
                                </div>
                                :
                                <Table borderless className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info" >
                                    <thead className="bg-light">
                                        <tr className="text-900">
                                            <th>Role</th>
                                            <th>Users</th>
                                            <th className="text-center">Permissions</th>
                                            <th className="text-center">Description</th>
                                            <th className="text-end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formattedRoleList.length >= 1 && formattedRoleList.map((ROLE, rowIndex) =>
                                            <tr className={formattedRoleList.length - 1 == rowIndex ? `border-200` : `border-bottom border-200`} key={ROLE.id}>
                                                <td>
                                                    <h6 className="mb-0">{ROLE.name}</h6>
                                                    {ROLE.system_role == '1' && <span className="text-muted">System Role</span>}
                                                </td>
                                                <td>
                                                    <div className='cursor-pointer' onClick={() => { setInfoModalRole(ROLE); setOffCanvasInfoShow(true) }}>
                                                        <AvatarGroup dense>
                                                            {ROLE?.users.length >= 1 && ROLE.users.map((USER, i) => {
                                                                if (i <= 3) return <Avatar
                                                                    key={USER.uuid}
                                                                    src={USER.profile_picture && USER.profile_picture}
                                                                    name={(USER.firstname && USER.lastname) && `${USER.firstname} ${USER.lastname}`}
                                                                    className="border border-1 rounded-circle border-light"
                                                                />
                                                            })}
                                                            {(ROLE?.users.length > 4) && <Avatar
                                                                name={`+${ROLE?.users.length - 3}`}
                                                                isExact
                                                                className="border border-1 rounded-circle border-light"
                                                            />}
                                                        </AvatarGroup>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className='cursor-pointer' onClick={() => { setInfoModalRole(ROLE); setOffCanvasInfoShow(true) }}>
                                                        <Avatar
                                                            name={`${ROLE?.permissions.length}`}
                                                            isExact
                                                            className="border border-3 rounded-circle border-light"
                                                        />
                                                    </div>
                                                </td>
                                                <td>{ROLE.description}</td>
                                                <td>
                                                    <ButtonToolbar>
                                                        <ButtonGroup className="me-2 mb-2">
                                                            {ACTIONS.map((ACTION, i) => {
                                                                if (ACTION.hasPermission && ROLE.system_role !== '1') return ACTION.button(ROLE, i)
                                                            })}
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                        }
                    </SimpleBarReact>
                </Card.Body>
            </Card>
            <RoleInfo
                show={offCanvasInfoShow}
                handleClose={() => { setOffCanvasInfoShow(false) }}
                ROLE={infoModalRole}
            />
            <RoleEdit
                show={offCanvasEditShow}
                handleClose={() => { setOffCanvasEditShow(false) }}
                successCallback={handleSuccessCallback}
                ROLE={EDITTING_ROLE}
            />
        </>

    )
}
export default UserRoles