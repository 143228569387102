import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import SoftBadge from 'components/common/SoftBadge';
import { useSearch } from 'context/SearchContext'
import { faServer } from '@fortawesome/pro-duotone-svg-icons';

const MediaSearchContent = ({ item }) => {
  return (
    <Dropdown.Item className="px-card py-2" as={Link} to={item.url}>
      <Flex alignItems="center">
        {item.file && (
          <div className="file-thumbnail">
            <img src={item.img} alt="" className={item.imgAttrs.class} />
          </div>
        )}
        {item.icon && (
          <Avatar src={item.icon.img} size="l" className={item.icon.status} />
        )}

        <div className="ms-2">
          <h6 className="mb-0">{item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};



const UserItem = ({ ...props }) => {


  const { firstname, lastname, title, username, profile_picture, url, recentItem } = props;

  return <Dropdown.Item as={Link} to={url || '/'} className="fs--1 px-card py-1 hover-primary">
    <Flex alignItems="center">
      <Avatar name={`${firstname} ${lastname}`} src={profile_picture} alt="" />
      <div className="ms-2">
        {`${firstname} ${lastname}`}
        <small className="d-block">{title}</small>
      </div>
      {recentItem &&
        <div className="ms-auto text-400">
          User
        </div>
      }
    </Flex>
  </Dropdown.Item>
}

// const CustomerItem = ({ ...props }) => {

//   const { name, url, logo, email, recentItem } = props;

//   return <Dropdown.Item as={Link} to={url || '/'} className="fs--1 px-card py-1 hover-primary">
//     <Flex alignItems="center">
//       <Avatar name={name} src={logo} alt="" />
//       <div className="ms-2">
//         {name}
//         <small className="d-block">{email}</small>
//       </div>
//       {recentItem &&
//         <div className="ms-auto text-400">
//           Customer
//         </div>
//       }
//     </Flex>
//   </Dropdown.Item>
// }

// const ServiceItem = ({ ...props }) => {

//   const { label, status, url, unique_id, recentItem } = props;
//   const STATUS = ServiceStatusRenderer({ status: status })

//   return <Dropdown.Item as={Link} to={url || '/'} className="fs--1 px-card py-1 hover-primary">
//     <Flex alignItems="center">
//       <Avatar icon={faServer} />
//       <div className="ms-2">
//         {label}
//         <small className="d-block">{unique_id}</small>
//       </div>
//       <div className="mx-3">
//         {STATUS.Component}
//       </div>
//       {recentItem &&
//         <div className="ms-auto text-400">
//           Service
//         </div>
//       }
//     </Flex>
//   </Dropdown.Item>

// }

const SuggestionItem = ({ ...props }) => {
  const { label, description, url } = props
  return <Dropdown.Item as={Link} to={url} className="fs--1 px-card py-1 hover-primary">
    <Flex alignItems="center">
      <SoftBadge>{label}</SoftBadge>
      <div className="ps-2">
        {description}
      </div>
    </Flex>
  </Dropdown.Item>
}


const SearchItem = ({ ...props }) => {

  const { item, recentItem } = props;
  if (!item) return <></>
  const { category_type, url } = item;
  const KnownSerachItemTypes = ['service', 'service_user', 'user', 'customer', 'suggestion'];

  if (!category_type || !KnownSerachItemTypes.includes(category_type)) return <Dropdown.Item className="fs--1 px-card py-1 hover-danger ">
    <SoftBadge bg="danger">Can't render this item</SoftBadge>
  </Dropdown.Item>

  // if (category_type === 'service') return <ServiceItem {...item} recentItem={recentItem} />
  // if (category_type === 'customer') return <CustomerItem {...item} recentItem={recentItem} />
  if (category_type === 'user') return <UserItem {...item} recentItem={recentItem} />
  if (category_type === 'suggestion') return <SuggestionItem {...item} />

  return <Dropdown.Item as="div" className="fs--1 px-card py-1 ">
    <span className="text-400">Can't display this item</span>
  </Dropdown.Item>

}



const SearchBox = ({ recentItems, searchResults, searchLoading }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState({});
  const [resultCategories, setResultCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const { searchForItems } = useSearch();

  const fuseJsOptions = {
    includeScore: true,
    keys: ['firstname', 'lastname', 'title', 'name', 'label', 'username']
  };



  const SuggestionItems = [
    // {
    //   category_type: 'suggestion',
    //   label: 'services:',
    //   description: 'Go to services list',
    //   url: '/Services'
    // },
    {
      category_type: 'suggestion',
      label: 'users:',
      description: 'Go to users list',
      url: '/Users'
    },
    // {
    //   category_type: 'suggestion',
    //   label: 'customers:',
    //   description: 'Go to customers list',
    //   url: '/Customers'
    // }
  ]

  // const recentlyBrowsedItems = isIterableArray(recentItems) ? recentItems.filter(
  //   item => item.catagories === 'recentlyBrowsedItems'
  // ) : {};

  // const suggestedFilters = isIterableArray(resultItem) ? resultItem.filter(
  //   item => item.catagories === 'suggestedFilters'
  // ) : {};

  // const suggestionFiles = isIterableArray(resultItem) ? resultItem.filter(
  //   item => item.catagories === 'suggestionFiles'
  // ) : {};

  // const suggestionMembers = isIterableArray(resultItem) ? resultItem.filter(
  //   item => item.catagories === 'suggestionMembers'
  // ) : {};

  const toggle = () => {
    // console.log(recentItems)
    // asd_function()
    setDropdownOpen(!dropdownOpen);
  }

  useEffect(() => {
    if (searchInputValue) {
      setLoading(true)
      searchForItems(searchInputValue).then(() => {
        setLoading(false)
        // setResultCategories
      })

      let searchResult = new Fuse(searchResults, fuseJsOptions)
        .search(searchInputValue)
        .map(item => item.item);
      setResultItem(searchResult);
      // isIterableArray(searchResult)
      //   ? setDropdownOpen(true)
      //   : setDropdownOpen(false);
    } else {
      setResultItem({});
    }

    // eslint-disable-next-line
  }, [searchInputValue]);


  useEffect(() => {
    const Categories = isIterableArray(resultItem) ? [...new Set(resultItem.map(item => item.category_type))] : [];
    setResultCategories(Categories)
  }, [resultItem])

  return (

    // SEARCH BOX

    <Dropdown onToggle={toggle} className="search-box">
      <Dropdown.Toggle
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div
              className="search-box-close-btn-container"
              // style={{ right: '10px', top: '8px' }}
            >
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => setSearchInputValue('')}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>


      {/* SEARCH RESULTS */}


      <Dropdown.Menu>
        <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
          {!searchInputValue ?
            <>{isIterableArray(recentItems) &&
              <>
              <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">Recent</Dropdown.Header>
              {recentItems.map((item, index) => item && <SearchItem key={index} item={item} recentItem={true} />)}
              <hr className="bg-200 dark__bg-900" />
            </>
            }
              {<>
                <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">Suggestions</Dropdown.Header>
                {SuggestionItems.map((item, index) => item && <SearchItem key={index} item={item} />)}
              </>}
            </>
            : <>
              {searchLoading ?
                <>Searching</>
                :
                <>
                  {resultItem.length === 0 ? <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>
                    :
                    <>
                      {isIterableArray(resultCategories) && resultCategories.map((Category, index) => <div key={Category}>
                        <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">{Category}</Dropdown.Header>
                        {isIterableArray(resultItem) && resultItem.map((item, index) => (item && item.category_type === Category) && <SearchItem key={index} item={item} recentItem={false} />)}
                        {(resultCategories.length - 1 !== index) && <hr className="bg-200 dark__bg-900" />}
                      </div>
                      )}
                    </>
                  }
                </>
              }
            </>
          }
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

MediaSearchContent.propTypes = {
  item: PropTypes.shape({
    catagories: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      img: PropTypes.string.isRequired,
      size: PropTypes.string,
      status: PropTypes.string
    }),
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    time: PropTypes.string,
    file: PropTypes.bool,
    imgAttrs: PropTypes.shape({
      class: PropTypes.string
    })
  }).isRequired
};

SearchItem.propTypes = {
  item: PropTypes.shape({
    // type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}

SearchBox.propTypes = {
  recentItems: PropTypes.arrayOf(
    PropTypes.shape(MediaSearchContent.propTypes.item)
  )
};

export default SearchBox;
