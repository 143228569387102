export const OrderAlerts = [{
    value: 'truck',
    label: 'Display alerts related to the truck assigned to the order'
}, {
    value: 'trailer',
    label: 'Display alerts related to the trailers assigned to the order'
}, {
    value: 'driver',
    label: 'Display alerts related to the drivers assigned to the order'
}, {
    value: 'dispatcher',
    label: 'Display alerts related to the dispatcher assigned to the order'
}, {
    value: 'customer',
    label: 'Display alerts related to the customer assigned to the order'
}, {
    value: 'customer_rep',
    label: 'Display alerts related to the customer representatives/contacts assigned to the order'
}, {
    value: 'carrier',
    label: 'Display alerts related to the carrier assigned to the order'
}, {
    value: 'carrier_rep',
    label: 'Display alerts related to the carrier representatives/contacts carrier assigned to the order'
}]