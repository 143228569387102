
import React, { useContext, useEffect, useState } from "react";
import AppContext from 'context/Context';
import { replaceAll, toTitleCase } from "helpers/utils";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { Dropdown, Form, Image } from "react-bootstrap";
import PoweredByGoogleOnLight from 'assets/img/logos/desktop/powered_by_google_on_white_hdpi.png'
import PoweredByGoogleOnDark from 'assets/img/logos/desktop/powered_by_google_on_non_white_hdpi.png'
import { faLightbulb, faPenToSquare, faPlusHexagon, faSave, faSearch, faSearchLocation, faSparkles, faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCloseButton from "components/common/FalconCloseButton";

export const getAddressObject = (address_components) => {
    var ShouldBeComponent = {
        number: ["street_number"],
        street: ["street_address",
            "route"],
        address_line_2: ["subpremise"],
        city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
        ],
        region: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5"
        ],
        postal_code: ["postal_code"],
        country: ["country"]
    };

    var address = {
        number: "",
        postal_code: "",
        street: "",
        region: "",
        city: "",
        country: ""
    };

    address_components.forEach(component => {
        for (var shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === "country" || shouldBe === "region") {
                    address[shouldBe] = component.short_name;
                } else {
                    address[shouldBe] = component.long_name;
                }
            }
        }
    });
    return address;
}

export const RawDetailsDecode = [
    {
        Display: 'Name',
        DataName: 'name'
    },
    {
        Display: 'Address',
        DataName: 'address_formatted'
    },
    {
        Display: 'Phone',
        DataName: 'phone'
    }

]
export const DecodePlaceDetails = Place => {

    const addressObject = getAddressObject(Place.address_components)
    // console.log(Place)
    return {
        name: Place?.name,
        address_formatted: Place?.formatted_address,
        icon: Place?.icon,
        status: Place?.business_status,
        type: Place ? toTitleCase(replaceAll(Place?.types?.join(', '), '_', ' ')) : '',
        address: `${addressObject?.number} ${addressObject?.street}`,
        address2: addressObject.address_line_2,
        city: addressObject?.city,
        zip: addressObject?.postal_code,
        state: addressObject?.region,
        country: addressObject?.country,
        lat: Place?.geometry?.location?.lat(),
        lng: Place?.geometry?.location?.lng(),
        coordinates: `${Place?.geometry?.location?.lat()}, ${Place?.geometry?.location?.lng()}`,
        timezone: '',
        contact: '',
        website: Place?.website,
        phone: Place?.formatted_phone_number,
        google_utc_offset_minutes: Place?.utc_offset_minutes,
        // google_rating: Place?.rating,
        // google_user_ratings_total: Place?.user_ratings_total,
        google_place_id: Place?.place_id,
        // google_url: Place?.url,
    }
}

export const GoogleAutocomplete = ({ placeSelected }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [suggestedBusinesses, setSuggestedBusinesses] = useState(false);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 750,
        language: 'en',
        options: {
            type: 'establishment',//https://developers.google.com/maps/documentation/javascript/supported_types  TABLE 3
            componentRestrictions: { country: ['us', 'ca', 'mx'] } //https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes Alpha-2 code
        }
    });

    const {
        config: { isDark }
    } = useContext(AppContext);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const handlePlaceSelect = place => {
        placesService?.getDetails({
            placeId: place.place_id,
        },
            (placeDetails) => {
                if (typeof placeSelected === 'function') placeSelected(placeDetails, suggestedBusinesses);
            }
        );

    }

    return <Dropdown className="search-box w-100" show={dropdownOpen}>
        <Form className="position-relative">
            <Form.Control
                type="search"
                placeholder="Search business name first, then address"
                aria-label="Search"
                size="sm"
                className="rounded-pill search-input"
                value={searchInputValue}
                onChange={({ target }) => {
                    if (target.value) setDropdownOpen(true);
                    if (!target.value) setDropdownOpen(false);
                    setSearchInputValue(target.value)
                    getPlacePredictions({ input: target.value })
                }}
                onKeyPress={() => { setDropdownOpen(true) }}
                onClick={() => { setDropdownOpen(true) }}
                onFocus={() => { setDropdownOpen(true) }}
                onBlur={() => {
                    setTimeout(() => {
                        setDropdownOpen(false)
                    }, 150)
                }}
            />
            <FontAwesomeIcon
                icon={isPlacePredictionsLoading ? faSpinnerThird : faSearchLocation}
                className={`position-absolute text-400 search-box-icon ${isPlacePredictionsLoading && 'fa-spin mt-0 top-0 py-2 me-1'}`}
            />
            {searchInputValue && (
                <div
                    className="search-box-close-btn-container"
                >
                    <FalconCloseButton
                        size="sm"
                        noOutline
                        onClick={() => setSearchInputValue('')}
                    />
                </div>
            )}
        </Form>
        <Dropdown.Toggle
            as="div"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            bsPrefix="toggle"
        >

        </Dropdown.Toggle>
        <Dropdown.Menu>
            <div className="scrollbar py-2" style={{ maxHeight: '24rem' }}>
                {placePredictions.length === 0 ? <p className="fs-1 text-center mb-0">No results found</p>
                    :
                    <><p className="fs--1 mb-0 ms-3">Results</p>
                        {placePredictions.map((item, index) => {
                            return <Dropdown.Item key={index} onClick={e => { handlePlaceSelect(item); }} >
                                <p className="fw-bold mb-0">{item.structured_formatting.main_text}</p>
                                <span className="text-secondary">{item.structured_formatting.secondary_text}</span>
                            </Dropdown.Item>
                        })}
                    </>
                }
            </div>
            <div className="text-end">
                <Image src={isDark ? PoweredByGoogleOnDark : PoweredByGoogleOnLight} style={{ height: '23px' }} className="ms-auto p-1" />
            </div>
        </Dropdown.Menu>
    </Dropdown>
}