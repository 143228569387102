import { render } from "@testing-library/react";
import Background from "components/common/Background";
import React from "react";
import { Alert, Button, Card, Image } from "react-bootstrap";
import Logo from 'assets/img/logos/quickbooks-small.png'
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle, faLinkHorizontalSlash } from "@fortawesome/pro-duotone-svg-icons";
import IconAlert from "components/common/IconAlert";
const QuickBooksIntegration = (props) => {

    return (<>
        <Card {...props}>
            <Card.Header className="position-relative" >
                <Flex justifyContent='between'>
                    <div className="flex-1 position-relative ">
                        <h5>QuickBooks</h5>
                        <p className="fs--1 text-muted mb-0">Connect to QuickBooks accounting software</p>
                    </div>
                    <Image height={48} src={Logo} className="bg-white px-2 py-1 light rounded" />
                </Flex>
            </Card.Header>
            <Card.Body>
                <IconAlert variant="info">
                        Please note!
                        Only 1 (one) QuickBooks account can be used to connect to ATLASS!
                        Once you authenticate, ATLASS saves your QuickBooks session information to perform necessary operations.
                        ATLASS <b>does not</b> have access to and <b>does not</b> save your QuickBooks login information.

                </IconAlert>
                <Alert variant="secondary">
                    <div className="text-center">
                        <p className="text-muted mb-0">Not connected <FontAwesomeIcon icon={faLinkHorizontalSlash} /></p>
                    </div>
                </Alert>
                <div className="d-grid gap-2">
                    <Button variant="success" >Connect!</Button>
                </div>
            </Card.Body>
        </Card>
    </>)
}

export default QuickBooksIntegration