import { faBug, faExclamation, faExclamationCircle, faExclamationSquare, faExclamationTriangle, faFaceSmilePlus, faSparkles, faCloudArrowUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useCallback, useState } from "react";
import { Button, Form, Dropdown, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ReactSelect from "react-select";
import { getSize, getFontawesomeIconByFileMIMEType } from 'helpers/utils';
import CardDropdown from 'components/common/CardDropdown';


const TicketForm = () => {


    // const onDropAccepted = useCallback(acceptedFiles => {
    //     const reader = new FileReader()
    //     console.log(onDropAccepted)
    // }, [])

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*, application/pdf',
        onDrop: acceptedFiles => {
            setFiles({
                ...files,
                ...acceptedFiles.map(file => {
                    console.log(file)
                    if (file.type.includes('image')) return Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                    else return file
                })
            });
        }
    });

    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
    };
    // const files = acceptedFiles.map(file => (
    //     <li key={file.path}>
    //         {file.path} - {file.size} bytes
    //     </li>
    // ));


    const [selectedTicketType, setSelectedTicketType] = useState(false)
    const [TicketTypes, setTicketTypes] = useState([
        {
            value: 0,
            label: <><div className="text-dark">
                <FontAwesomeIcon icon={faBug} /> Issue/Bug Report
                <p className="text-muted fs--1 mb-0">Something not working as intended? Let us know</p>
            </div>
            </>
        },
        {
            value: 1,
            label: <><div className="text-dark">
                <FontAwesomeIcon icon={faFaceSmilePlus} /> Feature Request
                <p className="text-muted fs--1 mb-0">Something missing that you need? Ask for it here</p>
            </div>
            </>
        }, ,
        {
            value: 2,
            label: <><div className="text-dark">
                <FontAwesomeIcon icon={faSparkles} /> Feedback
                <p className="text-muted fs--1 mb-0">Tell us how we're doing</p>
            </div>
            </>
        }
    ])

    const [selectedSeverity, setSelectedSeverity] = useState(false)
    const [SeverityTypes, setSeverityTypes] = useState([
        {
            value: 0,
            label: <><div className="bg-danger text-white rounded p-1">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Urgent / System breaking
                <p className="text-muted fs--1 mb-0">Only use this level of severity if the issue you found is making the system unusable to you</p>
            </div>
            </>
        },
        {
            value: 1,
            label: <><div className="bg-warning text-white rounded p-1">
                <FontAwesomeIcon icon={faExclamationCircle} /> Needs attention
                <p className="text-muted fs--1 mb-0">Issue that needs to be addressed quickly, but is not making the system unusable</p>
            </div>
            </>
        }, ,
        {
            value: 2,
            label: <><div className="text-dark p-1">
                <FontAwesomeIcon icon={faExclamationSquare} /> Can wait
                <p className="text-muted fs--1 mb-0">Minor problem or a bug</p>
            </div>
            </>
        }
    ])

    const handleSubmit = e => {
        e.preventDefault();
    }


    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>What is this ticket about?</Form.Label>
                    <ReactSelect
                        closeMenuOnSelect={true}
                        options={TicketTypes}
                        value={selectedTicketType}
                        onChange={v => setSelectedTicketType(v)}
                        classNamePrefix="react-select"
                    />
                </Form.Group>
                {(selectedTicketType && selectedTicketType.value == 0) &&
                    <Form.Group className="mb-3">
                        <Form.Label>Severity</Form.Label>
                        <ReactSelect
                            closeMenuOnSelect={true}
                            options={SeverityTypes}
                            value={selectedSeverity}
                            onChange={v => setSelectedSeverity(v)}
                            required
                            classNamePrefix="react-select"
                        />
                    </Form.Group>
                }
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Title summarry of what's going on"
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        rows={6}
                        placeholder="Please describe in a greater detail what is this issue about"
                    />
                </Form.Group>

                <div  {...getRootProps({ className: 'dropzone-area py-6' })}>
                    <input {...getInputProps()} />
                    <Flex justifyContent="center">
                        <FontAwesomeIcon icon={faCloudArrowUp} size="2x" className="fs-2 me-1" />
                        <p className="fs-0 mb-0 text-700">Drop your files you want to upload here</p>
                    </Flex>
                </div>
                <div className="mt-3">
                    {files && files.map(file => (
                        <Flex
                            alignItems="center"
                            className="py-3 border-bottom btn-reveal-trigger"
                            key={file?.path}
                        >
                            {file?.preview ? <Image rounded width={40} height={40} src={file?.preview} />
                                :
                                getFontawesomeIconByFileMIMEType({ MIME: file?.type, size: "3x" })
                            }
                            <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                                <div>
                                    <h6>{file?.path}</h6>
                                    <Flex className="position-relative" alignItems="center">
                                        <p className="mb-0 fs--1 text-400 line-height-1">
                                            <strong>
                                                {getSize(file?.size)}
                                            </strong>
                                        </p>
                                    </Flex>
                                </div>
                            </Flex>
                            <CardDropdown>
                                <div className="py-2">
                                    <Dropdown.Item className="text-danger" onClick={() => handleRemove(file?.path)}>
                                        Remove
                                    </Dropdown.Item>
                                </div>
                            </CardDropdown>
                        </Flex>
                    ))}
                </div>
                <Form.Group className="mt-3">
                    <Button
                        type="submit"
                        className=""
                        variant="primary">
                        Submit
                    </Button>

                </Form.Group>
            </Form>
        </>
    )
}

export default TicketForm