import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Form, ProgressBar, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { getRandomNumber } from "helpers/utils";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

export const RepairListItem = ({ Record }) => {

    return (<Row
        className='align-items-center py-2 border-bottom border-200'
    >
        <Col className="py-1">
            <h6>
                <b className="text-danger">{Record.component.icon} {Record.component.tag_name}</b>
                <div className="text-800 stretched-link">
                    {Record.description}
                </div>
            </h6>
        </Col>
        <Col>
            <Row className="justify-content-end align-items-center fs--2">
                <Col xs="auto pe-0">
                    Parts
                    <div className="fw-semi-bold">{formatter.format(getRandomNumber(50, 5000))}</div>
                </Col>
                <Col xs="5" className="pe-card">
                    Labor
                    <div className="fw-semi-bold">{formatter.format(getRandomNumber(50, 5000))}</div>
                </Col>
            </Row>
        </Col>
    </Row>)
}

const RepairList = ({ List }) => {
    return <>
    </>
}
export default RepairList