import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { EINInput, MCInput, USDOTInput, USZIPInput } from 'components/common/MaskedInputs'
import { AtlassStateSelect_NA } from "components/common/AtlassSelect";
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { ActionMeta, OnChangeValue } from 'react-select';
import { Link } from "react-router-dom";
import Flex from "components/common/Flex";
import { StatementNumberStructure, InvoiceNumberStructure } from "data/autocomplete/NumberFormattingPresets";
import { toast } from "react-toastify";
import APICommunicator from "api/APICommunicator";


const AccountingSettings = ({ data, successcallback, ...rest }) => {
    const API = new APICommunicator('Settings/Company');

    const [InvoiceOptions, setInvoiceOptions] = useState([])
    const [invoiceNumberFormatOutput, setInvoiceNumberFormatOutput] = useState(false)
    const [invoiceStructure, setInvoiceStructure] = useState([])

    const [StatementOptions, setStatementOptions] = useState([])
    const [statementNumberFormatOutput, setStatementNumberFormatOutput] = useState(false)
    const [statementStructure, setStatementStructure] = useState([])


    const [formData, setFormData] = useState({})

    const handleSubmit = e => {
        e.preventDefault();

        if (!formData) return toast.error('Accounting information not set properly')

        const submitArray = Object.entries(formData).map((item, value) => ({
            type: 'accounting',
            component: item[0],
            value: item[1]
        }))

        API.PUT('/', submitArray)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                if (typeof successcallback === 'function') successcallback(Response.content)
                toast.success('Accounting settings saved!')
            })
            .catch(handleErrorWithToast)
    }

    const handleFieldChange = e => setFormData({
            ...formData,
            [e.target.name]: e.target.value
    })

    const handleInvoiceCreateableSelectChange = TAGS => {
        const Structure = TAGS.map(TAG => TAG.value).join(',')
        setFormData({
            ...formData,
            'invoices_number_structure': Structure
        })

        var Output = '';
        for (const Tag of TAGS) {
            const EXISTS = InvoiceNumberStructure.find(x => x.value === Tag.value)
            Output += `${(EXISTS) ? EXISTS.example : Tag.value}`
        }

        setInvoiceStructure(TAGS) // set field value
        setInvoiceNumberFormatOutput(Output) //. display example
        setInvoiceOptions(InvoiceNumberStructure.filter(ar => !TAGS.find(rm => (rm.value === ar.value)))) // remove selected options from list
    }

    const handleStatementCreateableSelectChange = TAGS => {

        const Structure = TAGS.map(TAG => TAG.value).join(',')

        setFormData({
            ...formData,
            'statements_number_structure': Structure
        })

        var Output = '';

        for (const Tag of TAGS) {
            const EXISTS = StatementNumberStructure.find(x => x.value === Tag.value)
            Output += `${(EXISTS) ? EXISTS.example : Tag.value}`
        }

        setStatementStructure(TAGS) // set field value
        setStatementNumberFormatOutput(Output) //. display example
        setStatementOptions(StatementNumberStructure.filter(ar => !TAGS.find(rm => (rm.value === ar.value)))) // remove selected options from list
    }

    useEffect(() => {

        setInvoiceOptions(InvoiceNumberStructure.map(Option => ({
            value: Option.value,
            label: Option.label
        })))

        setStatementOptions(StatementNumberStructure.map(Option => ({
            value: Option.value,
            label: Option.label
        })))
    }, [])

    useEffect(() => {
        if (data && data.accounting) {
            const { accounting } = data;
            setFormData(accounting)

            // Invoice Number Structure
            const selectedInvoiceStructureTags = accounting.invoices_number_structure.split(',').map(v => ({
                value: v,
                label: InvoiceNumberStructure.find(x => x.value === v) ? InvoiceNumberStructure.find(x => x.value === v).label : v,
            }))
            setInvoiceStructure(selectedInvoiceStructureTags)
            setInvoiceOptions(InvoiceNumberStructure.filter(ar => !selectedInvoiceStructureTags.find(rm => (rm.value === ar.value))))


            // Statement Number Structure
            const selectedStatementStructureTags = accounting.statements_number_structure.split(',').map(v => ({
                value: v,
                label: StatementNumberStructure.find(x => x.value === v) ? StatementNumberStructure.find(x => x.value === v).label : v,
            }))
            setStatementStructure(selectedStatementStructureTags)
            setStatementOptions(StatementNumberStructure.filter(ar => !selectedStatementStructureTags.find(rm => (rm.value === ar.value))))

        }
    }, [data])

    useEffect(() => { }, [formData])
    useEffect(() => { console.log(invoiceStructure); }, [invoiceStructure])

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response))
    return (<>
        <Card  {...rest}>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <h5 className="fw-bold">Accounts Receivable</h5>
                    <div className="border-dashed-bottom my-3"></div>
                    {/* InvoicesNumberFormat */}
                    <Form.Group >
                        <Alert variant="warning">
                            <Form.Label as="div" className="fw-bold">Invoices Numbering Format</Form.Label>
                            This is a dangerous area! Please refer to <Link to="/Support/Knowledgebase#HowToSetStatementNumberingFormat">this article</Link> before making a change or <Link to="/Support/Contact">contact support</Link> for help

                            <CreatableSelect
                                placeholder='Type text and press enter and/or select from the list'
                                name="invoice_number_structure"
                                className="mt-1"
                                classNamePrefix="react-select"
                                options={InvoiceOptions}
                                onChange={handleInvoiceCreateableSelectChange}
                                value={invoiceStructure}
                                isClearable
                                isMulti
                            />
                            {invoiceNumberFormatOutput &&
                                <Alert variant="info" className="mt-3">
                                    Output format example:<br />
                                    <code>{invoiceNumberFormatOutput}</code>
                                </Alert>
                            }
                        </Alert>
                    </Form.Group>
                    {/* Invoices Message */}
                    <Form.Group className="mb-3 mt-3" >
                        <Form.Label >
                            Default Invoices Message
                        </Form.Label>
                        <Form.Control as="textarea" name="invoices_message" size="sm" rows="4" maxLength={255} value={formData?.invoices_message ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    {/* REMIT ADDRESS */}
                    <Form.Group>
                        <Form.Label>Remit Address</Form.Label>
                        <Form.Control name="invoices_address" placeholder="Required *" maxLength={255} value={formData?.invoices_address ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address Line 2 </Form.Label>
                        <Form.Control name="invoices_address_2" maxLength={255} value={formData?.invoices_address2 ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>City </Form.Label>
                            <Form.Control name="invoices_city" placeholder="Required *" maxLength={255} value={formData?.invoices_city ?? ""} onChange={handleFieldChange} />
                        </Col>
                        <Col>
                            <Form.Label>State</Form.Label>
                            <AtlassStateSelect_NA name="invoices_state" placeholder="Required *" value={formData?.invoices_state ?? ""} onChange={v => { handleFieldChange(v, 'invoices_state') }} />
                        </Col>
                        <Col>
                            <Form.Label>ZIP</Form.Label>
                            <USZIPInput name="invoices_zip" placeholder="Required *" value={formData.invoices_zip ?? ""} onChange={handleFieldChange} />
                        </Col>

                    </Row>

                    <h5 className="fw-bold mt-6">Accounts Payable</h5>
                    <div className="border-dashed-bottom my-3"></div>
                    {/* StatementNumberFormat */}
                    <Form.Group >

                        <Alert variant="warning">
                            <Form.Label as="div" className="fw-bold">Statement Numbering Format</Form.Label>
                            This is a dangerous area! Please refer to <Link to="/Support/Knowledgebase#HowToSetStatementNumberingFormat">this article</Link> before making a change or <Link to="/Support/Contact">contact support</Link> for help
                            <CreatableSelect
                                placeholder='Type text and press enter and/or select from the list'
                                className='mt-1'
                                classNamePrefix="react-select"
                                name="statement_number_structure"
                                options={StatementOptions}
                                onChange={handleStatementCreateableSelectChange}
                                value={statementStructure}
                                isClearable
                                isMulti
                            />
                            {statementNumberFormatOutput &&
                                <Alert variant="info" className="mt-3">
                                    Output format example:<br />
                                    <code>{statementNumberFormatOutput}</code>
                                </Alert>
                            }
                        </Alert>
                    </Form.Group>
                    {/* Statements Message */}
                    <Form.Group className="mb-3 mt-3" >
                        <Form.Label >
                            Default Statements Message
                        </Form.Label>
                        <Form.Control as="textarea" name="statements_message" size="sm" rows="4" value={formData?.statements_message ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    {/* STATEMENTS ADDRESS */}
                    <Form.Group>
                        <Form.Label>Statements Address</Form.Label>
                        <Form.Control name="statements_address" placeholder="Required *" maxLength={255} value={formData?.statements_address ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address Line 2 </Form.Label>
                        <Form.Control name="statements_address_2" maxLength={255} onChange={handleFieldChange} value={formData?.statements_address_2 ?? ""} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>City </Form.Label>
                            <Form.Control name="statements_city" placeholder="Required *" maxLength={255} onChange={handleFieldChange} value={formData?.statements_city ?? ""} />
                        </Col>
                        <Col>
                            <Form.Label>State</Form.Label>
                            <AtlassStateSelect_NA name="statements_state" placeholder="Required *" value={formData?.statements_state ?? ""} onChange={v => { handleFieldChange(v, 'statements_state') }} />
                        </Col>
                        <Col>
                            <Form.Label>ZIP</Form.Label>
                            <USZIPInput name="statements_zip" placeholder="Required *" onChange={handleFieldChange} value={formData?.statements_zip ?? ""} />
                        </Col>

                    </Row>

                    <Flex justifyContent="end" className="mt-3" >
                        <Button type="submit"
                            disabled={
                                !formData ||
                                !formData.invoices_number_structure ||
                                !formData.invoices_address ||
                                !formData.invoices_city ||
                                !formData.invoices_state ||
                                !formData.invoices_zip ||
                                !formData.statements_number_structure ||
                                !formData.statements_address ||
                                !formData.statements_city ||
                                !formData.statements_state ||
                                !formData.statements_zip
                            }
                        >
                            Save
                        </Button>
                    </Flex>
                </Form>
            </Card.Body>
        </Card>
    </>)
}

export default AccountingSettings