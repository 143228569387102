import React, { useContext, useState } from 'react'
import {  useHistory } from 'react-router-dom';
import Cookies from 'js-cookie'
import { BASE_URL, BASE_URL_UNAUTHENTICATED} from '../api/APICommunicator'
import Permissions from 'data/important/permissions'
import { isIterableArray } from 'helpers/utils';

const ENDPOINT = `/Auth`
const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getAccessToken = () => window.sessionStorage.getItem("ACCESS_TOKEN")
export const getRefreshToken = () => Cookies.get('REFRESH_TOKEN')
export const getUserData = () => Cookies.get('USER_DATA')
export const isAuthenticated = () => !!getRefreshToken()
export const isAccesstokenExpired = () => {
    const EXPIRES_IN = getTokenExpiryIn(window.sessionStorage.getItem('ACCESS_TOKEN_EXPIRE'))
    if (EXPIRES_IN) return EXPIRES_IN.minutes <= 1
    return true
}

export const hasPermission = PERMISSION_NAME => isAuthenticated() && typeof JSON.parse(getUserData()).permissions === 'object' && JSON.parse(getUserData()).permissions.indexOf(PERMISSION_NAME) >= 0;
export const hasPermissions = PERMISSIONS => {
    if (!isAuthenticated()) return false // not authenticated
    if (typeof JSON.parse(getUserData()).permissions !== 'object') return false //no permissions array in logged user data 
    if (typeof PERMISSIONS === 'string') return hasPermission(PERMISSIONS) //only one permission is asked for
    if (!isIterableArray(PERMISSIONS)) return false // not an array 
    for (const PERMISSION of PERMISSIONS) {
        // console.log(PERMISSION);
        // console.log(JSON.parse(getUserData()).permissions.indexOf(PERMISSION));
        if (typeof PERMISSION !== 'string') return false  // permission not a string 
        if (JSON.parse(getUserData()).permissions.indexOf(PERMISSION) <= -1) return false
    }
    return true
}
export const endpointToPermissionCodeName = ENDPOINT =>  Permissions.find(PERMISSION => PERMISSION.frontend_endpoint === ENDPOINT)?.code_name
export const hasEndpointPermission = ENDPOINT => true
// export const hasEndpointPermission = ENDPOINT => hasPermission(endpointToPermissionCodeName(ENDPOINT))



export function refreshAccessToken() {
    const QUERY = `/RefreshAccessToken`;

    const handleRefreshError = Response => {
        if (!Response || !Response.details) return console.error('Error refreshing access token', Response)
        if (Response.details.error !== 'refreshToken') return console.error('Error refreshing access token', Response)
        Logout();
    }

    return new Promise((resolve, reject) => {
        fetch(BASE_URL + ENDPOINT + QUERY, {
            method: 'POST',
            headers: HEADERS,
            body: JSON.stringify({
                refreshToken: Cookies.get('REFRESH_TOKEN')
            })
        })
            .then(Response => Response.json())
            .then(Response => {
                if (Response.status !== 'success') return handleRefreshError(Response)
                const RESPONSE = Response.content;
                const ACCESS_TOKEN = RESPONSE.accessToken
                const ACCESS_TOKEN_EXPIRE = RESPONSE.accessTokenExpire
                window.sessionStorage.setItem('ACCESS_TOKEN', ACCESS_TOKEN)
                window.sessionStorage.setItem('ACCESS_TOKEN_EXPIRE', ACCESS_TOKEN_EXPIRE)
                resolve()
            })
            .catch(e => {
                console.error('Error refreshing access token', e);
                reject();
            })
    })
}

export async function Logout() {   
    return new Promise(async (resolve, reject) => {
        Cookies.remove('REFRESH_TOKEN')
        Cookies.remove('REFRESH_TOKEN_EXPIRE')
        Cookies.remove('USER_DATA')
        window.sessionStorage.removeItem('ACCESS_TOKEN')
        window.sessionStorage.removeItem('ACCESS_TOKEN_EXPIRE')
        window.location.replace("/")
        resolve()
   }) 
    // return auth.signOut()
}

const changeTimezone = (date, tz = 'UTC') => {
    var invdate = new Date(date.toLocaleString('en-US', { timeZone: tz }));
    var diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
}

function getTokenExpiryIn(TOKEN_EXPIRES = false) {
    if (!TOKEN_EXPIRES) return console.error(`Can't get expiry`)
    const MILISECOND = 1
    const SECONDS = MILISECOND * 1000
    const MINUTES = SECONDS * 60
    const HOURS = MINUTES * 60
    const DAYS = HOURS * 24
    const CURRENT_DATE_UTC = changeTimezone(new Date(), 'UTC')
    const TOKEN_EXPIRE = new Date(TOKEN_EXPIRES);
    const EXPIRE_DIFFERENCE = TOKEN_EXPIRE.getTime() - CURRENT_DATE_UTC.getTime();
    const EXPIRE_DIFFERENCE_IN_MILISECONDS = EXPIRE_DIFFERENCE / MILISECOND;
    const EXPIRE_DIFFERENCE_IN_SECONDS = EXPIRE_DIFFERENCE / SECONDS;
    const EXPIRE_DIFFERENCE_IN_MINUTES = EXPIRE_DIFFERENCE / MINUTES;
    const EXPIRE_DIFFERENCE_IN_HOURS = EXPIRE_DIFFERENCE / HOURS;
    const EXPIRE_DIFFERENCE_IN_DAYS = EXPIRE_DIFFERENCE / DAYS;
    return { 
        miliseconds: EXPIRE_DIFFERENCE_IN_MILISECONDS,
        seconds: EXPIRE_DIFFERENCE_IN_SECONDS,
        minutes: EXPIRE_DIFFERENCE_IN_MINUTES,
        hours: EXPIRE_DIFFERENCE_IN_HOURS,
        days: EXPIRE_DIFFERENCE_IN_DAYS
    }
    
}

const AuthContext = React.createContext();


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState('asd');
    const history = useHistory();

    const getCurrentUser = (trimmed = true) => {
        const User = isAuthenticated() ? JSON.parse(getUserData()) : false
        var output = User
        if (trimmed) delete output.permissions
        return output
    }

    function setAccessToken(TOKEN = false, EXPIRES = false) {
        if (!TOKEN || !EXPIRES) return console.error(`Can't set Access Token`)
        window.sessionStorage.setItem('ACCESS_TOKEN', TOKEN)
        window.sessionStorage.setItem('ACCESS_TOKEN_EXPIRE', EXPIRES)
    }

    function setRefreshToken(TOKEN = false, EXPIRES = false) {
        if (!TOKEN || !EXPIRES) return console.error(`Can't set Refresh Token`)
        Cookies.set('REFRESH_TOKEN', TOKEN, {
            sameSite: 'strict'
        })
        Cookies.set('REFRESH_TOKEN_EXPIRE', EXPIRES, {
            sameSite: 'strict'
        })
    }

    function login(username, password) {  
        const QUERY = `/Login`;
        // return true
        return new Promise((resolve, reject) => {
            fetch(BASE_URL + ENDPOINT + QUERY, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            })
            .then(Response => Response.json())
            .then(Response => {
                if (!Response) return reject('Login error');
                if (Response.status !== 'success') return reject(Response.message);
                const RESPONSE = Response.content
            
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') console.log(RESPONSE)
                
                const ACCESS_TOKEN = RESPONSE.accessToken
                const ACCESS_TOKEN_EXPIRE = RESPONSE.accessTokenExpire
                const REFRESH_TOKEN = RESPONSE.refreshToken
                const REFRESH_TOKEN_EXPIRE = RESPONSE.refreshTokenExpire
                const SERVICE_UNIQUE_ID = RESPONSE.service_unique_id
    
                setAccessToken(ACCESS_TOKEN, ACCESS_TOKEN_EXPIRE)
                setRefreshToken(REFRESH_TOKEN, REFRESH_TOKEN_EXPIRE)
                
                delete RESPONSE.accessToken
                delete RESPONSE.accessTokenExpire
                delete RESPONSE.refreshToken
                delete RESPONSE.refreshTokenExpire
    
                Cookies.set('USER_DATA', JSON.stringify(RESPONSE), {
                    sameSite: 'strict'
                })
                resolve(Response)
            })
            .catch(e => reject(e))
        })
    }

    function logout() {
        const QUERY = `/Logout`;
        fetch(BASE_URL + ENDPOINT + QUERY, {
            method: 'POST',
            headers: HEADERS
        })
        .then(Response => Response.json())
        .then(Response => console.log(Response))
        Cookies.remove('REFRESH_TOKEN')
        Cookies.remove('REFRESH_TOKEN_EXPIRE')
        Cookies.remove('USER_DATA')
        window.sessionStorage.removeItem('ACCESS_TOKEN')
        window.sessionStorage.removeItem('ACCESS_TOKEN_EXPIRE')
        history.push("/")
        // return auth.signOut()
    }

    function reset_password(email) {
        const QUERY = `/ResetPassword`;
        return new Promise((resolve, reject) => {
            fetch(BASE_URL_UNAUTHENTICATED + ENDPOINT + QUERY, {
                body: JSON.stringify({
                    email: email
                }),
                method: 'POST',
                headers: HEADERS
            })
            .then(Response => Response.json())
                .then(Response => resolve(Response))
            .catch(Error => reject(Error))
        })
    }

    function check_activation_key(KEY) {
        const QUERY = `/Validate/ActivationCode/${KEY}`;
        return new Promise((resolve, reject) => {
            fetch(BASE_URL_UNAUTHENTICATED + ENDPOINT + QUERY, {
                method: 'GET',
                headers: HEADERS
            })
                .then(Response => Response.json())
                .then(Response => resolve(Response))
                .catch(Error => reject(Error))
        })
    }
    function activate_user(data) {
        const QUERY = `/Activate`;
        return new Promise((resolve, reject) => {
            fetch(BASE_URL_UNAUTHENTICATED + ENDPOINT + QUERY, {
                body: JSON.stringify(data),
                method: 'PUT',
                headers: HEADERS
            })
                .then(Response => Response.json())
                .then(Response => resolve(Response))
                .catch(Error => reject(Error))
        })
    }

    function forgotten_password(username) {
        const QUERY = `/ForgottenPassword`;
        return new Promise((resolve, reject) => {
            if(!username) reject('Insuficcient parameters')
            fetch(BASE_URL + ENDPOINT + QUERY, {
                body: JSON.stringify({
                    username: username
                }),
                method: 'PUT',
                headers: HEADERS
            })
                .then(Response => Response.json())
                .then(Response => resolve(Response))
                .catch(Error => reject(Error))
        })
    }


    function check_password_reset_code(KEY) {
        const QUERY = `/Validate/PasswordResetCode/${KEY}`;
        return new Promise((resolve, reject) => {
            fetch(BASE_URL_UNAUTHENTICATED + ENDPOINT + QUERY, {
                method: 'GET',
                headers: HEADERS
            })
                .then(Response => Response.json())
                .then(Response => resolve(Response))
                .catch(Error => reject(Error))
        })
    }
    function reset_password(forgotten_password_code, password,password_confirm) {
        const QUERY = `/ResetPassword`;
        return new Promise((resolve, reject) => {
            if(!forgotten_password_code || !password || !password_confirm) reject('Insuficcient parameters')
            fetch(BASE_URL + ENDPOINT + QUERY, {
                body: JSON.stringify({
                    forgotten_password_code: forgotten_password_code,
                    password: password,
                    password_confirm: password_confirm,
                }),
                method: 'PUT',
                headers: HEADERS
            })
                .then(Response => Response.json())
                .then(Response => resolve(Response))
                .catch(Error => reject(Error))
        })
    }

    const value = {
        currentUser,
        login,
        reset_password,
        check_activation_key,
        activate_user,
        forgotten_password,
        check_password_reset_code,
        reset_password,
        setAccessToken,
        refreshAccessToken,
        getCurrentUser,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
