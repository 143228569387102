import React, { useState, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FaqAccordionItem from './FaqAccordionItem';
import { faqAccordions as faqsData } from 'data/faqs';

const FaqAccordion = () => {
  const [faqs, setFaqs] = useState(faqsData);
  const [searchValue, setSearchValue] = useState('')


  useEffect(() => {
    setFaqs(faqsData.filter(Q => Q.title.toLowerCase().includes(searchValue.toLowerCase()) || Q.description.toLowerCase().includes(searchValue.toLowerCase())))
  }, [searchValue])

  useEffect(() => {
    document.title = `FAQ`
  }, [])

  return (
    <>
      <PageHeader
        title="Frequently Asked Questions"
        description="Below you'll find answers to the questions we get asked the most about the work with ATLASS"
        className="mb-3"
      />
      <Card className="mb-3">
        <Card.Body>
          <div>
            <Form className="position-relative">
              <Form.Control
                type="search"
                placeholder="Search questions ..."
                aria-label="Search"
                className="rounded-pill search-input ps-5"
                value={searchValue}
                onChange={({ target }) => setSearchValue(target.value)}
              />
              <FontAwesomeIcon
                icon="search"
                className="position-absolute top-0 mt-2 ms-3 text-400 search-box-icon"
              />
            </Form>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 0}
                isLast={index === faqs.length - 1}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

export default FaqAccordion;
