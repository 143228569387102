import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PageHeader from "components/common/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccountsReceivable = () => {

    useEffect(() => {
        document.title = `Accounts - Receivable`
    }, [])

    return (<>
        <PageHeader
            title="Accounts Receivable"
            description="Invoicing and payments"
            className="mb-3"
        />
    </>)
}

export default AccountsReceivable