import PageHeader from "components/common/PageHeader";
import React, { useEffect, useState } from "react";
import SettingsIcon from 'assets/img/illustrations/settings-icon.png'
import { Alert, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNodes, faDollarSign, faGear, faRoad, faRoadBarrier, faTimer } from "@fortawesome/pro-duotone-svg-icons";
import GeneralInformationForm from "./Forms/GeneralInformation";
import SectionHeader from "components/common/SectionHeader";
import { Link } from "react-router-dom";
import QuickBooksIntegration from "./Forms/QuickBooksIntegration";
import EFSIntegration from "./Forms/EFSIntegration";
import DATIntegration from "./Forms/DATIntegration";
import IconAlert from "components/common/IconAlert";
import TruckStopIntegration from "./Forms/TruckStopIntegration";
import CHRobinsonIntegration from "./Forms/CHRobinsonIntegration";
import TQLIntegration from "./Forms/TQLIntegration";
import OperationsSettings from "./Forms/OperationsSettings";
import SamsaraIntegration from "./Forms/SamsaraIntegration";
import AccountingSettings from "./Forms/AccountingSettings";
import RequestIntegration from "./Forms/RequestIntegration";
import APICommunicator from "api/APICommunicator";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import ModuleErrorWithReloadAlert from "components/common/ModuleErrorWithReloadAlert";
import ChangeLogo from "./Forms/ChangeLogo";



const Settings = () => {
    const [COMPANY, setCompany] = useState('')
    const [companySettingsError, setCompanySettingsError] = useState(false)
    const API = new APICommunicator('Settings')

    const getdata = () => {
        API.GET('/Company')
            .then(Response => {
                setCompanySettingsError(false)
                if (!Response || Response.status !== 'success') return handleCompanySettingsError(Response);
                setCompany(Response.content)
            })
            .catch(handleCompanySettingsError)
    }

    const handleCompanySettingsError = Response => setCompanySettingsError(API.handleResponseErrors(Response))

    useEffect(() => {
        document.title = `Company Settings`
        getdata()
    }, [])

    return (
        <>
            <PageHeader
                title="Settings"
                description="In this page you will find settings to manage your company's information and the way ATLASS works for you."
                className="mb-3"
                image={SettingsIcon}
            />
            <Row>
                <Col xl={7} lg={6} md={12}>
                    {/* Primary Column */}
                    <SectionHeader
                        Title='Company Information'
                        Subtitle='General contact Information'
                        Icon={<FontAwesomeIcon icon={faGear} className="me-0 ms-2" size="2x" />}
                    />
                    {companySettingsError ? <ModuleErrorWithReloadAlert errordata={companySettingsError} reloadCallback={() => getdata()} />
                        :
                        <ChangeLogo className="mb-3" data={COMPANY} successcallback={data => setCompany(data)} />
                    }
                    {companySettingsError ? <ModuleErrorWithReloadAlert errordata={companySettingsError} reloadCallback={() => getdata()} />
                        :
                        <GeneralInformationForm className="mb-3" data={COMPANY} successcallback={data => { setCompany(data) }} />
                    }
                    <SectionHeader
                        Title='Operations'
                        Subtitle='Settings related to orders and operations'
                        Icon={<FontAwesomeIcon icon={faRoad} className="me-0 ms-2" size="2x" />}
                    />
                    {companySettingsError ? <ModuleErrorWithReloadAlert errordata={companySettingsError} reloadCallback={() => getdata()} />
                        :
                        <OperationsSettings className="mb-3" data={COMPANY} successcallback={data => { setCompany(data) }} />
                    } 
                    <SectionHeader
                        Title='Accounting'
                        Subtitle='Settings related to accounting'
                        Icon={<FontAwesomeIcon icon={faDollarSign} className="me-0 ms-2" size="2x" />}
                    />
                    {companySettingsError ? <ModuleErrorWithReloadAlert errordata={companySettingsError} reloadCallback={() => getdata()} />
                        :
                        <AccountingSettings className="mb-3" data={COMPANY} successcallback={data => { setCompany(data) }} />
                    } 

                </Col>



                <Col xl={5} lg={6} md={12}>

                    {/* Secondary Column */}

                    <SectionHeader
                        Title='Integration'
                        Subtitle={<>
                            Connect with 3rd party software
                        </>}
                        Icon={<FontAwesomeIcon icon={faCircleNodes} className="me-0 ms-2" size="2x" />}
                    />
                    <Alert variant="danger">
                        Plase be careful when configuring these settings and don't change anything if you're unsure. If you need help, please <Link to="/Support/Contact">contact support</Link> or refer to our <Link to="/Support/FAQ">FAQ</Link> page
                    </Alert>
                    <QuickBooksIntegration className="mb-3" />
                    <EFSIntegration className="mb-4" />

                    <SectionHeader
                        Title='Upcoming integrations'
                        Subtitle={<>
                            Integrations we're currently working on
                        </>}
                        Icon={<FontAwesomeIcon icon={faTimer} className="me-0 ms-2" size="2x" />}
                        className="mb-3"
                    />
                    <SamsaraIntegration className="mb-3" />
                    <DATIntegration className="mb-3" />
                    <TruckStopIntegration className="mb-3" />
                    <CHRobinsonIntegration className="mb-3" />
                    <TQLIntegration className="mb-3" />
                    <RequestIntegration />

                </Col>

            </Row>
        </>
    )
}

export default Settings