import Section from "components/common/Section";
import PrivacyPolicy from "components/pages/help/PrivacyPolicy";
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import bg1 from 'assets/img/backgrounds/shutterstock_v25087742.jpg';
import rhsImage from 'assets/img/logo.png';
import { Link } from 'react-router-dom';
import SMSTermsPolicy from "components/pages/help/SMSTerms";

const SMSTerms = () => {

    return (<>
        <Section
            className="py-0 overflow-hidden light mb-0"
            image={bg1}
            bg="dark"
            overlay="0"
            position="center bottom">
            <Row className="justify-content-center align-items-center pt-8 pt-lg-8 pb-lg-4 pb-xl-0">
                <Col
                    className="pb-2 pb-xl-6 text-center text-xl-start">
                    <h1 className="text-white fw-light">
                        SMS Terms of Service
                    </h1>

                    <p className="lead text-white opacity-75">
                        Terms of service applied to Atlass' SMS Programs and all SMS/MMS communications
                    </p>
                    <p className="text-white opacity-75">
                        Last updated: December 12, 2022
                    </p>
                </Col>
            </Row>
        </Section>
        <Section className="pt-6">
            <SMSTermsPolicy />
        </Section>
    </>)
}

export default SMSTerms