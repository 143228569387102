import { render } from "@testing-library/react";
import Background from "components/common/Background";
import React from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Logo from 'assets/img/logos/WEX_OTR_LOGOS.svg'
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle, faLink, faLinkHorizontalSlash, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";
import IconAlert from "components/common/IconAlert";


const EFSIntegration = (props) => {
    const handleSubmit = () => {

    }

    const handleDeleteMyData = () => {

    }

    const handleTestConnection = () => {

    }
    return (<>
        <Card {...props}>
            <Card.Header className="position-relative" >
                <Flex justifyContent='between'>
                    <div className="flex-1 position-relative ">
                        <h5>WEX Companies</h5>
                        <p className="fs--1 text-muted mb-0">Connect to EFS systems</p>
                    </div>
                    <Image height={48} src={Logo} className="bg-white px-2 py-1 light rounded" />
                </Flex>
            </Card.Header>
            <Card.Body>
                <IconAlert variant="info">
                        Currently ATLASS only supports integration for EFS money codes and EFS fuel transactions.
                        We're actively working on providing more integrated functionality. Thank you for your patience.

                </IconAlert>
                <IconAlert variant="warning">
                    To allow ATLASS access to your EFS account and integrate with it, please first refer to
                    this <Link to="/Support/Knowledgebase/#ConnectWithEFS" >article</Link> and then come back here to finish the configuration.
                </IconAlert>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>EFS Username</Form.Label>
                        <Form.Control type="text" name="username" placeholder="" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>EFS Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Flex justifyContent="between" className="px-0" >
                            <Button variant='falcon-danger' className="text-truncate" onClick={handleDeleteMyData}><FontAwesomeIcon icon={faTrashAlt} className="me-2" />Delete my data!</Button>
                            <div>
                                <Button variant='falcon-success' onClick={handleTestConnection} className="ms-5 me-2"><FontAwesomeIcon icon={faLink} className="me-2" />Test</Button>
                                <Button variant='primary' className="" type="submit">Save</Button>
                            </div>
                        </Flex>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    </>)
}

export default EFSIntegration