import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PageHeader from "components/common/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccountsExpenses = () => {

    useEffect(() => {
        document.title = `Expenses`
    }, [])

    return (<>
        <PageHeader
            title="Expenses"
            description="Expense management and reports"
            className="mb-3"
        />
    </>)
}

export default AccountsExpenses