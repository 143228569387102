import React from "react";
import { Col, Row } from "react-bootstrap";


const SectionHeader = ({ Title, Subtitle, Icon, RightSide }) => {
    return <Col>
        <Row className="d-flex mb-3">

            <Col xs="auto" className="pt-2 ps-2">
                {Icon}
            </Col>
            <Col className="ps-0">
                <h5 className="mb-0 mt-1 text-primary position-relative">
                    <span className="bg-200 dark__bg-1100 px-2 rounded">{Title}</span>
                    <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                </h5>
                <p className="mb-0 px-2 d-none d-sm-inline-block">
                    {Subtitle}
                </p>
            </Col>
            {RightSide &&
                <Col xs='auto'>
                    {RightSide}
                </Col>
            }
        </Row>
    </Col>
}

export default SectionHeader