import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import SectionHeader from "components/common/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/pro-duotone-svg-icons";
import { ContactsTable } from "./ContactsTable";
import IconButton from "components/common/IconButton";
import { hasPermissions } from "context/AuthContext";

export const AddNewContactDefaultButton = props => {
    const [PlaceEditWindow, setPlaceEditWindow] = useState(false)
    const [placeEdit, setPlaceEdit] = useState(false)
    return <>
        <IconButton variant="primary" icon="plus" size="sm" transform="shrink-3" onClick={e => {
            setPlaceEdit(false)
            setPlaceEditWindow(true)
        }} {...props}>
            <span className="d-none d-sm-inline-block ms-1">Add a new contact</span>
        </IconButton>

        {/* <AddEs */}
    </>
}
const Contacts = () => {

    useEffect(() => {
        document.title = `Contacts`
    }, [])

    return (<>
        <Row>
            <Col>
                <SectionHeader
                    Title='Contacts'
                    Subtitle='List of all saved contacts'
                    Icon={<FontAwesomeIcon icon={faAddressCard} className="me-0 ms-2" size="2x" />}
                    RightSide={hasPermissions(['VIEW_CONTACTS', 'MANAGE_CONTACTS']) && <AddNewContactDefaultButton />}
                />
            </Col>
        </Row>
        <Card>
            <Card.Body className="px-0">
                <ContactsTable />
            </Card.Body>
        </Card>
    </>)
}

export default Contacts