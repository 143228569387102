import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, InputGroup, Alert } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import { SelectUserRole } from '../../../../common/APISelect';
import { USPhoneInput, USZIPInput } from 'components/common/MaskedInputs';
import { debounce } from 'helpers/utils';
import BouncingDotsLoader from 'components/common/BouncingDotsLoader';
import { AtlassStateSelect_NA } from "components/common/AtlassSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons';

const RequestUserModal = ({ show, handleClose, successCallback }) => {

    const API = new APICommunicator('Users');

    const [formData, setFormData] = useState({})
    const [error, setError] = useState(false)
    const [usernameFieldProps, setUsernameFieldProps] = useState({})
    const [usernameFeedback, setUsernameFeedback] = useState(false)

    const handleSubmit = e => {
        e.preventDefault();
        API.POST('/', formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }

    const CheckUsername = debounce((e) => {
        e.preventDefault();
        const val = e.target.value
        if (!val) setUsernameFieldProps({})
        if (val) API.GET(`/CheckIfUserExist/${val}`)
            .then(Response => {
                if (Response.content.exist) {
                    setUsernameFeedback(`Username already exists or is invalid. Please choose something else.`)
                    setUsernameFieldProps({ 'isInvalid': true })
                }
                if (!Response.content.exist) setUsernameFieldProps({ 'isValid': true })
            })
            .catch(handleError)
    })


    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><FontAwesomeIcon icon={faUserPlus} className="me-2" />Request new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel label="First Name" className="mb-3">
                                <Form.Control type="text" placeholder="First Name *" name="firstname" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label="Last Name" className="mb-3">
                                <Form.Control type="text" placeholder="Last Name *" name="lastname" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Form.Group as={Col} className="mb-3" controlId="">
                        <Form.Label>Role</Form.Label>
                        <SelectUserRole name="role" placeholder="Assign a role *" onChange={handleFieldFormChange} />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3" controlId="">
                        <Form.Label>Username</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Username *"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="username"
                                onChange={handleFieldFormChange}
                                onKeyUp={e => {
                                    setUsernameFeedback(<BouncingDotsLoader className="mt-2" />);
                                    setUsernameFieldProps({ 'isInvalid': true });
                                    CheckUsername(e)
                                }}
                                {...usernameFieldProps}
                            />
                            <Form.Control.Feedback type="invalid">
                                {usernameFeedback || `Username already exists or is invalid. Please choose something else.`}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <FloatingLabel label="Email address *" className="mb-3">
                        <Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel label="Phone *" className="mb-3">
                        <USPhoneInput type="text" placeholder="Phone" name="phone" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel label="Title" className="mb-3">
                        <Form.Control type="text" placeholder="title" name="title" onChange={handleFieldFormChange} />
                    </FloatingLabel>
                    {/* <div className="border-dashed-bottom my-3"></div> */}
                    <Row className="bg-200 py-2">
                        <Col>
                            <Form.Group className="mb-1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="Address" name="address_1" onChange={handleFieldFormChange} />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Unit / Office / Apartment </Form.Label>
                                <Form.Control type="text" placeholder="Unit / Office / Apartment" name="address_2" onChange={handleFieldFormChange} />
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-1">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="City" name="city" onChange={handleFieldFormChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-1">
                                        <Form.Label>State</Form.Label>
                                        <AtlassStateSelect_NA type="text" placeholder="State" name="state" onChange={handleFieldFormChange} aria-label="Select a State" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-1">
                                        <Form.Label>ZIP</Form.Label>
                                        <USZIPInput type="text" placeholder="ZIP" name="zip" onChange={handleFieldFormChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {error && <Alert variant="warning" dismissible onClose={() => setError(false)} >
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={
                                !formData ||
                                !formData.firstname ||
                                !formData.lastname ||
                                !formData.username ||
                                !formData.email ||
                                !formData.role
                            }>
                            Submit Request for New User
                        </Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}
export default RequestUserModal;