/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Dashboard from 'components/dashboards/default';

// Operations

import Scheduling from 'components/pages/Operations/Scheduling';
import Orders from 'components/pages/Operations/Orders';
import EmailInbox from 'components/pages/Operations/Email';

// Accounting
import AccountingDashboard from 'components/pages/Accounting/Dashboard'
import AccountsReceivable from 'components/pages/Accounting/Receivables';
import AccountsPayable from 'components/pages/Accounting/Payables';
import AccountsExpenses from 'components/pages/Accounting/Expenses';

// Safety
import Incidents from 'components/pages/Safety/Incidents';
import Service from 'components/pages/Safety/Service';
import ServiceRecordView from 'components/pages/Safety/Service/View';
import ServiceRecordUpsert from 'components/pages/Safety/Service/Upsert';

// Directory
import Places from 'components/pages/Directory/Places';
import Equipment from 'components/pages/Directory/Equipment';
import Drivers from 'components/pages/Directory/Drivers';
import Companies from 'components/pages/Directory/Companies';
import Contacts from 'components/pages/Directory/Contacts';

// More


// Settings and Management
import Settings from 'components/pages/Settings/index';
import Users from 'components/pages/Settings/Users/Users';
import UserProfile from 'components/pages/Settings/Users/user/profile/Profile';
import UserRoles from 'components/pages/Settings/UserRoles/UserRoles';
import Role from 'components/pages/Settings/UserRoles/Role';

// help
import FaqAccordion from 'components/pages/help/faq/faq-accordion/FaqAccordion';
import PrivacyPolicy from 'components/pages/help/PrivacyPolicy/';
import TermsOfService from 'components/pages/help/TermsOfService';
import Changelog from 'components/pages/help/Changelog';
import ContactSupport from 'components/pages/help/Support';

// other
import UnderConstruction from 'components/errors/UnderConstruction';
import fourohfour from 'components/errors/Error404';
import InsufficientPermissions from 'components/errors/InsufficientPermissions';
import { hasEndpointPermission, hasPermission, hasPermissions } from 'context/AuthContext';
import Chat from 'components/pages/Operations/chat/Chat';

const MainRoutes = () => (
  <Switch>
    <Route path="/_d/" exact component={Dashboard} />

    {/* Operations */}
    <Route path="/_d/Operations/Booking/" component={hasEndpointPermission('/_d/Operations/Booking/') ? UnderConstruction : InsufficientPermissions} />
    <Route path="/_d/Operations/Scheduling/" component={hasEndpointPermission('/_d/Operations/Scheduling/') ? Scheduling : InsufficientPermissions} />
    <Route path="/_d/Operations/Orders/" component={hasEndpointPermission('/_d/Operations/Orders/') ? Orders : InsufficientPermissions} />
    <Route path="/_d/Operations/Chat/" component={Chat} />
    <Route path="/_d/Operations/Email/" component={EmailInbox} />

    {/* Accounting */}
    <Route path="/_d/Accounting/Dashboard/" component={hasEndpointPermission('/_d/Accounting/Dashboard/') ? AccountingDashboard : InsufficientPermissions} />
    <Route path="/_d/Accounting/Receivables/" component={hasEndpointPermission('/_d/Accounting/Receivables/') ? AccountsReceivable : InsufficientPermissions} />
    <Route path="/_d/Accounting/Payables/" component={hasEndpointPermission('/_d/Accounting/Payables/') ? AccountsPayable : InsufficientPermissions} />
    <Route path="/_d/Accounting/Expenses/" component={hasEndpointPermission('/_d/Accounting/Expenses/') ? AccountsExpenses : InsufficientPermissions} />

    {/* Safety */}
    <Route path="/_d/Safety/Incidents/" component={hasEndpointPermission('/_d/Safety/Incidents/') ? Incidents : InsufficientPermissions} />
    <Route path="/_d/Safety/Service/" exact component={hasEndpointPermission('/_d/Safety/Service/') ? Service : InsufficientPermissions} />
    <Route path="/_d/Safety/Service/:SERVICE_ID" component={hasEndpointPermission('/_d/Safety/Service/:SERVICE_ID') ? ServiceRecordView : InsufficientPermissions} />
    <Route path="/_d/Safety/Service/New" component={hasEndpointPermission('/_d/Safety/Service/New') ? ServiceRecordUpsert : InsufficientPermissions} />
    <Route path="/_d/Safety/Service/Edit/:SERVICE_ID" component={hasEndpointPermission('/_d/Safety/Service/Edit/:SERVICE_ID') ? ServiceRecordUpsert : InsufficientPermissions} />

    {/* Directory */}
    <Route path="/_d/Directory/Drivers/" component={hasEndpointPermission('/_d/Directory/Drivers/') ? Drivers : InsufficientPermissions} />
    <Route path="/_d/Directory/Equipment/" component={hasEndpointPermission('/_d/Directory/Equipment/') ? Equipment : InsufficientPermissions} />
    <Route path="/_d/Directory/Companies/" component={hasEndpointPermission('/_d/Directory/Companies/') ? Companies : InsufficientPermissions} />
    <Route path="/_d/Directory/Contacts/" component={hasEndpointPermission('/_d/Directory/Contacts/') ? Contacts : InsufficientPermissions} />
    <Route path="/_d/Directory/Places/" component={hasEndpointPermission('/_d/Directory/Places/') ? Places : InsufficientPermissions} />

    {/* More */}
    <Route path="/_d/Reports/" component={hasEndpointPermission('/_d/Reports/') ? UnderConstruction : InsufficientPermissions} />
    <Route path="/_d/Statistics/" component={hasEndpointPermission('/_d/Statistics/') ? UnderConstruction : InsufficientPermissions} />

    {/* Settings and Management */}
    <Route path="/_d/Settings/Company/" component={hasEndpointPermission('/_d/Settings/Company/') ? Settings : InsufficientPermissions} />
    <Route path="/_d/Users/" exact component={hasEndpointPermission('/_d/Users/') ? Users : InsufficientPermissions} />
    <Route path="/_d/Users/:USER_ID" exact component={hasEndpointPermission('/_d/Users/:USER_ID') ? UserProfile : InsufficientPermissions} />
    <Route path="/_d/MyProfile/" forceRefresh  ><UserProfile self={true} /></Route>
    <Route path="/_d/UserRoles/" exact component={hasEndpointPermission('/_d/UserRoles/') ? UserRoles : InsufficientPermissions} />
    <Route path="/_d/UserRoles/:ROLE_ID" component={hasPermissions(['MANAGE_ROLES', 'VIEW_ROLES']) ? Role : InsufficientPermissions} />

    <Route path="/_d/Billing/" component={hasEndpointPermission('/_d/Billing/') ? UnderConstruction : InsufficientPermissions} />

    {/* Help */}
    <Route path="/_d/Support/FAQ" exact component={FaqAccordion} />
    <Route path="/_d/Support/Contact" exact component={ContactSupport} />
    <Route path="/_d/Support/PrivacyPolicy" exact component={PrivacyPolicy} />
    <Route path="/_d/Support/TOS" exact component={TermsOfService} />
    <Route path="/_d/Support/Changelog/" exact component={Changelog} />

    {/* helper pages */}
    <Route path="/_d/errors/404" exact component={fourohfour} />


    <Redirect to="/_d/errors/404" />



  </Switch>
);

export default MainRoutes;
