import React, { useState, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card } from 'react-bootstrap';
import { faqAccordions as faqsData } from 'data/faqs';

const SMSTerms = () => {
    useEffect(() => {
        document.title = `SMS Terms`
    }, []);
    return <>
        <Card>
            <Card.Body>
                <p>
                    When you enroll in a Atlass' SMS Program, you will receive confirmation of your enrollment and you give your consent to Atlass LLC (including its affiliates)
                    and its service providers to use an automatic dialing system to deliver text messages to the mobile number to which you have directed Atlass to transmit these text messages.
                </p>
                <p>
                    You acknowledge this consent is not a condition of purchase or service.
                </p>
                <p>
                    You represent that you are the owner of the wireless device for which you have authorized enrollment in the Atlass SMS Program and are responsible for messages sent from the device.
                </p>
                <p>
                    You acknowledge that text communications are not secure or encrypted and third parties can sometimes intercept messages or disguise messages as
                    though they were sent by us. You agree that you will not text Atlass or its affiliates any confidential or sensitive information.
                </p>
                <p>
                    By continuing to use the Atlass SMS Program, you agree that we will not be liable for any unauthorized access or interception of text communications with us.
                </p>
                <p>
                    Message and data rates may apply from your mobile carrier and, by enrolling in the SMS Programs, you agree to pay any such charges.
                    Atlass does not charge for the use of its SMS Programs.
                </p>
                <p>
                    Message frequency will vary based on the SMS Program and user activity.
                </p>
                <p>
                    To stop receiving text messages, text STOP or CANCEL. You consent that following a request to STOP or CANCEL, you will receive one final message confirming that you have been unsubscribed. To re-enroll, reply IN and you will receive confirmation of your re-enrollment.
                </p>
                <p>
                    If you have any questions or need help, reply HELP to the number you received a text message from.
                </p>
                <p>
                    Unsolicited text messages sent to Atlass will not be monitored and you should contact us at <a href="mailto:admin@atlassapp.com">admin@atlassapp.com</a>.
                </p>
                <p>
                    You agree that you will promptly notify Atlass if your mobile phone number changes.
                </p>
                <p>
                    For information about Atlass' information practices, please review our <a href="https://atlass.app/privacy-policy">Privacy Policy</a>.
                </p>
                <p>
                    Atlass reserves the right to terminate any of the SMS Programs, in whole or in part, at any time without notice.
                </p>
                <p>
                    You agree that we will not be liable for any delays or failures in your receipt of any SMS messages as delivery can be unpredictable based on transmission from your
                    network operator and processing by your mobile device. SMS message services are provided on an AS IS, AS AVAILABLE basis.
                </p>
                <p>
                    We may send MMS (Multimedia Messaging Service) messages, such as, but not limited to .jpgs, .png, .jpeg, .bmp, .txt, .doc, .docx, Atlass employee vCards, etc. If your device does not support such files,
                    it is your responsibility to request the file be sent through different means (e.g. e-mail or mail).
                </p>
            </Card.Body>
        </Card>
    </>
}
export default SMSTerms