import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    HttpLink,
    from
} from "@apollo/client";
import { onError } from '@apollo/client/link/error'
import { graphql } from "graphql";

const errorLink = onError(
    ({
        graphqlErrors,
        networkError
    }) => {
        if (graphqlErrors) {
            graphqlErrors.map(({ message, location, path }) => {
                console.log(`GQL error: ${message}`)
            })
        }
    })
const link = from([
    errorLink,
    new HttpLink({
        uri: `http://localhost:8080/graphql/service`
    })
])

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link
})
