import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import { EINInput, MCInput, USDOTInput, USZIPInput } from 'components/common/MaskedInputs'
import { AtlassStateSelect_NA } from "components/common/AtlassSelect";
import Flex from "components/common/Flex";
import { toast } from 'react-toastify';
import APICommunicator from 'api/APICommunicator'


const GeneralInformationForm = ({ data, successcallback, ...rest }) => {
    const API = new APICommunicator('Settings/Company');
    const [companyInformation, setCompanyInformation] = useState(false)
    const [submitData, setSubmitData] = useState(false)

    const handleSubmit = e => {
        e.preventDefault();

        if (!companyInformation) return toast.error('Company information not set properly')

        const submitArray = Object.entries(companyInformation).map((item, value) => ({
            type: 'general',
            component: item[0],
            value: item[1]
        }))

        API.PUT('/', submitArray)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                if (typeof successcallback === 'function') successcallback(Response.content)
                toast.success('Company information saved!')
            })
            .catch(handleErrorWithToast)
    }

    const handleFieldChange = (e, name) => {
        if (e && e.target) return setCompanyInformation({ ...companyInformation, [e.target.name]: e.target.value })
        if (e && name) return setCompanyInformation({ ...companyInformation, [name]: e })
    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response))

    useEffect(() => {
        if (data && data.general) {
            const { general } = data;
            setCompanyInformation(general)
        }
    }, [data])


    useEffect(() => {
        // console.log(companyInformation)
        var submitArray = [];
        if (companyInformation && typeof companyInformation === 'object') {
            submitArray = Object.entries(companyInformation).map((item, value) => ({
                type: 'general',
                component: item[0],
                value: item[1]
            }))
        }
    }, [companyInformation])

    useEffect(() => {
        // console.log(submitData)
        // console.log(submitData.map(item => ({ ...item, 'type': 'general' })))
    }, [submitData])

    return (<>
        <Card  {...rest}>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    {/* name */}
                    <Form.Group>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" name="company_name" placeholder="Required *" onChange={handleFieldChange} value={companyInformation.company_name ?? ""} />
                    </Form.Group>

                    {/* EIN */}
                    <Form.Group>
                        <Form.Label>EIN</Form.Label>
                        <EINInput name="company_ein" placeholder="Required * [XX-XXXXXXX]" onChange={handleFieldChange} value={companyInformation.company_ein ?? ""} />
                    </Form.Group>

                    <div className="border-dashed-bottom my-3"></div>

                    {/* MC */}
                    <Form.Group>
                        <Form.Label>MC</Form.Label>
                        <MCInput name="company_mc" placeholder="" value={companyInformation.company_mc ?? ""} onChange={handleFieldChange} />
                    </Form.Group>

                    {/* USDOT */}
                    <Form.Group>
                        <Form.Label>USDOT</Form.Label>
                        <USDOTInput name="company_usdot" placeholder="" value={companyInformation.company_usdot ?? ""} onChange={handleFieldChange} />
                    </Form.Group>

                    {/* WEBSITE */}
                    <Form.Group>
                        <Form.Label>Website</Form.Label>
                        <Form.Control name="company_website" placeholder="" value={companyInformation.company_website ?? ""} onChange={handleFieldChange} />
                    </Form.Group>

                    <div className="border-dashed-bottom my-3"></div>


                    <Form.Group>
                        <Form.Label>Primary Contact Email</Form.Label>
                        <Form.Control type="email" name="company_email" value={companyInformation.company_email ?? ""} onChange={handleFieldChange} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Autocopy Email</Form.Label>
                        <Form.Control type="email" name="company_autobcc" value={companyInformation.company_autobcc ?? ""} onChange={handleFieldChange} />
                    </Form.Group>


                    <div className="border-dashed-bottom my-3"></div>

                    {/* ADDRESS */}
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control name="company_addr1" placeholder="Required *" value={companyInformation.company_addr1 ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address Line 2 </Form.Label>
                        <Form.Control name="company_addr2" value={companyInformation.company_addr2 ?? ""} onChange={handleFieldChange} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>City </Form.Label>
                            <Form.Control name="company_city" placeholder="Required *" value={companyInformation.company_city ?? ""} onChange={handleFieldChange} />
                        </Col>
                        <Col>
                            <Form.Label>State</Form.Label>
                            <AtlassStateSelect_NA name="company_state" placeholder="Required *" value={companyInformation.company_state ?? ""} onChange={v => { handleFieldChange(v, 'company_state') }} />
                        </Col>
                        <Col>
                            <Form.Label>ZIP</Form.Label>
                            <USZIPInput name="company_zip" placeholder="Required *" value={companyInformation.company_zip ?? ""} onChange={handleFieldChange} />
                        </Col>
                    </Row>

                    <Flex justifyContent="end" className="mt-3">
                        <Button type="submit">
                            Save
                        </Button>
                    </Flex>
                </Form>

            </Card.Body>
        </Card>
    </>)
}

export default GeneralInformationForm