import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { SearchProvider } from 'context/SearchContext';
import { SocketProvider } from 'context/SocketContext';



const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <SearchProvider>
      <Switch>
        <Route component={MainLayout} />
        <Redirect to="/errors/404" />
      </Switch>
      {/* <SettingsToggle />
      <SettingsPanel /> */}
      <ToastContainer
        autoClose={5000}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
    </SearchProvider>
  );
};

export default Layout;
