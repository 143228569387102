import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { ActionMeta, OnChangeValue } from 'react-select';
import { Link } from "react-router-dom";
import Flex from "components/common/Flex";
import { OrderNumberStructure } from "data/autocomplete/NumberFormattingPresets";
import { OrderAlerts } from "data/autocomplete/AlertSelects";
import { selectDropdownStyle } from "helpers/utils";
import APICommunicator from "api/APICommunicator";
import { toast } from "react-toastify";


const OperationsSettings = ({ data, successcallback, ...rest }) => {
    const API = new APICommunicator('Settings/Company');

    const [formData, setFormData] = useState({})

    const [orderStructureOptions, setOrderStructureOptions] = useState([])
    const [orderNumberStructure, setOrderNumberStructure] = useState([]);
    const [loadNumberExampleOutput, setLoadNumberExampleOutput] = useState(false);

    const [alertListOptions, setAlertListOptions] = useState([]);
    const [alertList, setAlertList] = useState([]);

    const tempUnitsList = [{
        value: 'F',
        label: 'Fahrenheit'
    }, {
        value: 'C',
        label: 'Celsius'
    }]
    const [tempUnits, setTempUnits] = useState(false)

    const timeFormatList = [{
        value: '24',
        label: '24:00'
    }, {
        value: '12',
        label: '12:00'
    }]
    const [timeFormat, setTimeFormat] = useState(false)



    const handleSubmit = e => {
        e.preventDefault();

        if (!formData) return toast.error('Operations information not set properly')

        const submitArray = Object.entries(formData).map((item, value) => ({
            type: 'operations',
            component: item[0],
            value: item[1]
        }))

        API.PUT('/', submitArray)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                if (typeof successcallback === 'function') successcallback(Response.content)
                toast.success('Operations settings saved!')
            })
            .catch(handleErrorWithToast)
    }

    const handleOrderStructureChange = TAGS => {
        const SelectedOptions = TAGS.map(TAG => TAG.value).join(',')

        setFormData({
            ...formData,
            'order_number_structure': SelectedOptions
        })
        var Output = '';

        for (const Tag of TAGS) {
            const EXISTS = OrderNumberStructure.find(x => x.value === Tag.value)
            Output += `${(EXISTS) ? EXISTS.example : Tag.value}`
        }

        setOrderNumberStructure(TAGS) // set field value
        setLoadNumberExampleOutput(Output) // display example to user
        setOrderStructureOptions(OrderNumberStructure.filter(ar => !TAGS.find(rm => (rm.value === ar.value)))) // remove selected options from list
    }

    const handleAlertSelectChange = TAGS => {
        const SelectedOptions = TAGS.map(TAG => TAG.value).join(',')

        setFormData({
            ...formData,
            'order_warnings': SelectedOptions
        })
        setAlertList(TAGS) // set field value=
        setAlertListOptions(OrderAlerts.filter(ar => !TAGS.find(rm => (rm.value === ar.value)))) // remove selected options from list
    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {
        setOrderStructureOptions(OrderNumberStructure.map(Option => ({
            value: Option.value,
            label: Option.label
        })))
        setAlertListOptions(OrderAlerts)
    }, [])

    useEffect(() => {
        if (data && data.operations) {
            const { operations } = data;
            setFormData(operations)

            // Order Number Structure
            const selectedOrderStructureTags = operations.order_number_structure.split(',').map(v => ({
                value: v,
                label: OrderNumberStructure.find(x => x.value === v) ? OrderNumberStructure.find(x => x.value === v).label : v,
            }))
            setOrderNumberStructure(selectedOrderStructureTags)
            setOrderStructureOptions(OrderNumberStructure.filter(ar => !selectedOrderStructureTags.find(rm => (rm.value === ar.value))))

            // Order Alert Display
            const selectedAlertTags = operations.order_warnings.split(',').map(v => ({
                value: v,
                label: OrderAlerts.find(x => x.value === v.replace(/ /g, '')) ? OrderAlerts.find(x => x.value === v.replace(/ /g, '')).label : v.replace(/ /g, ''),
            }))
            setAlertList(selectedAlertTags)
            setAlertListOptions(OrderAlerts.filter(ar => !selectedAlertTags.find(rm => (rm.value.replace(/ /g, '') === ar.value.replace(/ /g, '')))))

            // Temperature Units
            setTempUnits({
                value: operations.orders_temp_units,
                label: tempUnitsList.find(x => x.label == operations.orders_temp_units || x.value == operations.orders_temp_units).label
            })

            // Time Format
            setTimeFormat({
                value: operations.orders_time_format,
                label: timeFormatList.find(x => x.label == operations.orders_time_format || x.value == operations.orders_time_format).label
            })

        }
    }, [data])

    // useEffect(() => { console.log(formData) }, [formData])

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response))


    return (<>
        <Card  {...rest}>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    {/* LoadNumberFormat */}
                    <Form.Group >

                        <Alert variant="warning">
                            <Form.Label as="div" className="fw-bold">Load Numbering Format</Form.Label>
                            This is a dangerous area! Please refer to <Link to="/Support/Knowledgebase#HowToSetLoadNumberingFormat">this article</Link> before making a change or <Link to="/Support/Contact">contact support</Link> for help
                            <CreatableSelect
                                placeholder='Type text and press enter and/or select from the list'
                                name="order_number_structure"
                                className="mt-1"
                                classNamePrefix="react-select"
                                options={orderStructureOptions}
                                onChange={handleOrderStructureChange}
                                isClearable
                                isMulti
                                value={orderNumberStructure}
                            />
                            {loadNumberExampleOutput &&
                                <Alert as="div" variant="info" className="mt-3">
                                    Output format example:<br />
                                    <code>{loadNumberExampleOutput}</code>
                                </Alert>
                            }
                        </Alert>

                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Alerts to display when entering a new order</Form.Label>
                        <ReactSelect
                            classNamePrefix="react-select"
                            name="order_warnings"
                            options={alertListOptions}
                            onChange={handleAlertSelectChange}
                            value={alertList}
                            isClearable
                            isMulti
                            placeholder='Select alerts to display'
                        />
                    </Form.Group>
                    {/* Alert warning days */}
                    <Form.Group as={Row} className="mb-3 mt-3" >
                        <Form.Label column sm="6">
                            Alert warning advance in days
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                min="1"
                                max="30"
                                type="number"
                                name="order_days_warning"
                                onChange={handleFieldChange}
                                value={formData?.order_days_warning ?? ""}
                            />
                        </Col>
                    </Form.Group>
                    {/* Temperature Units */}
                    <Form.Group as={Row} className="mb-3 mt-3" >
                        <Form.Label column sm="6">
                            Temperature Units
                        </Form.Label>
                        <Col sm="6">
                            <ReactSelect
                                name="orders_temp_units"
                                classNamePrefix="react-select"
                                styles={selectDropdownStyle}
                                onChange={setTempUnits}
                                options={tempUnitsList}
                                value={tempUnits}

                            />
                        </Col>
                    </Form.Group>
                    {/* Clock Format */}
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="6">
                            Time Format
                        </Form.Label>
                        <Col sm="6">
                            <ReactSelect
                                name="orders_time_format"
                                classNamePrefix="react-select"
                                styles={selectDropdownStyle}
                                onChange={setTimeFormat}
                                options={timeFormatList}
                                value={timeFormat}
                            />
                        </Col>
                    </Form.Group>

                    <Flex justifyContent="end" className="mt-3" >
                        <Button
                            type="submit"
                            disabled={
                                !formData ||
                                !formData.orders_temp_units ||
                                !formData.orders_time_format ||
                                !formData.order_number_structure
                            }
                        >
                            Save
                        </Button>
                    </Flex>
                </Form>
            </Card.Body>
        </Card>
    </>)
}

export default OperationsSettings