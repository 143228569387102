import { faArrowDown, faFile } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";



const FileTag = ({ file }) => {

    return <div className="border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs--1 mb-2">
        <FontAwesomeIcon icon={faFile} className="svg-inline--fa fa-image fa-w-16 fs-1" />
        <a href="#!" className="ms-2 me-3">
            <b>{file.name}.{file.ext}</b>
            <span className="ms-2">({file.size})</span>
        </a>
        <a href="#!" className="text-300 ms-auto">
            <FontAwesomeIcon icon={faArrowDown} className="svg-inline--fa fa-image fa-w-16 fs-1" />
        </a>
    </div>
}

export default FileTag