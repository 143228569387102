
import React, { useEffect, useState } from "react";
import { Alert, CloseButton } from "react-bootstrap";
import { faCheckCircle, faExclamationCircle, faTimesCircle, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie'


const MemoryDismissibleAlert = ({
    UniqueMemoryName,
    variant,
    children,
    className,
    onClose,
    Icon,
    IconName,
    ...rest
}) => {

    const [showTooltip, setShowTooltip] = useState(false)

    const icon = {
        'success': faCheckCircle,
        'info': faInfoCircle,
        'warning': faExclamationCircle,
        'danger': faTimesCircle,
    }

    const handleTooltipClose = e => {
        Cookies.set(UniqueMemoryName, false, {
            sameSite: 'strict'
        })
        setShowTooltip(false)
        if (typeof onClose === 'function') onClose()
    }

    useEffect(() => {
        const hasCookie = Cookies.get(UniqueMemoryName)
        if (hasCookie === undefined) {
            Cookies.set(UniqueMemoryName, true, {
                sameSite: 'strict'
            })
            setShowTooltip(true)
            return
        }
        setShowTooltip(hasCookie === 'true')
    }, [])

    return <>
        {showTooltip && <Alert
            variant={variant}
            className={classNames(className, 'd-flex align-items-center')}
            {...rest}
        >
            {!Icon ? <div className={`bg-${variant} me-2 icon-item`}>
                <FontAwesomeIcon icon={IconName || icon[variant]} className="text-white fs-3" />
            </div> : Icon}

            <div className="flex-1 me-3">{children}</div>

            <CloseButton
                className="p-2 position-absolute end-0 me-2"
                onClick={handleTooltipClose} />
        </Alert>}
    </>
}

MemoryDismissibleAlert.propTypes = {
    variant: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func
}

export default MemoryDismissibleAlert