import React, { useContext, useState, useEffect } from 'react'
import { io, Manager } from 'socket.io-client';
import { getUserData, isAuthenticated, useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import { debounce } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import { Logout } from "context/AuthContext"
import Flex from 'components/common/Flex';
import { useHistory } from 'react-router-dom';

const UserData = isAuthenticated() ? JSON.parse(getUserData()) : false
const namespaces = {
    general: 'general',
    orders: 'orders',
    email: 'email',
    chat: 'chat',

}
var token = UserData?.service_unique_id
// https://atlass-project.ey.r.appspot.com/
// const serverURL = 'localhost:8080'
const serverURL = false
// const serverURL = 'https://atlass-project.ey.r.appspot.com'
const config = {
    // port: 8080, // only for localhost
    auth: {
        token: token
    },
    // transports: ['websocket']
}

const initManager = UserData ? new Manager(serverURL, config) : false

export const SocketContext = React.createContext();

export function useSockets() {
    return useContext(SocketContext)
}

export const SocketProvider = ({
    children
}) => {

    const history = useHistory();
    const [newLogin, setNewLogin] = useState(false)
    const [online, setNetwork] = useState(window.navigator.onLine)
    const [error, setError] = useState(false)
    const [pong, setPong] = useState('');
    const [USER, setUser] = useState(UserData);
    const [connected, setConnected] = useState(initManager?.connected);
    const [manager, setManager] = useState(initManager)
    const [generalSocket, setGeneralSocket] = useState(initManager ? initManager.socket(`/${UserData?.service_unique_id}/${namespaces.general}`, config) : false)
    const [chatSocket, setChatSocket] = useState(initManager ? initManager.socket(`/${UserData?.service_unique_id}/${namespaces.chat}`, config) : false)

    const updateNetwork = () => {
        setNetwork(window.navigator.onLine);
    };

    const init = (manager, user) => {
        if (!manager || !user) return
        const cfg = {
            auth: {
                token: user.service_unique_id
            }
        }
        const gen = manager.socket(`/${user.service_unique_id}/${namespaces.general}`, cfg)
        const chat = manager.socket(`/${user.service_unique_id}/${namespaces.chat}`, cfg)
        setGeneralSocket(gen)
        setChatSocket(chat)

        if (!connected) manager.connect()

        manager.on("connect_error", err => {
            console.log(err.message)
            setError(online ? err.message : `You're offline`)
        })
        initGeneral(generalSocket)
        initGeneral(chatSocket)
    }


    const initGeneral = (generalSocket) => {

        if (!generalSocket) return

        generalSocket.on('pong', data => {
            setError(false)
            setPong(data)
        })

        generalSocket.on('user_is_online', newUser => {
            debounce(() => {
                if (UserData && newUser && UserData.service_unique_id === newUser.service_unique_id) {
                    console.log('UserData', UserData.service_unique_id)
                    console.log('newUser', newUser.service_unique_id)
                    console.warn('DUPLICATE LOGIN')
                }
                toast(<Flex alignItems="start">
                    <Avatar
                        size="2xl"
                        className="mb-0"
                        src={newUser.profile_picture && newUser.profile_picture}
                        name={(newUser.firstname && newUser.lastname) && `${newUser.firstname} ${newUser.lastname}`}
                        mediaClass="shadow-sm"
                        width={100}
                    />
                    <div className="ms-2">
                        <b>{newUser.firstname} {newUser.lastname}</b><br />
                        <span className="text-400">is now online</span>
                    </div>
                </Flex>, {
                    onClick: () => { history.push(`/Users/${newUser.uuid}`) }
                })
            })()
        })

        generalSocket.on("connect_error", err => {
            var error_message = false
            console.log(err.message)
            switch (err.message) {
                case 'xhr poll error':
                    error_message = 'Server offline'
                    break;
                default:
                    break;

            }
            setError(error_message ? error_message : `You're offline`)
        })
        generalSocket.emit('ping')
    }

    const initChat = (chatSocket) => {

    }




    const loginConnect = (User, cb = () => { }) => {
        console.log('service_unique_id', User.service_unique_id)
        return new Promise((resolve, reject) => {
            setManager(new Manager(serverURL, {
                port: 8080,
                auth: {
                    token: User.service_unique_id
                }
            }))
            setUser(User)
            setNewLogin(User)

            setTimeout(() => {
                resolve()
            }, 200)
        })
    }

    useEffect(() => {
        if (newLogin && generalSocket && USER) {
            generalSocket.emit("i_am_online", USER)
            setNewLogin(false)
        }
    }, [newLogin, generalSocket])

    useEffect(() => {
        // initGeneral(generalSocket)
    }, [generalSocket])

    useEffect(() => {
        if (!USER) return
        // if (manager && USER) init(manager, USER)
    }, [manager, USER])

    useEffect(() => {
        window.addEventListener("offline", updateNetwork);
        window.addEventListener("online", updateNetwork);

        return () => {
            window.removeEventListener("offline", updateNetwork);
            window.removeEventListener("online", updateNetwork);
        };
    });

    const value = {
        online,
        connected,
        generalSocket,
        loginConnect,
        chatSocket,
        error,
        pong
    }

    return <SocketContext.Provider value={value}>
        {children}
    </SocketContext.Provider>
}