import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, Alert, InputGroup, Placeholder, Card } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import { faLoveseat } from '@fortawesome/pro-solid-svg-icons';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import Permissions from 'data/important/permissions'

const ChangeRoleModal = ({ show, handleClose, successCallback, USER }) => {


    const API = new APICommunicator('Users');
    const ROLES_API = new APICommunicator('UserRoles');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [currentRole, setCurrentRole] = useState(false)
    const [ROLES, setROLES] = useState([])


    const handleSubmit = e => {
        e.preventDefault()
        setError(false)

        API.PUT(`/${USER.uuid}`, {
            service_unique_id: USER.service_unique_id,
            role: currentRole.value
        })
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleErrorWithToast)
    }

    const getRoles = () => {
        setLoading(true)
        ROLES_API.GET('/')
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleError(Response);
                setROLES(Response.content.map(ROLE => ({
                    value: ROLE.id,
                    label: renderRoleReactSelect(ROLE)
                })))
            })
            .catch(handleError)
    }

    const renderRoleReactSelect = ROLE => {
        return <div className='text-dark'><b>{ROLE.name}</b><div className='text-muted fs--1'>{ROLE.description}</div></div>
    }
    const handleError = Response => { setLoading(false); setError(API.handleResponseErrors(Response)); }
    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })


    useEffect(() => {
        if (show) getRoles()
    }, [show]);

    useEffect(() => {
        if (USER && ROLES) setCurrentRole(ROLES.find(role => role.value == USER.role))
    }, [USER, ROLES]);


    return (

        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Change user role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading ?
                    <>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    options={ROLES}
                                    placeholder="Select a role"
                                    value={currentRole}
                                    onChange={v => setCurrentRole(v)}
                                    classNamePrefix="react-select"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}>
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="ms-4"
                                    variant="primary"
                                    disabled={!currentRole}>
                                    Submit</Button>
                            </Form.Group>
                        </Form>
                    </>
                    :
                    <Placeholder as={Card.Text} animation="glow"><Placeholder xs={12} /></Placeholder>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ChangeRoleModal