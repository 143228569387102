import { faTrailer, faTruck, faTruckContainerEmpty, faTruckField, faTruckFront, faTruckMoving, faTruckPickup, faTruckPlow, faTruckTow, faVanShuttle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "components/common/SoftBadge";
import React, { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

export const VEHICLE_TYPE_PROPERTIES = {
    SEMI_TRUCK: {
        text: 'Semi Truck',
        icon: <FontAwesomeIcon icon={faTruckContainerEmpty} />
    },
    BOX_TRUCK: {
        text: 'Box Truck',
        icon: <FontAwesomeIcon icon={faTruckMoving} />
    },
    PICKUP_TRUCK: {
        text: 'Pickup Truck',
        icon: <FontAwesomeIcon icon={faTruckPickup} />
    },
    TOW_TRUCK: {
        text: 'Tow Truck',
        icon: <FontAwesomeIcon icon={faTruckTow} />
    },
    STEP_VAN: {
        text: 'Step Van',
        icon: <FontAwesomeIcon icon={faTruckField} />
    },
    CARGO_VAN: {
        text: 'Cargo Van',
        icon: <FontAwesomeIcon icon={faTruck} />
    },
    PASSENGER_VAN: {
        text: 'Passenger Van',
        icon: <FontAwesomeIcon icon={faVanShuttle} />
    },
    SPECIALTY: {
        text: 'Specialty Vehicle',
        icon: <FontAwesomeIcon icon={faTruckPlow} />
    },
    SEMI_TRAILER: {
        text: 'Semi Trailer',
        icon: <FontAwesomeIcon icon={faTrailer} />
    },
}

export const VehicleTextAndIconOnly = ({ Vehicle }) => {
    const VEHICLE_PROPERTIES = VEHICLE_TYPE_PROPERTIES[Vehicle.type]
    return <span className="d-inline-block">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{VEHICLE_PROPERTIES.text}</Tooltip>}>
            <span className="d-flex justify-content-between gap-1">
                {VEHICLE_PROPERTIES.icon}
                <span>{Vehicle.vehicle_id}</span>
            </span>
        </OverlayTrigger>
    </span>
}

export const VehicleShortTag = ({ Vehicle }) => {
    const VEHICLE_PROPERTIES = VEHICLE_TYPE_PROPERTIES[Vehicle.type]
    return (
        <Badge pill bg="light" className="me-1">
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{VEHICLE_PROPERTIES.text}</Tooltip>}>
                <h6 className="mb-0 fw-normal">
                    <Link to="#" className="fw-bold d-flex align-items-center gap-1">
                        {VEHICLE_PROPERTIES.icon}
                        <span>{Vehicle.vehicle_id}</span>
                    </Link>
                </h6>
            </OverlayTrigger>
        </Badge>
    )
}
