
import { faBell, faBug, faDigging, faEnvelopeOpenText, faMagic, faThList } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React from 'react'
import { ListGroup } from 'react-bootstrap';

const ChangelogList = () => [
    {
        "changelog_title": "From the ground up!",
        "changelog_number": "2.0000",
        "changelog_short": <>
            Intro: At <b>A.T.L.A.S.S. </b>we started as simple dispatchers with affinity for technology and automation. We wanted to create a tool to help people bring the trucking industry in the 21st century. Success, however is never a straight path. It involves a lot of trial and error. That's why when we saw that the technologies we were using were limiting us from making a product worthy of the 21st century, we had to redo everything again. We proudly present to you fully cloud-native modern-age Atlass 2.0 meant to tackle complex problems like complete automation, machine learning predictions and everything you never imagined a software can do for you.
        </>,
        "changelog_long": "",
        "date_added": "0000-00-00 00:00:00"
    },
    {
        "changelog_title": "Happy Holidays!",
        "changelog_number": "1.0079",
        "changelog_short": <>
            We wish you a happy, productive and stress-free holidays this season! May you be healthy and merry, your wheels rolling and the dollars falling,
            drivers be happy and dispatchers snappy, customers satisfied and luck on your side. Happy holidays!
        </>,
        "changelog_long": <>
            <ListGroup>
                <ListGroup.Item className='p-3 p-sm-4'>
                    <Flex justifyContent="between">
                        <h5 className="mb-1">Notifications</h5>
                        <FontAwesomeIcon icon={faBell} size="2x" />
                    </Flex>
                    <p className="mb-1">
                        New Notifications system has been deployed to help you focus on what's going on with stuff you need to pay attention to.
                    </p>
                </ListGroup.Item>
                <ListGroup.Item className='p-3 p-sm-4'>
                    <Flex justifyContent="between">
                        <h5 className="mb-1">Preparations</h5>
                        <FontAwesomeIcon icon={faDigging} size="2x" />
                    </Flex>
                    <p className="mb-1">
                        A lot of work went into preparing Atlass for something big that will be coming with the new year.. stay tuned
                    </p>
                </ListGroup.Item>
                <ListGroup.Item className='p-3 p-sm-4'>
                    <Flex justifyContent="between">
                        <h5 className="mb-1">Cocroaches</h5>
                        <FontAwesomeIcon icon={faBug} size="2x" />
                    </Flex>
                    <p className="mb-1">
                        Seems like the more we get rid of the more show up.. Seems like we'll need to call pest control in this place soon!
                    </p>
                </ListGroup.Item>
            </ListGroup>
        </>,
        "date_added": "2019-12-15 00:00:00"
    },
    {
        "changelog_title": "Performance focus",
        "changelog_number": "1.0076",
        "changelog_short": <>
            We've been working on a new way to deliver content to your computer, with the goal being to make the system lighter and faster. In some places there were speed improvements greater than 50%, which will greatly impact performance on older machines.
        </>,
        "changelog_long": <>
            <ListGroup>
                <ListGroup.Item className='p-3 p-sm-4'>
                    <Flex justifyContent="between">
                        <h5 className="mb-1">Orders Improvements</h5>
                        <FontAwesomeIcon icon={faMagic} size="2x" />
                    </Flex>
                    <p className="mb-1">
                        Along with the speed improvements, we've delivered a new design for the orders, that features all of the known elements with slight changes and some new components.
                        Now you can click-to-copy assignments so they can be sent to your Customer or Broker easily.
                        Motion Statuses now have more defined and easy view with an easy click-to-change action.
                        All activity and attention-demanding components are now centralized for maximum attention.
                        Those components now include 2 new features <span className="badge badge-default">Changelog</span> and <span className="badge bg-purple">Quick Dispatch</span>
                    </p>
                </ListGroup.Item>
                <ListGroup.Item className='p-3 p-sm-4'>
                    <Flex justifyContent="between">
                        <h5 className="mb-1">Email your lists!</h5>
                        <FontAwesomeIcon icon={faEnvelopeOpenText} size="2x" />
                    </Flex>
                    <p className="mb-1">
                        The <b>Available Equipment</b> list now features a button, that will transform your list in a ready-to-email and is just one click away from your contacts!
                        You can also use <a href="" target="_blank">Groups</a> to create mailing lists of your preference. Add as many contacts to a group as you like and add as many groups to your email as you like.
                        Remember! Never send out mass emails with recipients listed in the <b className="text-danger">Send to</b> or <b className="text-danger">CC</b> fields. This will create chat groups and every response will be visible by every recipient.
                        For mass emails, always make sure you use the <b className="text-success">BCC</b> field.
                    </p>
                </ListGroup.Item>
            </ListGroup>
        </>,
        "date_added": "2019-11-17 00:00:00"
    }
];

export default ChangelogList