export const OrderNumberStructure = [
    {
        value: '{ID}',
        label: 'Next sequential order number',
        example: '123456789'
    }, {
        value: '{RID}',
        label: 'Random order number',
        example: '8784987523'
    }, {
        value: '{RELID-C}',
        label: 'Next sequential order number for selected customer',
        example: '1234'
    }, {
        value: '{RELID-CY}',
        label: 'Next sequential order number for selected customer for this year',
        example: '1234'
    }, {
        value: '{RELID-V}',
        label: 'Next sequential order number for selected carrier ',
        example: '1234'
    }, {
        value: '{RELID-VY}',
        label: 'Next sequential order number for selected carrier for this year ',
        example: '1234'
    }, {
        value: '{RELID-T}',
        label: 'Next sequential order number for selected truck ',
        example: '1234'
    }, {
        value: '{RELID-TY}',
        label: 'Next sequential order number for selected truck for this year ',
        example: '1234'
    }, {
        value: '{RELID-TR}',
        label: 'Next sequential order number for selected trailer ',
        example: '1234'
    }, {
        value: '{RELID-TRY}',
        label: 'Next sequential order number for selected trailer ',
        example: '1234'
    }, {
        value: '{RELID-D}',
        label: 'Next sequential order number for selected driver ',
        example: '1234'
    }, {
        value: '{RELID-DY}',
        label: 'Next sequential order number for selected driver for this year ',
        example: '1234'
    }, {
        value: '{RELID-DI}',
        label: 'Next sequential order number for selected dispatcher ',
        example: '1234'
    }, {
        value: '{RELID-DIY}',
        label: 'Next sequential order number for selected dispatcher for this year ',
        example: '1234'
    }, {
        value: '{RELID-CR}',
        label: 'Next sequential order number for selected customer rep ',
        example: '1234'
    }, {
        value: '{RELID-CRY}',
        label: 'Next sequential order number for selected customer rep for this year ',
        example: '1234'
    }, {
        value: '{ID-C}',
        label: 'System ID for selected customer ',
        example: '21'
    }, {
        value: '{ID-V}',
        label: 'System ID for selected carrier ',
        example: '21'
    }, {
        value: '{ID-T}',
        label: 'System ID for selected truck ',
        example: '21'
    }, {
        value: '{ID-TR}',
        label: 'System ID for selected trailer ',
        example: '21'
    }, {
        value: '{ID-D}',
        label: 'System ID for selected driver ',
        example: '21'
    }, {
        value: '{ID-DI}',
        label: 'System ID for selected dispatcher ',
        example: '21'
    }, {
        value: '{ID-CR}',
        label: 'System ID for selected customer rep ',
        example: '21'
    }, {
        value: '{DATE-I}',
        label: 'Date when order was input',
        example: '20220621'
    }, {
        value: '{DATE-P}',
        label: 'Date of order\'s first pickup',
        example: '20220621'
    }, {
        value: '{DATE-D}',
        label: 'Date of order\'s last delivery',
        example: '20220621'
    }, {
        value: '{DATETIME}',
        label: 'Datestamp when this order was entered',
        example: '202206211451'
    }, {
        value: '{NS}',
        label: 'Number of stops for this order ',
        example: '7'
    }, {
        value: '{STATE-P}',
        label: 'State abbreviation where order was picked up',
        example: 'CO'
    }, {
        value: '{STATE-D}',
        label: 'State abbreviation where order was delivered',
        example: 'PA'
    }];



export const StatementNumberStructure = [{
    value: '{ID}',
    label: 'Next sequential statement number ',
    example: '1234'
}, {
    value: '{RELID-C}',
    label: 'Next sequential statement number for selected customer ',
    example: '1234'
}, {
    value: '{RELID-CY}',
    label: 'Next sequential statement number for selected customer for this year (Date Created) ',
    example: '1234'
}, {
    value: '{RELID-CYS}',
    label: 'Next sequential statement number for selected customer for the year from Statement Date ',
    example: '1234'
}, {
    value: '{RELID-CYD}',
    label: 'Next sequential statement number for selected customer for the year from Due Date ',
    example: '1234'
}, {
    value: '{RELID-AY-TRUCK}',
    label: 'Next sequential statement number for found Trucks in item associations for this year (Date Created) ',
    example: '1234'
}, {
    value: '{RELID-AYS-TRUCK}',
    label: 'Next sequential statement number for found Trucks in item associations for the year from Statement Date',
    example: '1234'
}, {
    value: '{RELID-AYD-TRUCK}',
    label: 'Next sequential statement number for found Trucks in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-TRAILER}',
    label: 'Next sequential statement number for found Trailers in item associations for this year (Date Created) ',
    example: '1234'
}, {
    value: '{RELID-AYS-TRAILER}',
    label: 'Next sequential statement number for found Trailers in item associations for the year from Statement Date',
    example: '1234'
}, {
    value: '{RELID-AYD-TRAILER}',
    label: 'Next sequential statement number for found Trailers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for this year (Date Created) ',
    example: '1234'
}, {
    value: '{RELID-AYS-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for the year from Statement Date',
    example: '1234'
}, {
    value: '{RELID-AYD-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for this year (Date Created) ',
    example: '1234'
}, {
    value: '{RELID-AYS-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for the year from Statement Date',
    example: '1234'
}, {
    value: '{RELID-AYD-DRIVER}',
    label: 'Next sequential statement number for found Drivers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{DATE-C}',
    label: 'Date when statement was create ',
    example: '20220621'
}, {
    value: '{DATE}',
    label: 'Date statement ',
    example: '20220621'
}, {
    value: '{DATE-D}',
    label: 'Date due ',
    example: '20220621'
}, {
    value: '{YEAR-C}',
    label: 'Year when statement was create (ex. YYYY)',
    example: '2022'
}, {
    value: '{YEAR}',
    label: 'Year statement (ex. YYYY)',
    example: '2022'
}, {
    value: '{YEAR-D}',
    label: 'Year due (ex. YYYY)',
    example: '2022'
}];

export const InvoiceNumberStructure = [{
    value: '{ID}',
    label: 'Next sequential invoice number',
    example: '1234'
}, {
    value: '{RELID-C}',
    label: 'Next sequential invoice number for selected customer',
    example: '1234'
}, {
    value: '{RELID-CY}',
    label: 'Next sequential invoice number for selected customer for this year',
    example: '1234'
}, {
    value: '{RELID-CYS}',
    label: 'Next sequential invoice number for selected customer for the year from Invoice Date',
    example: '1234'
}, {
    value: '{RELID-CYD}',
    label: 'Next sequential invoice number for selected customer for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-TRUCK}',
    label: 'Next sequential invoice number for found Trucks in item associations for this year (Date Created)',
    example: '1234'
}, {
    value: '{RELID-AYS-TRUCK}',
    label: 'Next sequential invoice number for found Trucks in item associations for the year from Invoice Date',
    example: '1234'
}, {
    value: '{RELID-AYD-TRUCK}',
    label: 'Next sequential invoice number for found Trucks in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-TRAILER}',
    label: 'Next sequential invoice number for found Trailers in item associations for this year (Date Created)',
    example: '1234'
}, {
    value: '{RELID-AYS-TRAILER}',
    label: 'Next sequential invoice number for found Trailers in item associations for the year from Invoice Date',
    example: '1234'
}, {
    value: '{RELID-AYD-TRAILER}',
    label: 'Next sequential invoice number for found Trailers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for this year (Date Created)',
    example: '1234'
}, {
    value: '{RELID-AYS-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for the year from Invoice Date',
    example: '1234'
}, {
    value: '{RELID-AYD-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{RELID-AY-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for this year (Date Created)',
    example: '1234'
}, {
    value: '{RELID-AYS-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for the year from Invoice Date',
    example: '1234'
}, {
    value: '{RELID-AYD-DRIVER}',
    label: 'Next sequential invoice number for found Drivers in item associations for the year from Due Date',
    example: '1234'
}, {
    value: '{DATE-C}',
    label: 'Date when invoice was create (ex. YYYYMMDD)',
    example: '20220621'
}, {
    value: '{DATE}',
    label: 'Date invoice (ex. YYYYMMDD)',
    example: '20220621'
}, {
    value: '{DATE-D}',
    label: 'Date due (ex. YYYYMMDD)',
    example: '20220621'
}, {
    value: '{YEAR-C}',
    label: 'Year when invoice was create (ex. YYYY)',
    example: '2022'
}, {
    value: '{YEAR}',
    label: 'Year invoice (ex. YYYY)',
    example: '2022'
}, {
    value: '{YEAR-D}',
    label: 'Year due (ex. YYYY)',
    example: '2022'
}];