import { faCarBattery, faEngine, faFluxCapacitor, faGasPump, faPump, faSprayCanSparkles, faTankWater, faTirePressureWarning, faWind, faWindWarning, faWrench } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export const REPAIR_COMPONENTS_DESCRIPTION = [
    {
        name: 'ENGINE',
        tag_name: 'ENGINE',
        icon: <FontAwesomeIcon icon={faEngine} />,
        iconNameOnly: faEngine
    },
    {
        name: 'TRANSMISSION_AND_DRIVETRAIN',
        tag_name: 'TRANSMISSION/DRIVETRAIN',
        icon: <FontAwesomeIcon icon={faPump} />,
        iconNameOnly: faPump
    },
    {
        name: 'SUSPENSION_AND_BRAKES',
        tag_name: 'SUSPENSION/BRAKES',
        icon: <FontAwesomeIcon icon={faTirePressureWarning} />,
        iconNameOnly: faTirePressureWarning
    },
    {
        name: 'ELECTRICAL',
        tag_name: 'ELECTRICAL',
        icon: <FontAwesomeIcon icon={faCarBattery} />,
        iconNameOnly: faCarBattery
    },
    {
        name: 'COOLANT_SYSTEM',
        tag_name: 'COOLANT SYSTEM',
        icon: <FontAwesomeIcon icon={faTankWater} />,
        iconNameOnly: faTankWater
    },
    {
        name: 'FUEL_SYSTEM',
        tag_name: 'FUEL SYSTEM',
        icon: <FontAwesomeIcon icon={faGasPump} />,
        iconNameOnly: faGasPump
    },
    {
        name: 'AIR_SYSTEM',
        tag_name: 'AIR SYSTEM',
        icon: <FontAwesomeIcon icon={faFluxCapacitor} />,
        iconNameOnly: faFluxCapacitor
    },
    {
        name: 'BODY_AND_CHASIS',
        tag_name: 'BODY/CHASIS',
        icon: <FontAwesomeIcon icon={faSprayCanSparkles} />,
        iconNameOnly: faSprayCanSparkles
    },
    {
        name: 'AFTERTREATMENT_AND_EXHAUST',
        tag_name: 'AFTERTREATMENT/EXHAUST',
        icon: <FontAwesomeIcon icon={faWindWarning} />,
        iconNameOnly: faWindWarning
    },
    {
        name: 'OTHER',
        tag_name: 'OTHER',
        icon: <FontAwesomeIcon icon={faWrench} />,
        iconNameOnly: faWrench
    }
]
export const TRC = (COMPONENT) => REPAIR_COMPONENTS_DESCRIPTION.find(x => x.name === COMPONENT)