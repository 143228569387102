import React from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import GenericAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';


const UserCard = ({ user }) => {

    const { placeholder, profile_picture, firstname, lastname, title, role_label, uuid, status, deleted } = user;
    // console.log(firstname, user)
    const ALLOWED_STATUSES = ['0', '1', '2'];
    const STATUS_DEF = {
        '0': {
            'name': 'Active',
            'background': '',
            'text-color': '',
            'subtitle-text-color': ''
        },
        '1': {
            'name': 'Disabled',
            'background': 'bg-secondary',
            'text-color': 'text-white',
            'subtitle-text-color': 'text-white'
        },
        '2': {
            'name': 'Pending Activation',
            'background': 'bg-warning bg-gradient',
            'text-color': 'text-white',
            'subtitle-text-color': 'text-white'
        },
        '3': {
            'name': 'Deleted',
            'background': 'bg-danger bg-gradient',
            'text-color': 'text-white',
            'subtitle-text-color': 'text-white'
        },
        '999': {
            'name': 'Unknown Status',
            'background': '',
            'color': '',
            'subtitle-text-color': ''
        }
    };

    const STATUS = (deleted !== '1') ? (!placeholder && ALLOWED_STATUSES.includes(status.toString())) ? status.toString() : '999' : '3';

    // console.log((firstname && lastname) ? `${firstname.chartAt(0)}${lastname.chartAt(0)}` : ' ')

    return <>
        <Card className={` p-3 h-100 me-2 col xs-6 md-4 lg-3 xxl-2 ${STATUS_DEF[STATUS]?.background}`}  >
            <Link to={`/_d/Users/${uuid}`}>
                <Avatar
                    size="4xl"
                    className="img-fluid mb-2"
                    src={profile_picture && profile_picture}
                    name={(firstname && lastname) ? `${firstname} ${lastname}` : ' '}
                    mediaClass="img-thumbnail shadow-sm"
                />
            </Link>
            <h6 className="mb-1">
                {(placeholder) && <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>}
                {(!placeholder) && <Link to={`/_d/Users/${uuid}`} className={`${STATUS_DEF[STATUS]['text-color']}`}>{firstname} {lastname}</Link>}
            </h6>
            <div className="fs--2 mb-1">
                <Link className={`${STATUS_DEF[STATUS]['subtitle-text-color']}`} to={`/Users/${uuid}`}>
                    {(placeholder) ? <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={6} />
                        <Placeholder xs={8} />
                    </Placeholder>
                        : <div>
                            {(title) && <div>{title}</div>}
                            {(role_label) && <div>{role_label}</div>}
                            {(STATUS !== '0') && <div>{STATUS_DEF[STATUS]['name']}</div>}
                        </div>
                    }
                </Link>
            </div>
        </Card>
    </>;
};

UserCard.propTypes = {
    user: PropTypes.shape({
        avatarSrc: PropTypes.string,
        name: PropTypes.string,
    })
};

export default UserCard;
