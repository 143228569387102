import React, { useContext, useState, useEffect } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import Cookies from 'js-cookie';
import { faArrowsRotate, faRotateExclamation } from '@fortawesome/pro-regular-svg-icons';
import { SocketContext } from 'context/SocketContext';
import { faCloudExclamation, faWifiExclamation } from '@fortawesome/pro-duotone-svg-icons';

const TopNavRightSideNavItem = () => {

  const [hasUpdateAvailable, setHasUpdateAvailable] = useState(false)

  const {
    config: { isDark, hasUpdate },
    setConfig
  } = useContext(AppContext);

  useEffect(() => {
    const hasupd = Cookies.get('ATLASS_HAS_NEWER_VERSION')
    // console.log('has newer version', hasupd === 'true')
    setHasUpdateAvailable(hasupd === 'true');
  }, [])

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >

      {hasUpdate &&
        <Nav.Item>
          <Nav.Link
            as={'div'}
            className="px-2 theme-control-toggle"
            onClick={() => {

              console.log('clicked update');

              navigator.serviceWorker.ready.then((registration) => {
                registration.unregister().then(() => {
                  setConfig('hasUpdate', false)
                  Cookies.set('ATLASS_HAS_NEWER_VERSION', false)
                  setHasUpdateAvailable(false)
                  window.location.reload();
                });
              });

            }}
          >
            <OverlayTrigger overlay={<Tooltip id="hello">Update available, click here to update</Tooltip>}
              placement="bottom">

              <div className="theme-control-toggle-label bg-warning-300">
                <FontAwesomeIcon icon={faArrowsRotate} className='text-warning fs-0 fa-spin' />
              </div>

            </OverlayTrigger>

          </Nav.Link>
        </Nav.Item>
      }

      {/* <SocketContext.Consumer >
        {
          ({ error, online }) => (<Nav.Item>
            <Nav.Link as={'div'} className="px-2"
            >
              {error && <OverlayTrigger overlay={<Tooltip>{error}</Tooltip>} placement="bottom">
                <div className="">
                  <FontAwesomeIcon icon={faCloudExclamation} className="text-warning" />
                </div>
              </OverlayTrigger>}
              {!online && <OverlayTrigger overlay={<Tooltip>You're offline</Tooltip>} placement="bottom">
                <div className="">
                  <FontAwesomeIcon icon={faWifiExclamation} className="text-warning" />
                </div>
              </OverlayTrigger>
              }
            </Nav.Link>
          </Nav.Item>
          )}
      </SocketContext.Consumer> */}

      <Nav.Item>
        <Nav.Link
          as={'div'}
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            overlay={
              <Tooltip id="hi">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
            placement="bottom"
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>


      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
