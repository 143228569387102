import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

const ValidatedInput = ({ FeedBackText, value, ...rest }) => {

    return (<>
        <InputGroup hasValidation>
            <Form.Control
                type="text"
                {...rest}
            />
            <Form.Control.Feedback type="invalid">{FeedBackText}</Form.Control.Feedback>
        </InputGroup>
    </>)
}


export const EmailValidatedInput = ({ value, ...props }) => {

    const [isInvalid, setIsInvalid] = useState(false)
    const [predefinedValue, setPredefinedValue] = useState(value)

    const validateEmail = e => {
        // e.preventDefault();
        const v = e.target.value
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        setPredefinedValue(v)

        if (!v || regex.test(v) === false) return setIsInvalid(true)

        setIsInvalid(false)
    }

    return <ValidatedInput
        FeedBackText={`Invalid Email`}
        onKeyDown={validateEmail}
        isInvalid={isInvalid}
        value={predefinedValue}
        data-isinvalid={isInvalid}
        {...props}
    />
}

export default ValidatedInput