import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { PlaceTypes } from 'data/autocomplete/PlaceTypes' // AtlassPlaceTypeSelect
import UnitedStatesAndCanada from 'data/provinces/NorthAmerica/UnitedStatesAndCanada' //AtlassStateSelect_NA
import { getColor } from "helpers/utils";

const AtlassSelect = ({
    onChange,
    name,
    value,
    ...props
}) => {

    const [selectedValue, setSelectedValue] = useState(false)

    const style = {
        option: (provided, state) => ({
            ...provided,
            color: getColor('dark')
        }),
        control: (provided) => ({
            ...provided
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided, opacity, transition,
                color: getColor('dark')
            };
        }
    }

    useEffect(() => {
        if (value) setSelectedValue(value)
    }, [value])

    return <ReactSelect
        closeMenuOnSelect={true}
        value={selectedValue}
        onChange={v => {
            setSelectedValue(v)
            if (typeof onChange === 'function') onChange({
                target: {
                    value: v ? v.value : '',
                    name: name
                }
            })
        }}
        name={name}
        classNamePrefix="react-select"
        styles={style}
        {...props}
    />
}

export const AtlassPlaceTypeSelect = ({ ...props }) => {

    const TYPES = PlaceTypes.map(TYPE => ({
        value: TYPE,
        label: TYPE
    }))

    return <AtlassSelect options={TYPES} placeholder="Select a type" {...props} />
}

export const AtlassStateSelect_NA = ({ value, ...props }) => {

    const [selectedValue, setSelectedValue] = useState(false)

    const formatItem = STATE => ({
        value: STATE.id,
        label: STATE.name
    })

    const US_STATES = UnitedStatesAndCanada.filter(STATE => STATE.country_abbr === 'US').map(formatItem)
    const MX_STATES = UnitedStatesAndCanada.filter(STATE => STATE.country_abbr === 'MX').map(formatItem)
    const CA_STATES = UnitedStatesAndCanada.filter(STATE => STATE.country_abbr === 'CA').map(formatItem)

    const STATES = [
        {
            label: 'United States',
            options: US_STATES
        },
        {
            label: 'Canada',
            options: CA_STATES
        },
        {
            label: 'Mexico',
            options: MX_STATES
        }
    ]

    useEffect(() => {
        if (!value) return
        const state = UnitedStatesAndCanada.find(x => x.id == value)
        if (state) setSelectedValue(formatItem(state))
    }, [value])

    return <AtlassSelect options={STATES} value={selectedValue} placeholder="Select a state" {...props} />
}

export default AtlassSelect