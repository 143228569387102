import React, { useState, useEffect } from 'react'
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import { Button, Card, FloatingLabel, Form, FormCheck, ListGroup, Modal, Offcanvas } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import Permissions from 'data/important/permissions'
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { getUserData } from 'context/AuthContext';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlusCircle } from '@fortawesome/pro-duotone-svg-icons';

const API = new APICommunicator('UserRoles');

const RoleEdit = ({ show, handleClose, successCallback, ROLE, ...rest }) => {

    const { ROLE_ID } = useParams();
    const [PERMISSIONS, setPERMISSIONS] = useState(false)
    const [roleName, setRoleName] = useState(false)
    const [roleDescription, setRoleDescription] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState([''])

    const getRoleData = () => {
        API.GET(`/${ROLE_ID}`)
            .then(Response => {
                // console.table(Response.content)
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                // setROLE(Response.content)
            })
            .catch(handleErrorWithToast)
    }

    const handleSubmit = e => {
        e.preventDefault()

        // Edit
        if (ROLE && ROLE !== 'NEW') return API.PUT(`/${ROLE.uuid}`, {
            name: roleName,
            description: roleDescription,
            permissions: selectedPermissions
        }).then(Response => {
            if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
            if (typeof successCallback === 'function') successCallback(Response.content)
            handleClose()
            toast.success('Role edited successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            })
        }).catch(handleErrorWithToast)

        API.POST(`/`, {
            name: roleName,
            description: roleDescription,
            permissions: selectedPermissions
        }).then(Response => {
            if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
            if (typeof successCallback === 'function') successCallback(Response.content)
            handleClose()
            toast.success('Role added successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            })
        }).catch(handleErrorWithToast)
    }

    const handlePermissionChange = e => {
        var newArray = [];
        var i = -1;
        const WORKING_CODE_NAME = e.target.id
        const CHECKED = e.target.checked
        const EXISTS_IN_SELECTED = (selectedPermissions && selectedPermissions.indexOf(WORKING_CODE_NAME) >= 0)

        // Checked  (Add if not already added)
        if (CHECKED && !EXISTS_IN_SELECTED) setSelectedPermissions([...selectedPermissions, WORKING_CODE_NAME]);

        // Not checked (Remove if exists)
        if (!CHECKED && EXISTS_IN_SELECTED) {
            newArray = [...selectedPermissions] 
            i = newArray.indexOf(WORKING_CODE_NAME)
            newArray.splice(i, 1) // Remove from array
            setSelectedPermissions(newArray)
        }

        if (!CHECKED) {

            // **** PARENT - CHILD RELATIONS
            const UNCHECK_ALL_CHILDREN = (CHILDREN) => {
                for (const CHILD of CHILDREN) {

                    const CHILD_IS_SELECTED = selectedPermissions.findIndex(role => role.uuid === CHILD.uuid) >= 0;
                    if (!CHILD_IS_SELECTED) return

                    // remove the child from selected
                    i = newArray.indexOf(CHILD.code_name)
                    newArray.splice(i, 1)
                    setSelectedPermissions(newArray)

                    // visually uncheck the child
                    document.getElementById(CHILD.code_name).checked = false

                    // find if that child has more children 
                    const HAS_MORE_CHILDREN = PERMISSIONS.map(x => { if (x.parent === CHILD.code_name) return x }).filter(x => x !== undefined)
                    if (HAS_MORE_CHILDREN) UNCHECK_ALL_CHILDREN(HAS_MORE_CHILDREN); // Self loop to uncheck the children of all children
                }
            }

            // find if the permission we're working on  has children
            const HAS_CHILDREN = PERMISSIONS.map(x => { if (x.parent === WORKING_CODE_NAME) return x }).filter(x => x !== undefined)
            // if so - uncheck them
            if (HAS_CHILDREN) UNCHECK_ALL_CHILDREN(HAS_CHILDREN)
        }
    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })

    const isPermissionDisabled = (PERMISSION, List = false) => {
        const selectedList = List || selectedPermissions
        if (!PERMISSION.parent) return false
        return selectedList.indexOf(PERMISSION.parent) <= -1
    }

    useEffect(() => {
        // ROLE_ID != 'New' && getRoleData()
    }, [ROLE_ID])

    useEffect(() => {
        setPERMISSIONS(Permissions)
        if (ROLE && ROLE !== 'NEW') setSelectedPermissions(ROLE.permissions)
        if (ROLE && ROLE !== 'NEW') setRoleName(ROLE.name)
        if (ROLE && ROLE !== 'NEW') setRoleDescription(ROLE.description)
        if (ROLE && ROLE === 'NEW') setSelectedPermissions([])
    }, [ROLE, show])

    useEffect(() => {
        // console.log(selectedPermissions)
    }, [selectedPermissions])

    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement='end'
                {...rest}
            >
                <Offcanvas.Header closeButton>
                    {ROLE && ROLE !== 'NEW' ?
                        <Offcanvas.Title>Edit Role <FontAwesomeIcon icon={faPenToSquare} /></Offcanvas.Title>
                        :
                        <Offcanvas.Title>Add New Role <FontAwesomeIcon icon={faPlusCircle} /></Offcanvas.Title>
                    }

                </Offcanvas.Header>
                <Offcanvas.Body >
                    <Form id="roleEditForm" onSubmit={handleSubmit}>
                        <Form.Group>
                            <FloatingLabel label="Name" className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    onChange={e => {
                                        setRoleName(e.target.value)
                                    }}
                                    defaultValue={ROLE.name}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group>
                            <FloatingLabel label="Description" className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Description"
                                    name="description"
                                    size='sm'
                                    rows={4}
                                    onChange={e => {
                                        setRoleDescription(e.target.value)
                                    }}
                                    defaultValue={ROLE.description}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <ListGroup variant="flush" className="mb-5">
                            {(PERMISSIONS.length >= 1 && (ROLE || ROLE == 'NEW')) && PERMISSIONS.map(PERMISSION =>
                                <ListGroup.Item key={PERMISSION.code_name} className="fs--2 pb-0" >
                                    <FormCheck
                                        type="switch"
                                        id={PERMISSION.code_name}
                                        style={{ minHeight: '0' }}
                                    >
                                        <FormCheck.Input
                                            onChange={handlePermissionChange}
                                            className="mt-2"
                                            style={{ minHeight: '0' }}
                                            defaultChecked={ROLE?.permissions ? ROLE?.permissions.indexOf(PERMISSION.code_name) >= 0 : false}
                                            disabled={isPermissionDisabled(PERMISSION)}
                                        />
                                        <FormCheck.Label as='div' className='d-block'>
                                            <div className="ms-3">
                                                <h6 className="mb-0">{PERMISSION.name}</h6>
                                                <span className="text-muted">{PERMISSION.description}</span>
                                            </div>
                                        </FormCheck.Label>
                                    </FormCheck>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Form>
                </Offcanvas.Body>
                <div className='position-fixed bottom-0 bg-light w-100 p-2'>
                    <Form.Group className='text-align-right'>
                        <Button
                            onClick={handleSubmit}
                            className="ms-2"
                            variant="primary"
                            disabled={!roleName}>
                            Save
                        </Button>
                        <Button
                            className="ms-4"
                            onClick={handleClose}
                            variant="default">
                            Cancel
                        </Button>
                    </Form.Group>
                </div>
            </Offcanvas>
        </>
    )
}

export default RoleEdit;