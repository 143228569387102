import APICommunicator from "api/APICommunicator";
import { debounce, getColor } from "helpers/utils";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";

const USER_ROLES_ENDPOINT = "UserRoles"
const USERS_ENDPOINT = "Users"


const APISelect = ({ value, onChange, name, placeholder, isLoading, API_ENDPOINT, ...props }) => {

    const API = new APICommunicator(API_ENDPOINT);
    const [loading, setLoading] = useState(false)
    const [selectedValue, setSelectedValue] = useState(false)
    const [OPTIONS, setOPTIONS] = useState([])

    const loadData = debounce((inputValue, callback) => {
        setLoading(true)
        const URL = inputValue ? `/?search=${inputValue}` : '/'

        API.GET(URL)
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                const options = Response.content.map(OPTION => ({
                    value: OPTION.id,
                    label: OPTION.name
                }))
                setOPTIONS(options)
                if (typeof callback === 'function') callback(options)

            })
            .catch(e => { setLoading(false); handleErrorWithToast(e) })
    })



    const style = {
        option: (provided, state) => ({
            ...provided,
            color: getColor('dark')
        }),
        control: (provided) => ({
            ...provided
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
                color: getColor('dark')
            }
        }
    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response))
    const handleInputChange = val => (!val) && loadData()

    useEffect(() => {
        setLoading(true)
        loadData()
    }, []);

    return <AsyncSelect
        closeMenuOnSelect={true}
        cacheOptions={false}
        onInputChange={handleInputChange}
        loadOptions={loadData}
        value={selectedValue}
        defaultOptions={OPTIONS}
        options={OPTIONS}
        placeholder={placeholder || "Select"}

        onChange={
            v => {
                setSelectedValue(v)
                // proper onChange functionality for default formData assignments
                if (typeof onChange === 'function') onChange({
                    target: {
                        name: name,
                        value: v.value
                    }
                })
            }
        }

        // loadingMessage='Loading, please wait ..'
        isLoading={loading}
        styles={style}
        name={name}
        classNamePrefix="react-select"

        {...props}
    />
}

export const SelectUserRole = ({ ...props }) => <APISelect
    API_ENDPOINT={USER_ROLES_ENDPOINT}
    placeholder='Select a role'
    {...props}
/>

export const SelectUser = ({ ...props }) => <APISelect
    API_ENDPOINT={USERS_ENDPOINT}
    placeholder='Select an user'
    {...props}
/>


export default APISelect