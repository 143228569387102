import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
  NavDropdown,
  Button,
  CloseButton
} from 'react-bootstrap';
import DemoRequestForm from './forms/DemoRequestForm';
// import Login from 'components/authentication/simple/Login';
// import Registration from 'components/authentication/simple/Registration';

const breakpoint = 'lg';

const LandingRightSideNavItem = () => {
  const [showDemoRequestModal, setShowDemoRequestModal] = useState(false);

  return (
    <Nav navbar className="ms-auto py-2">
      {/* <Nav.Item>
        <Nav.Link as={Link} to="/">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="dashboardTooltip">Dashboard</Tooltip>}
          >
            <div>
              <FontAwesomeIcon
                icon="chart-pie"
                id="dashboardTooltip"
                className={`d-none d-${breakpoint}-inline-block`}
              />
            </div>
          </OverlayTrigger>
          <span className={`d-${breakpoint}-none`}>Dashboard</span>
        </Nav.Link>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Nav.Link as={Link} to="/documentation/getting-started">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="dashboardTooltip">Documentation</Tooltip>}
          >
            <div>
              <FontAwesomeIcon
                icon="book"
                id="documentationTooltip"
                className={`d-none d-${breakpoint}-inline-block`}
              />
            </div>
          </OverlayTrigger>
          <span className={`d-${breakpoint}-none`}>Documentation</span>
        </Nav.Link>
      </Nav.Item> */}
      <Nav.Item as={Link} to="/login" title="Login" align="end">
        <div className="py-2 px-4 text-light">Login</div>
      </Nav.Item>
      <Nav.Item as={Button} variant="outline-danger" className="fs--1 border-2 rounded-pill" to="#!"
        onClick={() => setShowDemoRequestModal(!showDemoRequestModal)}>
        Request a Demo
      </Nav.Item>
      <Nav.Item>
        <Modal
          show={showDemoRequestModal}
          centered
          size='lg'
          onHide={() => setShowDemoRequestModal(false)}
        >
          <Modal.Body className="p-0">
            <Card>
              <Card.Header className='p-4 mb-0 pb-0'>
                <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0 float-end fs--1"
                  onClick={() => setShowDemoRequestModal(false)}
                />
                <Card.Title as="h5">
                  Request a Demo
                </Card.Title>
              </Card.Header>
              <Card.Body className="fs--1 fw-normal p-4 pt-1">
                <DemoRequestForm submitCallback={succeeded => {
                  if (succeeded === true) setShowDemoRequestModal(false)
                }} />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
