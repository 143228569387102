import { faCloudArrowUp } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row, Spinner } from "react-bootstrap";
import APICommunicator from 'api/APICommunicator'
import AtlassLogo from 'assets/img/logo.png'
import { toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import { getSize, getFontawesomeIconByFileMIMEType } from 'helpers/utils';

const ChangeLogo = ({ data, successcallback, ...rest }) => {
    const API = new APICommunicator('Settings/Company');
    const [companyInformation, setCompanyInformation] = useState(false)
    const [submitData, setSubmitData] = useState(false)
    const [loading, setLoading] = useState(false)

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: acceptedFiles => acceptedFiles.map(file => {
            changeLogo(file)
        }),
        onDropRejected: asd => toast.error('File not allowed. Only Image files accepted')

    });
    const changeLogo = File => {
        setLoading(true)
        var formData = new FormData();
        formData.append('file', File);
        // return;
        API.UPLOAD(`/ChangeLogo`, formData)
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response);
                if (typeof successcallback === 'function') return successcallback(Response.content)
                toast.success('Logo updated!')
            })
            .catch(e => {
                setLoading(false)
                handleErrorWithToast(e)
            })

    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response))

    useEffect(() => {
        if (data && data.general) {
            const { general } = data;
            setCompanyInformation(general)
        }
    }, [data])

    return (<>
        <Card {...rest}>
            <Card.Body>
                <Form.Group as={Row} className="justify-center" >
                    <Flex justifyContent="between">
                        {loading ? <Spinner className="my-auto mx-auto" animation="border"><span className="visually-hidden">Loading...</span></Spinner> :
                            <Image className="my-auto mx-auto" style={{ height: 'auto', maxHeight: '123px', width: 'auto', maxWidth: '220px' }} src={companyInformation?.company_logo} />
                        }
                        <div  {...getRootProps({ className: 'dropzone-area py-5 px-3 mx-3' })}>
                            <input {...getInputProps()} />
                            <Flex justifyContent="center">
                                <FontAwesomeIcon icon={faCloudArrowUp} size="2x" className="fs-2 me-1" />
                                <p className="fs-0 mb-0 text-700">Drop your logo here to upload</p>
                            </Flex>
                        </div>
                    </Flex>
                </Form.Group>

            </Card.Body>
        </Card>
    </>)
}

export default ChangeLogo