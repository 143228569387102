import React, { useRef, useBreakpoints, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';

// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';

// Import charts, all with Chart suffix
import { BarChart, RadarChart } from 'echarts/charts';

// import components, all suffixed with Component
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent
} from 'echarts/components';

// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import * as tooltipFormatter from 'helpers/echart-utils';
import dayjs from 'dayjs';

// Register the required components
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    BarChart,
    RadarChart,
    CanvasRenderer,
    LegendComponent
]);



const ActivityChart = ({ RadarStatistics, Loading, ...rest }) => {

    const { PreviousMonth, LastMonth, CurrentMonth } = RadarStatistics;

    const getOption = () => ({
        legend: {
            orient: 'vertical',
            left: 'left',
            textStyle: {
                color: getColor('600')
            }
        },
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('100'),
            borderColor: getColor('300'),
            textStyle: { color: getColor('dark') },
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        },

        radar: {
            shape: 'circle',
            indicator: [
                { name: 'Tracking Updates', max: 1000 },
                { name: 'Orders Added', max: 100 },
                { name: 'Assigned as Dispatch (Assist)', max: 100 },
                { name: 'Assigned as Customer Representative (Lead)', max: 100 },
                { name: 'Invoices Created', max: 200 },
                { name: 'Statements Created', max: 200 }
            ],
            radius: 100,
            splitLine: {
                lineStyle: {
                    color: rgbaColor(getColor('700'))
                }
            }
        },

        series: [
            {
                type: 'radar',
                data: [
                    {
                        value: PreviousMonth,
                        name: 'February 1-28',
                        itemStyle: {
                            color: getColor('secondary')
                        },
                        areaStyle: {
                            color: rgbaColor(getColor('secondary'))
                        }
                    },
                    {
                        value: LastMonth,
                        name: 'March 1-31',
                        itemStyle: {
                            color: getColor('primary')
                        },
                        areaStyle: {
                            color: rgbaColor(getColor('primary'))
                        }
                    },
                    {
                        value: CurrentMonth,
                        name: 'April 1-today',
                        itemStyle: {
                            color: getColor('success')
                        },
                        areaStyle: {
                            color: rgbaColor(getColor('success'))
                        }
                    }
                ]
            }
        ]
    });


    return (
        <Card {...rest}>
            <FalconCardHeader title="Latest Activity Statistics [SAMPLE DATA]" light />
            <Card.Body className="fs--1">
                <ReactEChartsCore
                    echarts={echarts}
                    option={getOption()}
                    style={{ height: '25.75rem' }}
                    showLoading={Loading}
                />
            </Card.Body>
        </Card>
    );
};

export default ActivityChart;
