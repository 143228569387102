export default [
  {
    media: {
      icon: ['fad', 'user-headset'], color: 'info', className: 'fs-4'
    },
    title: 'Manage Orders',
    description:
      "Quote, Receive, Dispatch and Track Orders from your customers or through our 3rd party integrations. Make sure appointemnts are met, properly scheduled and your customers are informed."
  },
  {
    media: { icon: ['fad', 'usd-circle'], color: 'success', className: 'fs-6' },
    title: 'All accounting in one place',
    description:
      'Take the complicated out of accounting. We\'ll help you create invoices, statements and pay your drivers, while keeping track of every important piece of document! View a summary of how your business is doing all in one place.'
  },
  {
    media: { icon: ['fad', 'screwdriver-wrench'], color: 'warning', className: 'fs-5' },
    title: 'Get ahold of Safety and Maintenance',
    description:
      'With our robust Safety and Management tools, you\'ll be able to easily track expired registrations, licenses and monitor driver safety performance, while keeping track of your maintenance records thus saving money.'
  }
];
