import React from "react";
import Flex from "./Flex";
import { Alert, CloseButton } from "react-bootstrap";
import IconButton from "./IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import classNames from 'classnames';

const ModuleErrorWithReloadAlert = ({
    message,
    errordata,
    reloadCallback,
    Icon,
    className,
    IconName,
    ...rest
}) => {

    return (<>
        <Alert variant="danger"
            className={classNames(className, 'd-flex align-items-center')}
            {...rest}
        >
            {!Icon ? <div className={`bg-danger ms-1 me-3 icon-item`}>
                <FontAwesomeIcon icon={IconName || faTimesCircle} className="text-white fs-3" />
            </div> : Icon}

            <Flex className="w-100" justifyContent="between">


                <div>
                    <div>{message || 'Error loading component'}</div>
                    {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                        (typeof errordata === 'object') ? errordata.map(e => <code key={e} style={{ 'whiteSpace': 'pre-line' }}>{e}</code>) : <code style={{ 'whiteSpace': 'pre-line' }}>{errordata}</code>
                    }
                </div>


                {(typeof reloadCallback === 'function') && <div className="ms-auto right-0 text-center">
                    <IconButton variant="falcon-danger" icon="sync-alt" size="sm" transform="shrink-3">
                        <span className="d-none d-sm-inline-block ms-0" onClick={() => { if (typeof reloadCallback === 'function') reloadCallback() }}>Reload</span>
                    </IconButton>
                </div>
                }


            </Flex>

        </Alert>
    </>)
}

export default ModuleErrorWithReloadAlert