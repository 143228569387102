import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrianglePersonDigging, faHome } from '@fortawesome/pro-duotone-svg-icons';
import Lottie from 'react-lottie';
import UnderConstructionAnimation from 'assets/img/animated-icons/under-construction-ladder'

const UnderConstruction = () => {

  const ConstructionAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: UnderConstructionAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">
          <Lottie options={ConstructionAnimationOptions} ariaRole="div" style={{ maxWidth: '400px' }} />
        </div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          The page you're looking for is under construction.
        </p>
        <hr />
        <p>
          Come back later or
          <a href="mailto:admin@atlassapp.com" className="ms-1">
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default UnderConstruction;
