import createMarkup from "helpers/createMarkup";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import Flex from "./Flex";
import StarRating from "./StarRating";

const UserComment = ({ userId, content, avatarSrc, name, postTime, rating }) => {

    return (
        <div>
            <Flex className="mt-3">
                <Avatar src={avatarSrc && avatarSrc} name={name && name} size="xl" />
                <div className="flex-1 ms-2 fs--1">
                    <p className="mb-1 bg-200 rounded-3 p-2">
                        <Link className="fw-semi-bold" to={`/Users/${userId}`}>
                            {name}
                        </Link>
                        <span
                            className="ms-1"
                            dangerouslySetInnerHTML={createMarkup(content)}
                        />
                    </p>
                    <div className="px-2">
                        <StarRating rating={rating || 0}
                            readonly={true}
                            size="1x"
                            className="" /> • {postTime}
                    </div>
                </div>
            </Flex>
        </div>)
}

export default UserComment