import Section from "components/common/Section";
import PrivacyPolicy from "components/pages/help/PrivacyPolicy";
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import bg1 from 'assets/img/backgrounds/shutterstock_v25087742.jpg';
import rhsImage from 'assets/img/logo.png';
import { Link } from 'react-router-dom';

const PublicPrivacyPolicy = () => {

    return (<>
        <Section
            className="py-0 overflow-hidden light"
            image={bg1}
            bg="dark"
            overlay="0"
            position="center bottom">
            <Row className="justify-content-center align-items-center pt-8 pt-lg-8 pb-lg-4 pb-xl-0">
                <Col
                    className="pb-2 pb-xl-6 text-center text-xl-start">
                    <h1 className="text-white fw-light">
                        Privacy Policy
                    </h1>

                    <p className="lead text-white opacity-75">
                        This Privacy Policy describes Our policies and procedures on the collection,
                        use and disclosure of Your information when You use the Service and tells
                        You about Your privacy rights and how the law protects You.
                        We use Your Personal data to provide and improve the Service. By using the Service,
                        You agree to the collection and use of information in accordance with this Privacy Policy.
                    </p>
                    <p className="text-white opacity-75">
                        Last updated: December 08, 2021
                    </p>
                </Col>
            </Row>
        </Section>
        <Section className="pt-6">
            <PrivacyPolicy />
        </Section>
    </>)
}

export default PublicPrivacyPolicy